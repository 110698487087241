export const processSpecialWeaponKills = (telemetryData: any[]) => {
    const specialWeapons = new Set([
        "PanzerFaust100M_Projectile_C",
        "WeapCrossbow_1_C",
        "ProjC4_C",
        "Mortar_Projectile_C",
    ]);

    const specialWeaponKills = telemetryData
        .filter(
            (event) =>
                event._T === "LogPlayerKillV2" &&
                specialWeapons.has(event.killerDamageInfo.damageCauserName)
        )
        .map((event) => ({
            killer: event.killer.name,
            victim: event.victim.name,
            distance: Math.round(event.killerDamageInfo.distance), // Round to avoid decimals
            weapon: event.killerDamageInfo.damageCauserName,
        }));

    return specialWeaponKills;
};
