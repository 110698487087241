import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import FunnyLoading from "components/FunnyLoading";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

interface Player {
    playerName: string;
    platform: string;
    savedAt: Date;
}

interface SavedPlayersResponse {
    myAccounts: Player[];
    myFriends: Player[];
    following: Player[];
}

const SavedPlayers: React.FC = () => {
    const [savedPlayers, setSavedPlayers] = useState<SavedPlayersResponse>({
        myAccounts: [],
        myFriends: [],
        following: [],
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSavedPlayers = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem("authToken");
                const response = await fetch(API_ENDPOINTS.SAVED_PLAYERS, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch saved players");
                }

                const data = await response.json();
                setSavedPlayers({
                    myAccounts: data.myAccounts || [],
                    myFriends: data.myFriends || [],
                    following: data.following || [],
                });
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSavedPlayers();
    }, []);

    const handlePlayerClick = (playerName: string, platform: string) => {
        navigate(
            `/player-stats/profile?name=${playerName}&platform=${platform}`
        );
    };

    const renderCategory = (title: string, players: Player[]) => (
        <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            {players.length > 0 ? (
                <List>
                    {players.map((player, index) => (
                        <ListItem
                            key={index}
                            onClick={() =>
                                handlePlayerClick(
                                    player.playerName,
                                    player.platform
                                )
                            }
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            <ListItemText
                                primary={player.playerName}
                                secondary={`Platform: ${
                                    player.platform
                                } | Saved since: ${format(
                                    new Date(player.savedAt),
                                    "PPpp"
                                )}`}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography>No players in this category.</Typography>
            )}
            <Divider sx={{ marginY: 2 }} />
        </Box>
    );

    return (
        <Box sx={{ padding: 2, maxWidth: 1400, margin: "0 auto" }}>
            <Typography variant="h5" gutterBottom>
                Saved Players
            </Typography>
            {loading ? (
                <FunnyLoading />
            ) : (
                <>
                    {renderCategory("My Accounts", savedPlayers.myAccounts)}
                    {renderCategory("My Friends", savedPlayers.myFriends)}
                    {renderCategory("Following", savedPlayers.following)}
                </>
            )}
        </Box>
    );
};

export default SavedPlayers;
