import React, { useState, useCallback } from "react";
import { TextField, Button, Box, Typography, useTheme } from "@mui/material";
import { Match } from "types/match";
import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";
import { useFocusContext } from "hooks/FocusContext";

interface MatchIdSearchProps {
    matches: Match[]; // Pass matches data to the component
    platform: string;
    playerName: string;
}

export const MatchSearch: React.FC<MatchIdSearchProps> = ({
    matches,
    platform,
    playerName,
}) => {
    const [matchId, setMatchId] = useState<string>("");

    const theme = useTheme();

    const { setIsSearchBoxFocused } = useFocusContext();

    const handleButtonClick = () => {
        const matchedMatch = matches.find(
            (match) => match.matchId.slice(-6) === matchId
        );

        if (matchedMatch) {
            const matchUrl = NAVIGATION_ROUTES.MATCH_DETAILS(
                platform,
                playerName,
                matchedMatch.matchId
            );

            // Open the match details page in a new tab
            window.open(matchUrl, "_blank");
        } else {
            alert("No match found with the entered ID.");
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleButtonClick();
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                gap: 1, // Space between the text field and button
                mb: 2,
                padding: 2, // Add some padding inside the border
                border: `2px solid ${theme.palette.secondary.main}`, // Add border with theme color
                borderRadius: 2, // Rounded corners
                backgroundColor: "rgba(0, 0, 0, 0.03)", // Subtle background for better contrast
            }}
        >
            <TextField
                label="Enter Last 6 of Match ID"
                value={matchId}
                onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 6) {
                        setMatchId(value);
                    }
                }}
                onFocus={() => setIsSearchBoxFocused(true)} // Set focus state
                onBlur={() => setIsSearchBoxFocused(false)} // Reset focus state
                onKeyDown={handleKeyPress}
                variant="outlined"
                sx={{ width: "300px" }}
            />
            <Button
                variant="contained"
                sx={{
                    textTransform: "uppercase",
                }}
                onClick={handleButtonClick}
                disabled={matchId.length !== 6} // Button is only enabled if the input is 6 characters
            >
                Search
            </Button>
            <Typography
                variant="caption"
                sx={{
                    color: "text.secondary",
                    marginLeft: 2, // Add space to separate the text from the button
                    whiteSpace: "nowrap", // Prevent wrapping
                }}
            >
                *Found in the bottom right corner while in a match
            </Typography>
        </Box>
    );
};
