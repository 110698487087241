import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";
import { useNavigate } from "react-router-dom";

/**
 * Hook to navigate to the player stats profile page.
 *
 * @returns A function to navigate to the profile page.
 */
export const usePlayerProfileNavigation = () => {
    const navigate = useNavigate();

    const navigateToPlayerProfile = (
        playerName: string,
        platform: string
    ): void => {
        navigate(NAVIGATION_ROUTES.PLAYER_PROFILE(platform, playerName));
    };

    return navigateToPlayerProfile;
};
