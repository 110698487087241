import React, { useState } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Typography,
    Tooltip,
    Collapse,
    IconButton,
} from "@mui/material";

// MUI Icons
import SmartToyIcon from "@mui/icons-material/SmartToy"; // Bot Icon
import LoopIcon from "@mui/icons-material/Loop"; // Recall Icon
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"; // Highest Damage Icon
import SportsMmaIcon from "@mui/icons-material/SportsMma"; // Most Kills Icon
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
///

import { useTheme } from "@mui/material/styles";

import { NormalMatchStats } from "pages/playerStats/MatchDetails";
import { Participant, Roster } from "utils/matchData/rosterStats";
import PlacementIcon from "utils/PlacementIcon";
import { COLORS } from "constants/appWide/colors";
import { getScrollbarStyles } from "styles/styles";
import SectionHeader from "components/MatchDetails/SectionHeader";

interface RecalledPlayer {
    name: string;
    phases: number[];
}

interface RostersTableProps {
    playerName: string;
    rosters: Roster[];
    participants: Participant[];
    normalMatchStats: NormalMatchStats[];
    recalledPlayers: RecalledPlayer[];
    isCasualOrIbr: boolean;
}

const RostersTable: React.FC<RostersTableProps> = ({
    playerName,
    rosters,
    participants,
    normalMatchStats,
    recalledPlayers,
    isCasualOrIbr,
}) => {
    const theme = useTheme();
    const [sortKey, setSortKey] = useState<
        | "rank"
        | "totalKills"
        | "totalDamage"
        | "kdRatio"
        | "totalDeaths"
        | "totalDamageTaken"
    >("rank"); // Default sorting: Placement (rank)
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

    const [expandedRoster, setExpandedRoster] = useState<number | null>(null); // Track expanded team

    const handleToggleExpand = (teamId: number) => {
        setExpandedRoster(expandedRoster === teamId ? null : teamId);
    };

    // Find the active player's roster
    const activeRoster = rosters.find((roster) =>
        roster.participants.some((participantId) =>
            participants.some(
                (p) =>
                    p.participantId === participantId && p.name === playerName
            )
        )
    );

    // Process Rosters with Aggregated Stats
    const processedRosters = rosters.map((roster) => {
        const teamPlayers = participants.filter((p) =>
            roster.participants.includes(p.participantId)
        );

        // Aggregate stats from participants
        const totalKills = teamPlayers.reduce((sum, p) => sum + p.kills, 0);
        const totalDamage = teamPlayers.reduce(
            (sum, p) => sum + p.damageDealt,
            0
        );
        const highestSurvivalTime = Math.max(
            ...teamPlayers.map((p) => p.timeSurvived),
            0
        );

        // Compute deaths and damage taken from normalMatchStats
        let totalDeaths = 0;
        let totalDamageTaken = 0;

        teamPlayers.forEach((participant) => {
            const playerStats = normalMatchStats.find(
                (stats) => stats.name === participant.name
            );
            totalDeaths += playerStats?.deaths ?? 0;
            totalDamageTaken += playerStats?.damageTaken ?? 0;
        });

        // Calculate Team K/D Ratio
        const kdRatio = totalDeaths > 0 ? totalKills / totalDeaths : 0;

        // Check if this is a bot team (teamId 200 or greater)
        const isBotTeam = roster.teamId >= 200;

        // Count total recalls per team
        let totalRecalls = 0;
        recalledPlayers.forEach((rp) => {
            if (teamPlayers.some((p) => p.name === rp.name)) {
                totalRecalls += rp.phases.length; // Count all recall phases per player
            }
        });

        return {
            ...roster,
            totalKills,
            totalDamage,
            highestSurvivalTime,
            totalDeaths,
            totalDamageTaken,
            kdRatio,
            isBotTeam,
            totalRecalls,
        };
    });

    // Find Team with Most Kills and Most Damage
    const maxKills = Math.max(...processedRosters.map((r) => r.totalKills), 0);
    const maxDamage = Math.max(
        ...processedRosters.map((r) => r.totalDamage),
        0
    );

    const nonBotTeamsCount = processedRosters.filter(
        (r) => r.teamId < 200
    ).length;

    // Sorting Function
    const sortedRosters = [...processedRosters].sort((a, b) => {
        let aValue = a[sortKey as keyof typeof a];
        let bValue = b[sortKey as keyof typeof b];

        // Ensure values are numbers for sorting
        if (typeof aValue !== "number") aValue = 0;
        if (typeof bValue !== "number") bValue = 0;

        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
    });

    // Change Sorting Order
    const handleSort = (
        key:
            | "rank"
            | "totalKills"
            | "totalDamage"
            | "kdRatio"
            | "totalDeaths"
            | "totalDamageTaken"
    ) => {
        setSortOrder(sortKey === key && sortOrder === "asc" ? "desc" : "asc");
        setSortKey(key);
    };

    return (
        <Box>
            <SectionHeader
                sectionType="teamsLeaderboard"
                itemCount={rosters.length}
            />

            <TableContainer
                component={Paper}
                sx={{
                    maxHeight: 800,
                    overflowY: "auto",
                    [theme.breakpoints.up("md")]: getScrollbarStyles(),
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "rank"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("rank")}
                                >
                                    Placement
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Team
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "totalKills"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("totalKills")}
                                >
                                    Kills
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "totalDeaths"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("totalDeaths")}
                                >
                                    Deaths
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "kdRatio"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("kdRatio")}
                                >
                                    K/D Ratio
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "totalDamage"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("totalDamage")}
                                >
                                    Damage Dealt
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "totalDamageTaken"}
                                    direction={sortOrder}
                                    onClick={() =>
                                        handleSort("totalDamageTaken")
                                    }
                                >
                                    Damage Taken
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Highest Survival Time
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRosters.map((roster, index) => (
                            <>
                                <TableRow key={roster.id}>
                                    <TableCell
                                        align="left"
                                        sx={{ position: "relative" }}
                                    >
                                        <Typography
                                            sx={{
                                                position: "absolute",
                                                top: "0px",
                                                left: "4px",
                                                fontSize: "12px",
                                                color: COLORS.orange,
                                            }}
                                        >
                                            {index + 1}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexWrap: "nowrap",
                                            }}
                                        >
                                            {roster.rank <= 3 ? (
                                                <PlacementIcon
                                                    rank={roster.rank}
                                                    isCasualOrIbr={
                                                        isCasualOrIbr
                                                    }
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "inline-block",
                                                        minWidth: 26,
                                                        height: 26,
                                                        borderRadius: 1,
                                                        border: "2px solid",
                                                        fontWeight: "bold",
                                                        textAlign: "center",
                                                        lineHeight: "26px",
                                                        backgroundColor:
                                                            theme.palette
                                                                .background
                                                                .default,
                                                        borderColor:
                                                            roster.isBotTeam
                                                                ? theme.palette
                                                                      .secondary
                                                                      .main
                                                                : theme.palette
                                                                      .primary
                                                                      .main,
                                                        color: roster.isBotTeam
                                                            ? theme.palette
                                                                  .secondary
                                                                  .main
                                                            : theme.palette
                                                                  .primary.main,
                                                    }}
                                                >
                                                    {roster.rank}
                                                </Box>
                                            )}

                                            {/* Most Kills Icon */}
                                            {roster.totalKills === maxKills &&
                                                roster.totalKills > 0 && (
                                                    <Tooltip title="Most Kills">
                                                        <SportsMmaIcon
                                                            fontSize="small"
                                                            sx={{
                                                                color: "red",
                                                                ml: 1,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}

                                            {/* Highest Damage Icon */}
                                            {roster.totalDamage === maxDamage &&
                                                roster.totalDamage > 0 && (
                                                    <Tooltip title="Highest Damage">
                                                        <LocalFireDepartmentIcon
                                                            fontSize="small"
                                                            sx={{
                                                                color: "orange",
                                                                ml: 1,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                        </Box>
                                    </TableCell>

                                    <TableCell>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {/* Bot Team Icon */}
                                            {roster.isBotTeam && (
                                                <Tooltip title="Bot Team">
                                                    <SmartToyIcon
                                                        sx={{
                                                            color: theme.palette
                                                                .secondary.main,
                                                            mr: 1,
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}

                                            {/* Active Roster Icon */}
                                            {activeRoster?.id === roster.id && (
                                                <Tooltip title="My Team">
                                                    <ArrowForwardIcon
                                                        sx={{
                                                            color: theme.palette
                                                                .primary.main,
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}

                                            {/* Recall Icons with Tooltip */}
                                            {roster.totalRecalls > 0 && (
                                                <Tooltip
                                                    title={`${
                                                        roster.totalRecalls
                                                    } Recall${
                                                        roster.totalRecalls > 1
                                                            ? "s"
                                                            : ""
                                                    }`}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        {Array(
                                                            roster.totalRecalls
                                                        )
                                                            .fill(null)
                                                            .map((_, i) => (
                                                                <LoopIcon
                                                                    key={i}
                                                                    sx={{
                                                                        color: "goldenrod",
                                                                        mr: 0.5,
                                                                    }}
                                                                />
                                                            ))}
                                                    </Box>
                                                </Tooltip>
                                            )}
                                            {/* Team Name */}
                                            {/* Team Name Logic */}
                                            {roster.isBotTeam ? (
                                                <>
                                                    Team{" "}
                                                    {roster.teamId -
                                                        200 +
                                                        (nonBotTeamsCount +
                                                            1)}{" "}
                                                    ({roster.teamId})
                                                </>
                                            ) : (
                                                <>Team {roster.teamId}</>
                                            )}
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleToggleExpand(
                                                        roster.teamId
                                                    )
                                                }
                                            >
                                                {expandedRoster ===
                                                roster.teamId ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                            </IconButton>
                                        </Box>
                                    </TableCell>

                                    <TableCell align="center">
                                        {roster.totalKills}
                                    </TableCell>
                                    <TableCell align="center">
                                        {roster.totalDeaths}
                                    </TableCell>
                                    <TableCell align="center">
                                        {roster.kdRatio.toFixed(2)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {roster.totalDamage
                                            .toFixed(0)
                                            .toLocaleString()}
                                    </TableCell>
                                    <TableCell align="center">
                                        {roster.totalDamageTaken.toLocaleString()}
                                    </TableCell>
                                    <TableCell align="center">
                                        {Math.floor(
                                            roster.highestSurvivalTime / 60
                                        )}
                                        m {roster.highestSurvivalTime % 60}s
                                    </TableCell>
                                </TableRow>
                                {/* Collapsible Content Row */}
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        sx={{ paddingBottom: 0, paddingTop: 0 }}
                                    >
                                        <Collapse
                                            in={
                                                expandedRoster === roster.teamId
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <Box
                                                sx={{
                                                    margin: 2,
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "left",
                                                        alignItems: "center",
                                                        gap: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                            fontWeight: "bold",
                                                            display:
                                                                "inline-block",
                                                            textTransform:
                                                                "uppercase",
                                                            letterSpacing:
                                                                "0.75px",
                                                            fontSize: "0.9rem",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Team Members:
                                                    </Typography>

                                                    {roster.participants
                                                        .map((participantId) =>
                                                            participants.find(
                                                                (p) =>
                                                                    p.participantId ===
                                                                    participantId
                                                            )
                                                        )
                                                        .filter(Boolean) // Ensure we only map valid players
                                                        .map((player) => (
                                                            <Box
                                                                key={
                                                                    player?.participantId
                                                                }
                                                                sx={{
                                                                    padding:
                                                                        "8px",
                                                                    borderRadius:
                                                                        "8px",
                                                                    background:
                                                                        "rgba(0, 0, 0, 0.1)",
                                                                    minWidth:
                                                                        "120px",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        player?.name
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                </Box>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default RostersTable;
