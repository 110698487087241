import React, { useState } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Typography,
    Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Icons
import trophy from "assets/matchCardIcons/trophy.png";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SportsMmaIcon from "@mui/icons-material/SportsMma"; // Most Kills Icon
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"; // Highest Damage Icon
import SectionHeader from "components/MatchDetails/SectionHeader";

interface TdmPlayer {
    kills: number;
    deaths: number;
    killScore: number;
    damageDealt: number;
    damageTaken: number;
}

interface TeamStats {
    teamNumber: number;
    totalKills: number;
    totalDeaths: number;
    kdRatio: string;
    totalDamageDealt: number;
    totalDamageTaken: number;
    totalKillScore: number;
}

interface TdmRosterLeaderboardProps {
    tdmStats: {
        team1Roster: TdmPlayer[];
        team2Roster: TdmPlayer[];
    };
    winningTeam: number;
}

// Define the valid sorting keys
type SortableKeys = keyof TeamStats;

const TdmRosterLeaderboard: React.FC<TdmRosterLeaderboardProps> = ({
    tdmStats,
    winningTeam,
}) => {
    const theme = useTheme();

    const [sortConfig, setSortConfig] = useState<{
        key: SortableKeys;
        direction: "asc" | "desc";
    }>({
        key: "totalKillScore",
        direction: "desc",
    });

    // Function to aggregate team stats
    const aggregateTeamStats = (
        teamRoster: TdmPlayer[],
        teamNumber: number
    ): TeamStats => {
        const totalKills = teamRoster.reduce(
            (sum, player) => sum + player.kills,
            0
        );
        const totalDeaths = teamRoster.reduce(
            (sum, player) => sum + player.deaths,
            0
        );
        const totalDamageDealt = teamRoster.reduce(
            (sum, player) => sum + player.damageDealt,
            0
        );
        const totalDamageTaken = teamRoster.reduce(
            (sum, player) => sum + player.damageTaken,
            0
        );
        const totalKillScore = teamRoster.reduce(
            (sum, player) => sum + player.killScore,
            0
        );

        return {
            teamNumber,
            totalKills,
            totalDeaths,
            kdRatio:
                totalDeaths > 0
                    ? (totalKills / totalDeaths).toFixed(2)
                    : totalKills.toFixed(2),
            totalDamageDealt,
            totalDamageTaken,
            totalKillScore,
        };
    };

    // Aggregate stats for both teams
    const team1Stats = aggregateTeamStats(tdmStats.team1Roster, 1);
    const team2Stats = aggregateTeamStats(tdmStats.team2Roster, 2);

    // Array containing both teams
    const teams: TeamStats[] = [team1Stats, team2Stats];

    // Determine highest kills and highest damage
    const maxKills = Math.max(...teams.map((team) => team.totalKills), 0);
    const maxDamage = Math.max(
        ...teams.map((team) => team.totalDamageDealt),
        0
    );

    // Sorting logic
    const sortedTeams = [...teams].sort((a, b) => {
        const key = sortConfig.key;
        const order = sortConfig.direction === "asc" ? 1 : -1;

        if (key === "kdRatio") {
            return (parseFloat(a.kdRatio) - parseFloat(b.kdRatio)) * order;
        }

        return ((a[key] as number) - (b[key] as number)) * order;
    });

    const handleSort = (key: SortableKeys) => {
        setSortConfig((prev) => ({
            key,
            direction:
                prev.key === key && prev.direction === "asc" ? "desc" : "asc",
        }));
    };

    return (
        <Box sx={{ width: "100%", mt: 3 }}>
            {/* Enhanced Title Section */}

            <SectionHeader sectionType="teamsLeaderboard" itemCount={2} />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Team
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "totalKills"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("totalKills")}
                                >
                                    Kills
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "totalDeaths"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("totalDeaths")}
                                >
                                    Deaths
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "kdRatio"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("kdRatio")}
                                >
                                    K/D
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={
                                        sortConfig.key === "totalDamageDealt"
                                    }
                                    direction={sortConfig.direction}
                                    onClick={() =>
                                        handleSort("totalDamageDealt")
                                    }
                                >
                                    Damage Dealt
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={
                                        sortConfig.key === "totalDamageTaken"
                                    }
                                    direction={sortConfig.direction}
                                    onClick={() =>
                                        handleSort("totalDamageTaken")
                                    }
                                >
                                    Damage Taken
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "totalKillScore"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("totalKillScore")}
                                >
                                    Score
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedTeams.map((team) => (
                            <TableRow key={team.teamNumber}>
                                {/* Team Name and Win/Loss Indicator */}
                                <TableCell
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                    }}
                                >
                                    {/* Win/Loss Icon */}
                                    {team.teamNumber === winningTeam ? (
                                        <Tooltip title="Winner">
                                            <img
                                                src={trophy}
                                                alt="Trophy Icon"
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Lost">
                                            <SentimentDissatisfiedIcon
                                                sx={{
                                                    color: "red",
                                                    fontSize: 24,
                                                }}
                                            />
                                        </Tooltip>
                                    )}

                                    {/* Highest Kills Icon */}
                                    {team.totalKills === maxKills &&
                                        team.totalKills > 0 && (
                                            <Tooltip title="Most Kills">
                                                <SportsMmaIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "red",
                                                    }}
                                                />
                                            </Tooltip>
                                        )}

                                    {/* Highest Damage Icon */}
                                    {team.totalDamageDealt === maxDamage &&
                                        team.totalDamageDealt > 0 && (
                                            <Tooltip title="Highest Damage">
                                                <LocalFireDepartmentIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "orange",
                                                    }}
                                                />
                                            </Tooltip>
                                        )}

                                    <Typography>
                                        Team {team.teamNumber}
                                    </Typography>
                                </TableCell>

                                {/* Team Stats */}
                                <TableCell align="center">
                                    {team.totalKills}
                                </TableCell>
                                <TableCell align="center">
                                    {team.totalDeaths}
                                </TableCell>
                                <TableCell align="center">
                                    {team.kdRatio}
                                </TableCell>
                                <TableCell align="center">
                                    {team.totalDamageDealt.toLocaleString()}
                                </TableCell>
                                <TableCell align="center">
                                    {team.totalDamageTaken.toLocaleString()}
                                </TableCell>
                                <TableCell align="center">
                                    {team.totalKillScore.toLocaleString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TdmRosterLeaderboard;
