export const processCarePackages = (
    telemetryData: any[],
    matchCreatedAt: string
) => {
    const matchStartTime = new Date(matchCreatedAt).getTime();
    let carePackageList: {
        type: "Red Crate" | "BRDM";
        phase: number;
        landedAt: string;
        weapon?: string;
        lootedAfter?: string;
        lootedBy?: string;
        uniqueId: number;
    }[] = [];

    let carePackageMap: Record<number, number> = {}; // Maps `carePackageUniqueId` to care package order

    // Process care package landings
    telemetryData.forEach((event) => {
        if (event._T === "LogCarePackageLand") {
            const isRedCrate =
                event.itemPackage.itemPackageId === "Carapackage_RedBox_C";
            const isBRDM = event.itemPackage.itemPackageId === "BP_BRDM_C";
            if (!isRedCrate && !isBRDM) return;

            const landedTime = new Date(event._D).getTime();
            const elapsedSeconds = Math.floor(
                (landedTime - matchStartTime) / 1000
            );
            const minutes = Math.floor(elapsedSeconds / 60);
            const seconds = elapsedSeconds % 60;
            const formattedLandedTime = `${minutes}m ${seconds}s`;

            const packageData = {
                type: isRedCrate ? ("Red Crate" as const) : ("BRDM" as const),
                phase: event.common.isGame,
                landedAt: formattedLandedTime,
                weapon: isRedCrate
                    ? event.itemPackage.items
                          .filter((item: any) => item.category === "Weapon") // Only store the weapon
                          .map((item: any) => item.itemId)
                    : undefined,
                lootedAfter: undefined,
                lootedBy: undefined,
                uniqueId: carePackageList.length, // Assign unique order index
            };

            carePackageList.push(packageData);
            carePackageMap[carePackageList.length - 1] = packageData.uniqueId;
        }
    });

    // Process looting events
    telemetryData.forEach((event) => {
        if (
            event._T === "LogItemPickupFromCarepackage" &&
            event.item.category === "Weapon"
        ) {
            const lootedTime = new Date(event._D).getTime();
            const elapsedSeconds = Math.floor(
                (lootedTime - matchStartTime) / 1000
            );
            const minutes = Math.floor(elapsedSeconds / 60);
            const seconds = elapsedSeconds % 60;
            const formattedLootedTime = `${minutes}m ${seconds}s`;

            const carePackageIndex = carePackageMap[event.carePackageUniqueId];
            if (carePackageIndex !== undefined) {
                const packageData = carePackageList[carePackageIndex];
                if (packageData.type === "Red Crate") {
                    const landedTime = packageData.landedAt.split("m ");
                    const landedSeconds =
                        parseInt(landedTime[0]) * 60 + parseInt(landedTime[1]);

                    const lootElapsed = elapsedSeconds - landedSeconds;
                    const lootMinutes = Math.floor(lootElapsed / 60);
                    const lootSeconds = lootElapsed % 60;
                    const formattedLootElapsed = `${lootMinutes}m ${lootSeconds}s`;

                    packageData.lootedAfter = formattedLootElapsed;
                    packageData.lootedBy = event.character.name;
                }
            }
        }
    });

    return carePackageList;
};
