import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import { patches } from "../constants/patches";
import ReactGA from "react-ga4";
import { COLORS } from "constants/appWide/colors";
import { maxWidths } from "constants/appWide/maxWidths";

export const PatchesPage: React.FC = () => {
    const [expandedPatch, setExpandedPatch] = useState<number | null>(null);

    const toggleSummary = (index: number) => {
        setExpandedPatch(expandedPatch === index ? null : index);
    };

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/patches",
            title: "Patch History Page",
        });
    }, []);

    return (
        <Box
            sx={{
                maxWidth: maxWidths.m,
                margin: "0 auto",
                padding: "20px",
                textAlign: "center",
            }}
        >
            <Box sx={{ padding: "20px", textAlign: "left" }}>
                <Typography variant="h5" textAlign={"center"} gutterBottom>
                    Patch History (PC)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Explore the history of PUBG (PC) patches with their details
                    and summaries. This list is maintained for archival
                    purposes, ensuring that you can access patch information
                    even if it becomes unavailable on the official PUBG website
                    in the future.
                </Typography>
                <Box
                    component="ul"
                    sx={{ paddingLeft: "20px", marginBottom: 2 }}
                >
                    <li>
                        This list contains the{" "}
                        <strong>{patches.length} PC patches</strong> that have
                        been officially posted on the PUBG website.
                    </li>
                    <li>
                        Note: Earlier patches, released when PUBG used Steam or
                        Reddit for patch notes, are not included here.
                    </li>
                    <li>
                        For a complete history of older patches, you can visit:
                        <Box
                            component="ul"
                            sx={{ paddingLeft: "20px", marginTop: 1 }}
                        >
                            <li>
                                <a
                                    href="https://pubg.fandom.com/wiki/Patch_Notes"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: "#1976d2",
                                        textDecoration: "none",
                                    }}
                                >
                                    PUBG Fandom Wiki
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://pubg.wiki.gg/wiki/Patch_Notes"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: "#1976d2",
                                        textDecoration: "none",
                                    }}
                                >
                                    PUBG Wiki.gg
                                </a>
                            </li>
                        </Box>
                    </li>
                </Box>
                <Typography variant="body1">
                    For the official patch notes, you can visit the{" "}
                    <a
                        href="https://pubg.com/en/news?category=patch_notes"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#1976d2", textDecoration: "none" }}
                    >
                        PUBG Patch Notes Page
                    </a>
                    .
                </Typography>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "20px",
                }}
            >
                {patches.map((patch, index) => (
                    <Paper
                        key={index}
                        elevation={3}
                        sx={{
                            padding: "10px 20px",
                            width: "100%",
                            maxWidth: maxWidths.m,
                            textAlign: "left",
                            borderRadius: "8px",
                        }}
                    >
                        <Typography variant="h6">{patch.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                            Announced: {patch.date}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "10px",
                            }}
                        >
                            {patch.summary && (
                                <Button
                                    variant="outlined"
                                    onClick={() => toggleSummary(index)}
                                    sx={{
                                        textTransform: "none", // Prevent uppercase text
                                        display: "flex", // Align items horizontally
                                        alignItems: "center", // Center items vertically
                                        color: COLORS.orange, // Text color
                                        borderColor: COLORS.orange, // Outline color
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(255, 165, 0, 0.1)", // Light orange background on hover
                                            borderColor: COLORS.darkOrange, // Darker orange on hover
                                        },
                                    }}
                                >
                                    {expandedPatch === index
                                        ? "↑ Hide Summary ↑"
                                        : "↓ View Summary ↓"}
                                </Button>
                            )}
                            <Button
                                href={patch.link}
                                target="_blank"
                                rel="noopener"
                                sx={{
                                    textTransform: "none", // Prevent uppercase text
                                    display: "flex", // Align items horizontally
                                    alignItems: "center", // Center items vertically
                                    color: COLORS.orange, // Text color
                                    borderColor: COLORS.orange, // Outline color
                                    "&:hover": {
                                        backgroundColor:
                                            "rgba(255, 165, 0, 0.1)", // Light orange background on hover
                                        borderColor: COLORS.darkOrange, // Darker orange on hover
                                    },
                                }}
                                variant="outlined"
                            >
                                View Online
                            </Button>
                            {patch.pdf && (
                                <Button
                                    href={patch.pdf}
                                    target="_blank"
                                    rel="noopener"
                                    sx={{
                                        textTransform: "none", // Prevent uppercase text
                                        display: "flex", // Align items horizontally
                                        alignItems: "center", // Center items vertically
                                        color: COLORS.orange, // Text color
                                        borderColor: COLORS.orange, // Outline color
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(255, 165, 0, 0.1)", // Light orange background on hover
                                            borderColor: COLORS.darkOrange, // Darker orange on hover
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    View PDF
                                </Button>
                            )}
                        </Box>
                        {expandedPatch === index && patch.summary && (
                            <Typography
                                variant="body2"
                                sx={{
                                    marginTop: "10px",
                                    whiteSpace: "pre-line",
                                    fontStyle: "italic",
                                }}
                            >
                                {patch.summary}
                            </Typography>
                        )}
                    </Paper>
                ))}
            </Box>
        </Box>
    );
};

export default PatchesPage;
