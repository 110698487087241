import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Paper,
    Button,
    IconButton,
    useTheme,
    Grid,
    Card,
    CardContent,
    Divider,
    useMediaQuery,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import MatchCard from "components/MatchesView/MatchCard";
import { Match } from "types/match";
import { enrichMatchData } from "utils/matchData/enrichMatchData";

// Google Analytics
import ReactGA from "react-ga4";

/// Heart Icons
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Win icon
import CancelIcon from "@mui/icons-material/Cancel"; // Loss icon
import StarIcon from "@mui/icons-material/Star";
import { useAuth } from "AuthContext";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import { addSeconds, format } from "date-fns";
import { COLORS } from "constants/appWide/colors";
import { useTelemetry } from "hooks/useTelemetry";
import KillTree from "components/trees/KillTree";
import { processLogPlayerKillV2 } from "utils/telemetryProcessing/processLogPlayerKillV2";
import { processLogMatchEnd } from "utils/telemetryProcessing/processLogMatchEnd";
import { WeaponCard } from "components/WeaponCard";
import { getWeaponMetadata } from "utils/weapons/getWeaponMetaData";
import FunnyLoading from "components/FunnyLoading";
import { weatherMap } from "constants/weatherMap";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { maxWidths } from "constants/appWide/maxWidths";
import PlayerTag from "components/PlayerTag";
import { getPlatformLogo } from "utils/getPlatformLogo";
import BanStatus from "components/BanStatus";
import { useSurvivalMastery } from "hooks/pubgApi/useSurvivalMastery";
import { useClanInfo } from "hooks/pubgApi/useClanInfo";
import { useAccountData } from "hooks/pubgApi/useAccountData";
import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";
import { emphasizedTypographyStyle } from "styles/styles";
import { processTdmTelemetry } from "utils/telemetryProcessing/processTdmTelemetry";
import TdmPlayerLeaderboard from "components/MatchDetailsTables/TdmPlayerLeaderboard";
import { processRecalls } from "utils/telemetryProcessing/processRecalls";
import RecalledPlayersTable from "components/MatchDetailsTables/RecalledPlayersTable";
import ParticipantsTable from "components/MatchDetailsTables/ParticipantsTable";
import { processNormalMatchTelemetry } from "utils/telemetryProcessing/processNormalMatchTelemetry";
import RostersTable from "components/MatchDetailsTables/RostersTable";
import TdmRosterLeaderboard from "components/MatchDetailsTables/TdmRosterLeaderboard";
import BotListTable from "components/MatchDetailsTables/BotListTable";
import { processEmergencyPickup } from "utils/telemetryProcessing/processEmergencyPickup";
import EmergencyPickupTable from "components/MatchDetailsTables/EmergencyPickupTable";
import SpecialWeaponKillsTable from "components/MatchDetailsTables/SpecialWeaponKillsTable";
import { processSpecialWeaponKills } from "utils/telemetryProcessing/processSpecialWeaponKills";
import { processSelfRevives } from "utils/telemetryProcessing/processSelfRevives";
import SelfRevivesTable from "components/MatchDetailsTables/SelfRevivesTable";
import { processCarePackages } from "utils/telemetryProcessing/processCarePackages";
import CarePackageTable from "components/MatchDetailsTables/CratePackageTable";
import JumpToSection from "components/MatchDetails/JumpToSection";
import SectionHeader, {
    sectionConfig,
} from "components/MatchDetails/SectionHeader";
import { useScrollToTop } from "components/ScrollToTop";

export interface BotPlayer {
    name: string;
    accountId: string;
    ranking: number;
    individualRanking: number;
}

export interface NormalMatchStats {
    accountId: string;
    name: string;
    kills: number;
    deaths: number;
    damageDealt: number;
    damageTaken: number;
}

const MatchDetails: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {
        platform = "steam",
        playerName = "Unknown Player",
        matchId,
    } = useParams<{
        platform?: string;
        playerName?: string;
        matchId?: string;
    }>();

    const { accountId, clanId, banType, accountLoading, accountError } =
        useAccountData(platform, playerName);

    // Using Banner Hooks
    const { clanInfo, clanLoading, clanError } = useClanInfo(clanId, platform);

    const { survivalMastery, survivalLoading, survivalError } =
        useSurvivalMastery(accountId, platform);

    const [matchRegion, setMatchRegion] = useState<string | null>(null);
    const [weather, setWeather] = useState<string | null>(null);
    const weatherInfo =
        weather && weatherMap[weather]
            ? weatherMap[weather]
            : { label: weather, emoji: "" };

    const [matchData, setMatchData] = useState<Match | null>(null);

    const [matchDataLoading, setMatchDataLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Save Match
    const [isSaved, setIsSaved] = useState(false);
    const { isAuthenticated } = useAuth();

    const favoriteGameMode = localStorage.getItem("favoriteGameMode") || "";

    const [rosterSortField, setRosterSortField] = useState<
        "rank" | "totalKills" | "totalDamage"
    >("rank");
    const [rosterSortOrder, setRosterSortOrder] = useState<"asc" | "desc">(
        "asc"
    );

    //////////////// Sections
    // Generate the sections array dynamically from sectionConfig
    const sections = Object.keys(sectionConfig).map((id) => ({ id }));

    // Create refs for each section dynamically
    const sectionRefs = Object.fromEntries(
        sections.map((section) => [section.id, useRef<HTMLDivElement>(null)])
    ) as Record<string, React.RefObject<HTMLDivElement>>;

    // Scroll function
    const scrollToSection = (id: string) => {
        sectionRefs[id]?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    const { jumpToSectionRef } = useScrollToTop();

    //////////////

    const [recalledPlayers, setRecalledPlayers] = useState<
        {
            name: string;
            phases: number[];
            timestamps: string[];
            respawnTimes: string[];
        }[]
    >([]);

    const [emergencyPickupUsers, setEmergencyPickupUsers] = useState<
        {
            name: string;
            attachPhase: number;
            detachPhase: number | null; // Null if they never detached
        }[]
    >([]);

    const [specialWeaponKills, setSpecialWeaponKills] = useState<
        { killer: string; victim: string; distance: number; weapon: string }[]
    >([]);

    const [selfRevives, setSelfRevives] = useState<
        {
            name: string;
            phase: number;
            reviveTime: string;
        }[]
    >([]);

    // Telemetry Data: LogMatchEnd
    const [playerRankings, setPlayerRankings] = useState<
        {
            name: string;
            accountId: string;
            ranking: number;
            individualRanking: number;
            primaryWeaponFirst?: string;
            primaryWeaponSecond?: string;
        }[]
    >([]);

    const [carePackages, setCarePackages] = useState<
        {
            type: "Red Crate" | "BRDM";
            phase: number;
            landedAt: string; // Time in "m s" format
            content?: string[]; // Only for Red Crate
            lootedAfter?: string; // Only for Red Crate
            lootedBy?: string; // Only for Red Crate
        }[]
    >([]);

    const [botList, setBotList] = useState<BotPlayer[]>([]);

    const saveBotsToBackend = async (botNames: string[]) => {
        try {
            const response = await fetch(API_ENDPOINTS.SAVE_BOTS, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ botNames }),
            });

            const data = await response.json();
            console.log("Bot Save Response:", data);
        } catch (error) {
            console.error("Error saving bots:", error);
        }
    };

    // Telemetry Data: LogPlayerKillV2
    const [killTree, setKillTree] = useState<{
        [killer: string]: {
            victim: string;
            distance: number;
            weapon: string;
        }[];
    }>({});
    const [remainingPlayers, setRemainingPlayers] = useState<string[]>([]);

    // Get Telemetry
    const {
        telemetryData,
        loading: telemetryLoading,
        error: telemetryError,
    } = useTelemetry(matchId!, platform);

    const [normalMatchStats, setNormalMatchStats] = useState<
        NormalMatchStats[]
    >([]);

    const [telemetryProcessing, setTelemetryProcessing] =
        useState<boolean>(false); // ✅ Main loading state

    useEffect(() => {
        if (!telemetryData || matchDataLoading) return;

        setTelemetryProcessing(true);

        const processTelemetry = async () => {
            try {
                // LogMatchEnd
                const matchEndEvent = telemetryData.find(
                    (event) => event._T === "LogMatchEnd"
                );

                if (matchEndEvent) {
                    const { playerRankings, botList } =
                        processLogMatchEnd(matchEndEvent);
                    setPlayerRankings(playerRankings);
                    setBotList(botList);
                    if (botList.length > 0) {
                        saveBotsToBackend(botList.map((bot) => bot.name));
                    }
                }

                // Extract LogPlayerKillV2 events
                const logPlayerKillEvents = telemetryData.filter(
                    (event) => event._T === "LogPlayerKillV2"
                );

                const { killTree, remainingPlayers } =
                    processLogPlayerKillV2(logPlayerKillEvents);
                setKillTree(killTree);
                setRemainingPlayers(remainingPlayers);

                // Extract Region
                const logMatchDefinitionEvent = telemetryData.find(
                    (event) => event._T === "LogMatchDefinition"
                );

                if (logMatchDefinitionEvent) {
                    const matchId = logMatchDefinitionEvent.MatchId;
                    const matchRegion = matchId.split(".")[6]; // "as" in this case
                    setMatchRegion(matchRegion.toUpperCase());
                }

                // Extract Weather
                const matchStartEvent = telemetryData.find(
                    (event) => event._T === "LogMatchStart"
                );

                if (matchStartEvent?.weatherId) {
                    setWeather(matchStartEvent.weatherId);
                }

                // Do not check for IBR
                if (
                    matchData &&
                    telemetryData &&
                    matchData?.gameMode !== "tdm" &&
                    matchData?.gameMode !== "ibr" &&
                    matchData?.gameMode !== "training"
                ) {
                    const recalledPlayers = await processRecalls(
                        telemetryData,
                        matchData?.createdAt
                    );
                    setRecalledPlayers(recalledPlayers);
                }

                // Check for IBR
                if (
                    matchData &&
                    telemetryData &&
                    matchData?.gameMode !== "tdm" &&
                    matchData?.gameMode !== "training"
                ) {
                    const extractedSelfRevives = await processSelfRevives(
                        telemetryData,
                        matchData.createdAt
                    );
                    setSelfRevives(extractedSelfRevives);
                    const emergencyPickupUsers = await processEmergencyPickup(
                        telemetryData
                    );
                    setEmergencyPickupUsers(emergencyPickupUsers);
                    const extractedSpecialKills =
                        await processSpecialWeaponKills(telemetryData);
                    setSpecialWeaponKills(extractedSpecialKills);
                    const updatedMatch = await processNormalMatchTelemetry(
                        matchData
                    );
                    if (updatedMatch.normalMatchStats) {
                        setNormalMatchStats(updatedMatch.normalMatchStats);
                    }
                    const extractedCrateData = await processCarePackages(
                        telemetryData,
                        matchData.createdAt
                    );
                    setCarePackages(extractedCrateData);
                }
            } catch (error) {
                console.error("Error processing telemetry:", error);
            } finally {
                setTelemetryProcessing(false);
            }
        };

        processTelemetry();
    }, [telemetryData, matchDataLoading]);

    // Filter winning players (ranking === 1)
    const winningPlayers = playerRankings.filter(
        (player) => player.ranking === 1
    );

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/matches/",
            title: "Match Details Page",
        });
    }, []);

    // Fetch match details
    useEffect(() => {
        if (!matchId || !platform || !accountId) return;
        const fetchMatchDetails = async () => {
            setMatchDataLoading(true);
            setError(null);

            try {
                const matchResponse = await fetch(
                    API_ENDPOINTS.MATCH_DETAILS(platform, matchId)
                );

                if (!matchResponse.ok) {
                    throw new Error(
                        `Failed to fetch details for match ID: ${matchId}`
                    );
                }

                const matchData = await matchResponse.json();

                // Initialize enrichedData
                let enrichedData = matchData;

                // Check if the game mode is TDM
                if (matchData.gameMode === "tdm" && matchData.telemetryUrl) {
                    // Process TDM telemetry
                    enrichedData = await processTdmTelemetry(
                        matchData,
                        accountId,
                        playerName
                    );
                }

                // Apply the standard enrichment logic
                enrichedData = enrichMatchData(
                    enrichedData,
                    accountId,
                    playerName
                );

                setMatchData(enrichedData);
            } catch (err) {
                setError(
                    (err as Error).message ||
                        "An error occurred while fetching match details."
                );
            } finally {
                setMatchDataLoading(false);
            }
        };

        fetchMatchDetails();
    }, [matchId, platform, playerName, accountId]);

    const checkIfMatchSaved = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(API_ENDPOINTS.SAVED_MATCHES, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch saved matches");
            }

            const data = await response.json();
            const matchSaved = data.matches.some(
                (match: { matchId: string; platform: string }) =>
                    match.matchId === matchId && match.platform === platform
            );

            setIsSaved(matchSaved);
        } catch (error) {
            console.error("Error checking saved match:", error);
        }
    };

    const handleToggleSave = async () => {
        try {
            const token = localStorage.getItem("authToken");

            if (isSaved) {
                const response = await fetch(API_ENDPOINTS.REMOVE_MATCH, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ matchId, platform }),
                });

                if (!response.ok) {
                    throw new Error("Failed to remove match");
                }

                setIsSaved(false);
            } else {
                const response = await fetch(API_ENDPOINTS.SAVE_MATCH, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        matchId,
                        platform,
                        matchData,
                        playerName,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Failed to save match");
                }

                setIsSaved(true);
            }
        } catch (error) {
            console.error("Error toggling save:", error);
            alert("An error occurred while toggling the saved state.");
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            checkIfMatchSaved();
        }
    }, [isAuthenticated, matchId, platform]);

    const handleRosterSort = (field: "rank" | "totalKills" | "totalDamage") => {
        if (rosterSortField === field) {
            setRosterSortOrder(rosterSortOrder === "asc" ? "desc" : "asc");
        } else {
            setRosterSortField(field);
            setRosterSortOrder("asc");
        }
    };

    const sortData = <T,>(
        data: T[],
        field: keyof T,
        order: "asc" | "desc"
    ): T[] => {
        return [...data].sort((a, b) => {
            const aValue = a[field] as number;
            const bValue = b[field] as number;

            return order === "asc" ? aValue - bValue : bValue - aValue;
        });
    };

    if (
        matchDataLoading ||
        telemetryProcessing ||
        accountLoading ||
        clanLoading ||
        survivalLoading ||
        !survivalMastery
    ) {
        return <FunnyLoading />;
    }

    if (error)
        return (
            <Typography color="error" variant="body1">
                {error}
            </Typography>
        );

    if (!matchData) return null;

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l,
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                gap: 3,
            }}
        >
            <Button
                variant="contained"
                sx={{
                    mt: 2,

                    backgroundColor: COLORS.orange,
                    fontWeight: "bold",
                    ":hover": {
                        backgroundColor: COLORS.darkOrange,
                    },
                }}
                onClick={() =>
                    navigate(
                        NAVIGATION_ROUTES.RECENT_MATCHES(platform, playerName)
                    )
                }
            >
                Back to Matches
            </Button>
            <Box
                sx={{
                    margin: "0 auto",
                    border: "2px solid #444",
                    borderRadius: "8px",
                    padding: "16px",
                    backgroundColor: "#2e2e2e",
                    marginTop: "16px",
                    marginBottom: "24px",
                    alignContent: "center",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "16px",
                        marginBottom: "16px",
                    }}
                >
                    {/* Left Section: Page Title and Banner */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                            gap: 2, // Gap between banner and favorite icon
                        }}
                    >
                        {/* Banner Section */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <img
                                src={getPlatformLogo(platform)}
                                alt={`${platform} logo`}
                                style={{ width: 26, height: 26 }}
                            />
                            <Typography
                                variant="h6"
                                sx={emphasizedTypographyStyle(theme)}
                            >
                                Match Details
                            </Typography>
                        </Box>

                        {/* Player Tag */}
                        <PlayerTag
                            playerName={playerName}
                            survivalTier={survivalMastery?.tier}
                            survivalLevel={survivalMastery?.level}
                            clanInfo={clanInfo}
                        />
                        <IconButton
                            onClick={handleToggleSave}
                            sx={{
                                color: isSaved ? "red" : "gray",
                                marginBottom: 1,
                            }}
                        >
                            {isSaved ? (
                                <FavoriteIcon />
                            ) : (
                                <FavoriteBorderIcon />
                            )}
                        </IconButton>
                    </Box>

                    <BanStatus banType={banType} />
                </Box>

                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "repeat(2, 1fr)", // Always 2 cards per row on mobile
                            sm: "repeat(auto-fit, minmax(200px, 1fr))", // Ensure equal card dimensions on desktop
                        },
                        justifyContent: "center", // Center the grid container
                        gap: 2, // Spacing between cards
                        padding: 2,
                        maxWidth: maxWidths.l, // Limit the width of the grid to prevent stretching
                        margin: "0 auto", // Center the grid on the page
                    }}
                >
                    {/* Match Date Box */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 2,
                            border: (theme) =>
                                `2px solid ${theme.palette.primary.main}`, // Border in theme color
                            borderRadius: 2, // Rounded corners
                            backgroundColor: (theme) =>
                                theme.palette.background.paper, // Background color
                            boxShadow: 3, // Add a subtle shadow

                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Match Date
                        </Typography>
                        <Typography variant="body1">
                            {format(
                                new Date(matchData.createdAt),
                                "MMMM d, yyyy"
                            )}
                        </Typography>
                        <Divider
                            sx={{
                                width: "100%",
                                marginY: 1,
                            }}
                        />
                        <Typography variant="body2" gutterBottom>
                            Start Time:{" "}
                            {format(
                                new Date(matchData.createdAt),
                                "hh:mm:ss a"
                            )}
                        </Typography>
                        <Typography variant="body2">
                            End Time:{" "}
                            {format(
                                addSeconds(
                                    new Date(matchData.createdAt),
                                    matchData.duration
                                ),
                                "hh:mm:ss a"
                            )}
                        </Typography>
                    </Box>

                    {/* Players / Bots Box */}
                    {matchData.gameMode !== "tdm" && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 2,
                                border: (theme) =>
                                    `2px solid ${theme.palette.primary.main}`, // Border in theme color
                                borderRadius: 2, // Rounded corners
                                backgroundColor: (theme) =>
                                    theme.palette.background.paper, // Background color
                                boxShadow: 3, // Add a subtle shadow

                                textAlign: "center",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                Enemies:{" "}
                                {matchData.participants.length -
                                    matchData.playerRoster.length}
                            </Typography>
                            <Divider
                                sx={{
                                    width: "100%",
                                    marginY: 1,
                                }}
                            />

                            <Typography variant="body1">
                                Players:{" "}
                                <strong>
                                    {matchData.participants.length -
                                        matchData.playerRoster.length -
                                        botList.length}
                                </strong>
                            </Typography>
                            <Divider
                                sx={{
                                    width: "100%",
                                    marginY: 1,
                                }}
                            />
                            <Typography variant="body2" gutterBottom>
                                Bots: <strong>{botList.length}</strong> (
                                {Math.round(
                                    (botList.length /
                                        matchData.participants.length) *
                                        100
                                )}
                                %)
                            </Typography>
                        </Box>
                    )}

                    {matchData.gameMode === "tdm" &&
                        matchData.tdmStats &&
                        matchData.tdmStats.roundResults &&
                        matchData.tdmStats.roundResults.length > 0 && ( // Ensure data exists
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 2,
                                    border: (theme) =>
                                        `2px solid ${theme.palette.primary.main}`, // Border in theme color
                                    borderRadius: 2, // Rounded corners
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper, // Background color
                                    boxShadow: 3, // Add a subtle shadow
                                    textAlign: "center",
                                    width: "100%", // Full width
                                }}
                            >
                                {/* Loop through round results */}
                                {matchData.tdmStats.roundResults.map(
                                    (round, index) => {
                                        const playerTeamId =
                                            matchData.tdmStats?.playerTeamId;
                                        const playerWon =
                                            (playerTeamId === 1 &&
                                                round.team1Kills >
                                                    round.team2Kills) ||
                                            (playerTeamId === 2 &&
                                                round.team2Kills >
                                                    round.team1Kills);

                                        const playerTeamKills =
                                            playerTeamId === 1
                                                ? round.team1Kills
                                                : round.team2Kills;
                                        const enemyTeamKills =
                                            playerTeamId === 1
                                                ? round.team2Kills
                                                : round.team1Kills;

                                        return (
                                            <React.Fragment key={index}>
                                                {/* Round Info */}
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: {
                                                            xs: "column",
                                                            sm: "row",
                                                        },
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        width: "100%",
                                                        paddingY: 1,
                                                    }}
                                                >
                                                    {/* Win or Loss Icon */}
                                                    {playerWon ? (
                                                        <CheckCircleIcon
                                                            fontSize="small"
                                                            sx={{
                                                                color: "green",
                                                                marginBottom: {
                                                                    xs: 0.5,
                                                                    sm: 0,
                                                                }, // Add spacing only on mobile
                                                                marginRight: {
                                                                    xs: 0,
                                                                    sm: 1,
                                                                }, // Right margin only for larger screens
                                                            }}
                                                        />
                                                    ) : (
                                                        <CancelIcon
                                                            fontSize="small"
                                                            sx={{
                                                                color: "red",
                                                                marginBottom: {
                                                                    xs: 0.5,
                                                                    sm: 0,
                                                                }, // Add spacing only on mobile
                                                                marginRight: {
                                                                    xs: 0,
                                                                    sm: 1,
                                                                }, // Right margin only for larger screens
                                                            }}
                                                        />
                                                    )}

                                                    <Typography variant="body2">
                                                        <strong>
                                                            Round{" "}
                                                            {round.roundNumber}:
                                                        </strong>{" "}
                                                        {playerTeamKills} -{" "}
                                                        {enemyTeamKills}
                                                    </Typography>
                                                </Box>

                                                {/* Divider Between Rows (Not After Last Row) */}
                                                {index !==
                                                    (matchData.tdmStats
                                                        ?.roundResults
                                                        ?.length || 0) -
                                                        1 && (
                                                    <Divider
                                                        sx={{
                                                            width: "100%",
                                                            marginY: 1,
                                                        }}
                                                    />
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </Box>
                        )}

                    {/* Weather Box */}
                    {weather !== null && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 2,
                                border: (theme) =>
                                    `2px solid ${theme.palette.primary.main}`, // Border in theme color
                                borderRadius: 2, // Rounded corners
                                backgroundColor: (theme) =>
                                    theme.palette.background.paper, // Background color
                                boxShadow: 3, // Add a subtle shadow

                                textAlign: "center",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                Weather
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: "bold", margin: "8px 0" }}
                            >
                                {weatherInfo.emoji}
                            </Typography>
                            <Typography variant="body1">
                                {weatherInfo.label}
                            </Typography>
                        </Box>
                    )}

                    {/*  Region Box */}
                    {weather !== null && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 2,
                                border: (theme) =>
                                    `2px solid ${theme.palette.primary.main}`, // Border in theme color
                                borderRadius: 2, // Rounded corners
                                backgroundColor: (theme) =>
                                    theme.palette.background.paper, // Background color
                                boxShadow: 3, // Add a subtle shadow

                                textAlign: "center",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                Region
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: "bold", margin: "8px 0" }}
                            >
                                {matchRegion}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            {/* Add Detailed MatchCard */}
            <MatchCard
                match={matchData}
                accountId={accountId}
                viewType="detailedCard"
                platform={platform}
                playerName={playerName}
                onMatchHistoryPage={false}
                favoriteGameMode={favoriteGameMode}
                showImages={true}
            />

            {/* Jump to Section Navigation */}
            <Box ref={jumpToSectionRef}>
                <JumpToSection
                    sections={sections}
                    scrollToSection={scrollToSection}
                    isTdm={matchData.gameMode === "tdm"}
                />
            </Box>

            <Box ref={sectionRefs["winningTeam"]}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                    }}
                >
                    <SectionHeader
                        sectionType="winningTeam"
                        itemCount={winningPlayers.length}
                    />
                </Box>

                <Grid container spacing={3}>
                    {winningPlayers
                        .map((player) => {
                            // Find the player data from participants
                            const playerStats = matchData.participants.find(
                                (participant) =>
                                    participant.name === player.name
                            );

                            // Calculate MVP score
                            const mvpScore =
                                (playerStats?.kills || 0) * 100 +
                                (playerStats?.damageDealt || 0);

                            return {
                                ...player,
                                kills: playerStats?.kills || 0,
                                damageDealt: playerStats?.damageDealt || 0,
                                mvpScore, // Add MVP score to the player object
                            };
                        })
                        .sort((a, b) => b.mvpScore - a.mvpScore) // Sort by MVP score in descending order
                        .map((player, index) => {
                            // Check if the current player is the MVP
                            const isMVP = index === 0; // The first player after sorting is the MVP

                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={player.accountId}
                                >
                                    <Card
                                        sx={{
                                            background: `linear-gradient(135deg,${theme.palette.secondary.main}, ${theme.palette.custom.accentColor2})`,
                                            color: theme.palette.text.primary,
                                            border: isMVP
                                                ? "8px solid gold"
                                                : `2px solid ${theme.palette.divider}`,
                                            borderRadius: "12px",
                                            boxShadow:
                                                "0px 6px 12px rgba(0, 0, 0, 0.15)",
                                            overflow: "hidden",
                                            position: "relative",
                                            transition:
                                                "transform 0.2s ease-in-out",
                                            ":hover": {
                                                transform: "translateY(-10px)",
                                                boxShadow:
                                                    "0px 10px 20px rgba(0, 0, 0, 0.25)",
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            {isMVP && (
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        backgroundColor: "gold",
                                                        color: "black",
                                                        display: "flex", // Ensure text and icon are inline
                                                        alignItems: "center", // Vertically align text and icon
                                                        padding: "0px 8px",
                                                        lineHeight: "1.4",
                                                        borderBottomRightRadius:
                                                            "8px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    MVP
                                                    <StarIcon
                                                        sx={{
                                                            fontSize: "16px", // Adjust size as needed
                                                            marginLeft: "4px", // Add space between text and icon
                                                        }}
                                                    />
                                                </Typography>
                                            )}
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: "bold",
                                                    mb: 2,
                                                    textAlign: "center",
                                                    color: theme.palette.primary
                                                        .contrastText,
                                                }}
                                            >
                                                {player.name}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    mb: 2,
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Kills: {player.kills}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Damage:{" "}
                                                    {Number(
                                                        player.damageDealt.toFixed(
                                                            0
                                                        )
                                                    ).toLocaleString()}
                                                </Typography>
                                            </Box>

                                            {(player.primaryWeaponFirst &&
                                                player.primaryWeaponFirst !==
                                                    "None") ||
                                            (player.primaryWeaponSecond &&
                                                player.primaryWeaponSecond !==
                                                    "None") ? (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: {
                                                            xs: "row",
                                                            md: "column",
                                                        }, // Row on mobile, Column on desktop
                                                        gap: 2, // Space between items
                                                        textAlign: "center",
                                                        background:
                                                            "rgba(0, 0, 0, 0.1)",
                                                        padding: "10px",
                                                        borderRadius: "8px",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {player.primaryWeaponFirst &&
                                                        player.primaryWeaponFirst !==
                                                            "None" && (
                                                            <Box
                                                                sx={{
                                                                    flex: 1,
                                                                    minWidth:
                                                                        "140px",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        mb: 1,
                                                                    }}
                                                                >
                                                                    Primary
                                                                    Weapon 1
                                                                </Typography>
                                                                <WeaponCard
                                                                    {...getWeaponMetadata(
                                                                        player.primaryWeaponFirst
                                                                    )}
                                                                />
                                                            </Box>
                                                        )}
                                                    {player.primaryWeaponSecond &&
                                                        player.primaryWeaponSecond !==
                                                            "None" && (
                                                            <Box
                                                                sx={{
                                                                    flex: 1,
                                                                    minWidth:
                                                                        "140px",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        mb: 1,
                                                                    }}
                                                                >
                                                                    Primary
                                                                    Weapon 2
                                                                </Typography>
                                                                <WeaponCard
                                                                    {...getWeaponMetadata(
                                                                        player.primaryWeaponSecond
                                                                    )}
                                                                />
                                                            </Box>
                                                        )}
                                                </Box>
                                            ) : null}

                                            {/* MVP Score */}
                                            {player.primaryWeaponFirst ===
                                                "None" &&
                                                player.primaryWeaponSecond ===
                                                    "None" && (
                                                    <Box
                                                        sx={{
                                                            textAlign: "center",
                                                            background:
                                                                "rgba(0, 0, 0, 0.1)",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            No Weapon Data
                                                            because the player
                                                            was dead at the time
                                                            of the match end.
                                                        </Typography>
                                                    </Box>
                                                )}
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    mt: 2,
                                                }}
                                            >
                                                PUBG Meta Score:{" "}
                                                {player.mvpScore.toFixed(0)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>
            </Box>

            {matchData.gameMode === "tdm" && matchData.tdmStats && (
                <>
                    <Box ref={sectionRefs["teamsLeaderboard"]}>
                        <TdmRosterLeaderboard
                            tdmStats={matchData.tdmStats}
                            winningTeam={matchData.tdmStats.winningTeamId}
                        />
                    </Box>
                    <Box ref={sectionRefs["playersLeaderboard"]}>
                        <TdmPlayerLeaderboard
                            tdmStats={{
                                team1Roster: matchData.tdmStats.team1Roster.map(
                                    (player) => ({
                                        ...player,
                                        teamNumber: 1, // Add team number to all Team 1 players
                                    })
                                ),
                                team2Roster: matchData.tdmStats.team2Roster.map(
                                    (player) => ({
                                        ...player,
                                        teamNumber: 2, // Add team number to all Team 2 players
                                    })
                                ),
                            }}
                            playerName={playerName}
                            platform={platform}
                            winningTeam={matchData.tdmStats.winningTeamId}
                        />
                    </Box>
                </>
            )}

            {matchData.gameMode !== "tdm" && normalMatchStats.length > 0 && (
                <>
                    <Box ref={sectionRefs["teamsLeaderboard"]}>
                        <RostersTable
                            playerName={playerName}
                            participants={matchData.participants}
                            rosters={matchData.rosters}
                            recalledPlayers={recalledPlayers}
                            normalMatchStats={normalMatchStats}
                            isCasualOrIbr={
                                matchData.matchType === "airoyale" ||
                                matchData.gameMode === "ibr"
                            }
                        />
                    </Box>
                    <Box ref={sectionRefs["playersLeaderboard"]}>
                        <ParticipantsTable
                            playerName={playerName}
                            participants={matchData.participants}
                            botList={botList}
                            recalledPlayers={recalledPlayers}
                            platform={platform}
                            normalMatchStats={normalMatchStats}
                            isCasualOrIbr={
                                matchData.matchType === "airoyale" ||
                                matchData.gameMode === "ibr"
                            }
                        />
                    </Box>
                </>
            )}

            {matchData.gameMode !== "tdm" && specialWeaponKills.length > 0 && (
                <Box ref={sectionRefs["specialKills"]}>
                    <SpecialWeaponKillsTable
                        specialWeaponKills={specialWeaponKills}
                        platform={platform}
                    />
                </Box>
            )}

            {carePackages.length > 0 && (
                <Box ref={sectionRefs["carePackages"]}>
                    <CarePackageTable carePackages={carePackages} />
                </Box>
            )}

            {matchData.gameMode !== "tdm" &&
                matchData.gameMode !== "ibr" &&
                recalledPlayers.length > 0 && (
                    <Box ref={sectionRefs["recalledPlayers"]}>
                        <RecalledPlayersTable
                            recalledPlayers={recalledPlayers}
                            platform={platform}
                        />
                    </Box>
                )}

            {matchData.gameMode !== "tdm" &&
                emergencyPickupUsers.length > 0 && (
                    <Box ref={sectionRefs["emergencyPickup"]}>
                        <EmergencyPickupTable
                            emergencyPickupUsers={emergencyPickupUsers}
                            platform={platform}
                        />
                    </Box>
                )}

            {matchData.gameMode !== "tdm" && selfRevives.length > 0 && (
                <Box ref={sectionRefs["selfRevived"]}>
                    <SelfRevivesTable
                        selfRevives={selfRevives}
                        platform={platform}
                    />
                </Box>
            )}

            {matchData.matchType !== "competitive" &&
                matchData.gameMode !== "tdm" &&
                matchData.gameMode !== "training" &&
                matchData.gameMode !== "trainingRoom" && (
                    <Box ref={sectionRefs["botList"]}>
                        <BotListTable botList={botList} />
                    </Box>
                )}

            {matchData.gameMode !== "tdm" &&
                matchData.gameMode !== "training" &&
                matchData.gameMode !== "trainingRoom" && (
                    <Box ref={sectionRefs["killTree"]}>
                        {telemetryLoading ? (
                            <FunnyLoading />
                        ) : telemetryError ? (
                            <Typography color="error">
                                {telemetryError}
                            </Typography>
                        ) : (
                            <>
                                {/* Kill Tree Section with Styled Header */}
                                <SectionHeader sectionType="killTree" />

                                {/* Kill Tree Content */}
                                <Box
                                    sx={{
                                        border: "2px solid #333",
                                        padding: "20px 20px 10px",
                                        marginBottom: "20px",
                                        backgroundColor:
                                            theme.palette.secondary.main,
                                    }}
                                >
                                    <KillTree
                                        killTree={killTree}
                                        remainingPlayers={remainingPlayers}
                                        focusPlayer={playerName}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                )}
        </Box>
    );
};

export default MatchDetails;
