import React, { createContext, useContext, useState } from "react";

interface FocusContextProps {
    isSearchBoxFocused: boolean;
    setIsSearchBoxFocused: (focused: boolean) => void;
}

const FocusContext = createContext<FocusContextProps | undefined>(undefined);

export const FocusProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [isSearchBoxFocused, setIsSearchBoxFocused] = useState(false);

    return (
        <FocusContext.Provider
            value={{ isSearchBoxFocused, setIsSearchBoxFocused }}
        >
            {children}
        </FocusContext.Provider>
    );
};

export const useFocusContext = () => {
    const context = useContext(FocusContext);
    if (!context) {
        throw new Error("useFocusContext must be used within a FocusProvider");
    }
    return context;
};
