export const hexToRgb = (hex: string): string => {
    // Validate the input hex color
    if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        throw new Error("Invalid hex color format. Use #RRGGBB or #RGB.");
    }

    // Remove '#' if present
    const sanitizedHex = hex.replace("#", "");

    // Convert shorthand hex (e.g., #F00) to full length (e.g., #FF0000)
    const fullHex =
        sanitizedHex.length === 3
            ? sanitizedHex
                  .split("")
                  .map((char) => char + char)
                  .join("")
            : sanitizedHex;

    const bigint = parseInt(fullHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `${r}, ${g}, ${b}`;
};
