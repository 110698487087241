import React from "react";
import { Box, Typography } from "@mui/material";

interface PlayerTagProps {
    playerName: string;
    survivalTier: number;
    survivalLevel: number;
    clanInfo?: {
        clanLevel: number;
        clanTag: string;
    } | null;
}

const PlayerTag: React.FC<PlayerTagProps> = ({
    playerName,
    survivalTier,
    survivalLevel,
    clanInfo,
}) => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-flex", // Inline-flex ensures the tag adjusts to its content
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                userSelect: "none", // Prevent text selection
            }}
        >
            {/* Player Tag Container */}
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {/* Nameplate Background */}
                <img
                    src={require("../assets/nameplate/slateNameplate.png")}
                    alt="Nameplate Background"
                    style={{
                        height: "80px",
                        borderRadius: "8px",
                    }}
                />

                {/* PUBG Logo */}
                <Box
                    sx={{
                        position: "absolute",
                        top: clanInfo ? "30px" : "43px",
                        left: "23px",
                        transform: "translateY(-50%)",
                    }}
                >
                    <img
                        src={require("../assets/nameplate/pubgNameplate.png")}
                        alt="PUBG Logo"
                        style={{
                            width: "40px",
                            height: "40px",
                        }}
                    />
                </Box>

                {/* Player Info */}
                <Box
                    sx={{
                        position: "absolute",
                        left: "85px",
                        color: "white",
                    }}
                >
                    {/* Player Name */}
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {playerName}
                    </Typography>

                    {/* Tier Icon and Level */}
                    <Box
                        sx={{
                            display: "flex",
                            // alignItems: "center",
                        }}
                    >
                        <img
                            src={require(`../assets/survivalMastery/tier${survivalTier}.png`)}
                            alt={`Tier ${survivalTier}`}
                            style={{
                                width: "24px",
                                height: "24px",
                            }}
                        />
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: "bold",
                                position: "relative", // Ensure it moves independently
                                left: "8px", // Shift text to the right
                            }}
                        >
                            Lv.{survivalLevel}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Clan Level Nameplate */}
            {clanInfo && clanInfo.clanLevel >= 2 && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "0px",
                        left: "13px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {(() => {
                        const availableLevels = [2, 5, 7, 10, 12, 15, 17, 20];
                        const unlockedLevel = availableLevels
                            .filter((level) => level <= clanInfo.clanLevel)
                            .pop();

                        return unlockedLevel ? (
                            <Box
                                sx={{
                                    position: "relative",
                                    display: "inline-block",
                                }}
                            >
                                <img
                                    src={require(`../assets/clanTags/lvl${unlockedLevel}clan.png`)}
                                    alt={`Clan Level ${unlockedLevel}`}
                                    style={{
                                        width: "60px",
                                        height: "30px",
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        position: "absolute",
                                        top: "43%",
                                        left: "49%",
                                        transform: "translate(-50%, -50%)",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {clanInfo.clanTag}
                                </Typography>
                            </Box>
                        ) : null;
                    })()}
                </Box>
            )}
        </Box>
    );
};

export default PlayerTag;
