import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";

import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";
import { weapons } from "constants/tierListWeapons";
import SectionHeader from "components/MatchDetails/SectionHeader";

interface SpecialWeaponKill {
    killer: string;
    victim: string;
    distance: number;
    weapon: string;
}

interface SpecialWeaponKillsTableProps {
    specialWeaponKills: SpecialWeaponKill[];
    platform: string;
}

const specialWeaponsMap: Record<string, string> = {
    PanzerFaust100M_Projectile_C: "Panzerfaust",
    WeapCrossbow_1_C: "Crossbow",
    ProjC4_C: "C4",
    Mortar_Projectile_C: "Mortar",
};

const getWeaponImage = (damageCauserName: string): string | undefined => {
    // Check if the weapon exists in the predefined special weapons map
    const normalizedWeaponName = specialWeaponsMap[damageCauserName];

    // Match against the weapons array
    const weapon = weapons.find((w) =>
        w.title.toLowerCase().includes(normalizedWeaponName.toLowerCase())
    );

    return weapon?.cardImage || undefined;
};

const SpecialWeaponKillsTable: React.FC<SpecialWeaponKillsTableProps> = ({
    specialWeaponKills,
    platform,
}) => {
    const theme = useTheme();

    return (
        <Box>
            <SectionHeader
                sectionType="specialKills"
                itemCount={specialWeaponKills.length}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Killer
                            </TableCell>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Victim
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Distance (m)
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Weapon
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {specialWeaponKills.map((kill, index) => (
                            <TableRow key={index}>
                                {/* Killer Name */}
                                <TableCell>
                                    <Typography
                                        component="a"
                                        href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                            platform,
                                            kill.killer
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            textDecoration: "none",
                                            color: "primary.main",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                    >
                                        <strong>{kill.killer}</strong>
                                    </Typography>
                                </TableCell>

                                {/* Victim Name */}
                                <TableCell>
                                    <Typography
                                        component="a"
                                        href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                            platform,
                                            kill.victim
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            textDecoration: "none",
                                            color: "error.main",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                    >
                                        <strong>{kill.victim}</strong>
                                    </Typography>
                                </TableCell>

                                {/* Kill Distance */}
                                <TableCell align="center">
                                    {Math.round(kill.distance / 100)}m
                                </TableCell>

                                {/* Weapon Used */}
                                <TableCell align="center">
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: 1,
                                        }}
                                    >
                                        <img
                                            src={
                                                getWeaponImage(kill.weapon) ||
                                                "https://example.com/placeholder.png"
                                            }
                                            alt={kill.weapon}
                                            style={{
                                                width: "48px",
                                                height: "48px",
                                                objectFit: "contain",
                                            }}
                                        />

                                        <Box
                                            sx={{
                                                backgroundColor:
                                                    "rgba(0,0,0,0.1)", // Subtle background for readability
                                                padding: "4px 8px",
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    fontSize: "0.9rem",
                                                    color: "primary.main",
                                                }}
                                            >
                                                {specialWeaponsMap[kill.weapon]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SpecialWeaponKillsTable;
