export const NAVIGATION_ROUTES = {
    PLAYER_PROFILE: (platform: string, playerName: string) =>
        `/player-stats/${platform}/${playerName}/profile`,
    RECENT_MATCHES: (platform: string, playerName: string) =>
        `/player-stats/${platform}/${playerName}/matches`,
    MATCH_DETAILS: (platform: string, playerName: string, matchId: string) =>
        `/player-stats/${platform}/${playerName}/matches/${matchId}`,
    FOUR_K_MAP: (mapName: string) => `/maps/4k-maps/${mapName}`,
    TDM_MAP: (mapName: string) => `/maps/tdm/${mapName}`,
    LIFETIME: (platform: string, playerName: string) =>
        `/player-stats/${platform}/${playerName}/lifetime`,
    ADD_COMPARE: (player2Name: string) => `/compare/${player2Name}`,
    TIER_LIST: (id: string) => `/tier-lists/maker/${id}`,
};
