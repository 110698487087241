import React from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useTheme,
} from "@mui/material";
import { getScrollbarStyles } from "styles/styles";
import SectionHeader from "components/MatchDetails/SectionHeader";

interface Bot {
    name: string;
    accountId: string;
    ranking: number;
}

interface BotListTableProps {
    botList: Bot[];
}

const BotListTable: React.FC<BotListTableProps> = ({ botList }) => {
    const theme = useTheme();

    return (
        <Box>
            {/* Title Section */}
            <SectionHeader sectionType="botList" itemCount={botList.length} />

            {/* Bot List Table */}
            {botList.length > 0 ? (
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 500,
                        overflowY: "auto",
                        borderRadius: "8px",
                        border: "1px solid #444",
                        [theme.breakpoints.up("md")]: getScrollbarStyles(),
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.background.paper,
                                        fontWeight: "bold",
                                        color: "#fff",
                                    }}
                                >
                                    Bot Name
                                </TableCell>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.background.paper,
                                        fontWeight: "bold",
                                        color: "#fff",
                                    }}
                                >
                                    Ranking
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {botList.map((bot) => (
                                <TableRow
                                    key={bot.accountId}
                                    sx={{
                                        "&:nth-of-type(odd)": {
                                            backgroundColor:
                                                "rgba(255, 255, 255, 0.05)",
                                        },
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(255, 255, 255, 0.1)",
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{ fontWeight: 500, color: "#ddd" }}
                                    >
                                        {bot.name}
                                    </TableCell>
                                    <TableCell sx={{ color: "#aaa" }}>
                                        {bot.ranking}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 200,
                        border: "1px dashed #666",
                        borderRadius: "8px",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{ color: "#aaa", marginBottom: 1 }}
                    >
                        No Bots Found
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#888" }}>
                        There were no bots in this match.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default BotListTable;
