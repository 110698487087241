import erangelThumbnail from "assets/maps/thumbnails/Erangel_Main_Low_Res.png";
import taegoThumbnail from "assets/maps/thumbnails/taego_secret_room_thumbnail.png";
import basementThumbnail from "assets/maps/thumbnails/basement_thumbnail.png";
import ibrThumbnail from "assets/maps/thumbnails/ibr_thumbnail.png";
import destonThumbnail from "assets/maps/thumbnails/deston_thumb.png";
import tdmThumbnail from "assets/maps/thumbnails/tdmThumbnail.png";
import vikendiSecretThumbnail from "assets/vikendiSecretThumbnail.jpg";

export const categories = [
    {
        name: "4K-Resolution Maps",
        image: erangelThumbnail,
        route: "/maps/4k-maps",
    },
    {
        name: "Erangel Secret Rooms",
        image: basementThumbnail,
        route: "/maps/erangel-secret-rooms",
    },
    {
        name: "Vikendi Secret Rooms",
        image: vikendiSecretThumbnail,
        route: "/maps/vikendi-secret-rooms",
    },
    {
        name: "Taego Secret Rooms",
        image: taegoThumbnail,
        route: "/maps/taego-secret-rooms",
    },
    {
        name: "Deston Secret Rooms",
        image: destonThumbnail,
        route: "/maps/deston-secret-rooms",
    },

    {
        name: "IBR Crates Maps",
        image: ibrThumbnail,
        route: "/maps/ibr-crates",
    },
    {
        name: "TDM Maps",
        image: tdmThumbnail,
        route: "/maps/tdm",
    },
];
