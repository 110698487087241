import React from "react";
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Divider,
} from "@mui/material";
import { COLORS } from "constants/appWide/colors";

// Platform logos
import steamLogo from "../assets/platformLogos/steam.png";
import psnLogo from "../assets/platformLogos/psn.png";
import xboxLogo from "../assets/platformLogos/xbox.png";
import FunnyLoading from "./FunnyLoading";

// Platform logos
const platformLogos: { [key: string]: string } = {
    steam: steamLogo,
    psn: psnLogo,
    xbox: xboxLogo,
};

interface SavedPlayersDropdownProps {
    isAuthenticated: boolean;
    savedPlayers: {
        myAccounts: { playerName: string; platform: string }[];
        myFriends: { playerName: string; platform: string }[];
        following: { playerName: string; platform: string }[];
    };
    loading: boolean;
    onSelect: (playerName: string, platform: string) => void;
    currentSelection?: { playerName: string; platform: string } | null;
    platform?: string;
}

export const SavedPlayersDropdown: React.FC<SavedPlayersDropdownProps> = ({
    isAuthenticated,
    savedPlayers,
    loading,
    onSelect,
    currentSelection,
    platform,
}) => {
    const selectedValue =
        currentSelection &&
        `${currentSelection.playerName} (${currentSelection.platform})`;

    return (
        <Box sx={{ maxWidth: "240px", margin: "0 auto" }}>
            <FormControl
                fullWidth
                sx={{
                    backgroundColor: "#2A2A2A",
                    "& .MuiInputLabel-root": {
                        color: COLORS.orange,
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                        color: COLORS.orange,
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: COLORS.orange,
                        },
                        "&:hover fieldset": {
                            borderColor: COLORS.orange,
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: COLORS.orange,
                        },
                    },
                }}
            >
                <InputLabel id="Saved Players">Saved Players</InputLabel>
                <Select
                    label="Saved Players"
                    defaultValue=""
                    value={selectedValue || ""}
                >
                    {!isAuthenticated && (
                        <MenuItem
                            disabled
                            sx={{
                                "&.Mui-disabled": {
                                    opacity: 1,
                                },
                                color: COLORS.orange,
                            }}
                        >
                            <Typography
                                variant="body2"
                                display="flex"
                                alignItems="center"
                                sx={{ color: COLORS.orange }}
                            >
                                Log in to use this feature.
                            </Typography>
                        </MenuItem>
                    )}
                    {(
                        Object.keys(savedPlayers) as Array<
                            keyof typeof savedPlayers
                        >
                    ).flatMap((category, index, array) => {
                        // Filter players based on the platform prop
                        const filteredPlayers = savedPlayers[category].filter(
                            (player) =>
                                !platform || player.platform === platform
                        );

                        return [
                            // Category header
                            <MenuItem
                                key={`header-${category}`}
                                disabled
                                sx={{
                                    "&.Mui-disabled": {
                                        opacity: 1,
                                    },
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: COLORS.orange }}
                                >
                                    {category
                                        .replace("myAccounts", "My Accounts")
                                        .replace("myFriends", "My Friends")
                                        .replace("following", "Following")}
                                </Typography>
                            </MenuItem>,
                            // Players in the Category
                            filteredPlayers.length > 0
                                ? filteredPlayers.map((player, playerIndex) => (
                                      <MenuItem
                                          key={`player-${category}-${playerIndex}`}
                                          value={`${player.playerName} (${player.platform})`}
                                          onClick={() =>
                                              onSelect(
                                                  player.playerName,
                                                  player.platform
                                              )
                                          }
                                      >
                                          <img
                                              src={
                                                  platformLogos[player.platform]
                                              }
                                              alt={`${
                                                  platformLogos[player.platform]
                                              } logo`}
                                              style={{
                                                  width: "20px",
                                                  height: "20px",
                                                  marginRight: "8px",
                                                  objectFit: "contain",
                                                  verticalAlign: "middle",
                                              }}
                                          />
                                          {player.playerName}
                                      </MenuItem>
                                  ))
                                : [
                                      <MenuItem
                                          key={`no-players-${category}`}
                                          disabled
                                      >
                                          <Typography
                                              variant="body2"
                                              display="flex"
                                              alignItems="center"
                                          >
                                              No{" "}
                                              {platform && (
                                                  <img
                                                      src={
                                                          platformLogos[
                                                              platform
                                                          ]
                                                      }
                                                      alt={`${platform} logo`}
                                                      style={{
                                                          width: "16px", // Adjust the size of the logo
                                                          height: "16px",
                                                          margin: "0 4px", // Add spacing around the logo
                                                          verticalAlign:
                                                              "middle", // Ensure vertical alignment
                                                      }}
                                                  />
                                              )}{" "}
                                              players in this category.
                                          </Typography>
                                      </MenuItem>,
                                  ],
                            // Divider after each category except the last
                            index < array.length - 1 && (
                                <Divider key={`divider-${category}`} />
                            ),
                        ];
                    })}
                </Select>
            </FormControl>
            {loading && <FunnyLoading />}
        </Box>
    );
};
