import React from "react";
import {
    Box,
    Typography,
    IconButton,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import GavelIcon from "@mui/icons-material/Gavel";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
import LoopIcon from "@mui/icons-material/Loop";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HealingIcon from "@mui/icons-material/Healing";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useScrollToTop } from "components/ScrollToTop";

interface SectionHeaderProps {
    sectionType: string;
    itemCount?: number;
    isJumpToSection?: boolean;
    onClick?: () => void; // Used in JumpToSection for scrolling
    isTdm?: boolean;
}

export const sectionConfig: Record<
    string,
    {
        title: string;
        icon: React.ReactNode;
        itemLabel?: string;
        description: string;
    }
> = {
    winningTeam: {
        title: "Winning Team Overview",
        icon: <EmojiEventsIcon fontSize="medium" sx={{ color: "gold" }} />,
        itemLabel: "player",
        description: "Overview of the team that won the match.",
    },
    teamsLeaderboard: {
        title: "Teams Leaderboard",
        icon: <GroupsIcon fontSize="medium" sx={{ color: "primary.main" }} />,
        itemLabel: "team",
        description: "Ranking of all teams in the match.",
    },
    playersLeaderboard: {
        title: "Players Leaderboard",
        icon: (
            <PersonIcon
                fontSize="medium"
                sx={{ color: "custom.accentColor2" }}
            />
        ),
        itemLabel: "player",
        description: "Ranking of all players in the match.",
    },
    specialKills: {
        title: "Panzer / Crossbow / Mortar / C4 Kills",
        icon: <GavelIcon fontSize="medium" sx={{ color: "primary.main" }} />,
        itemLabel: "kill",
        description: "Kills using unique weapons.",
    },
    carePackages: {
        title: "Care Packages",
        icon: <ParaglidingIcon fontSize="medium" sx={{ color: "red" }} />,
        itemLabel: "drop",
        description: "Details on care package drops.",
    },
    recalledPlayers: {
        title: "Recalled Players",
        icon: <LoopIcon fontSize="medium" sx={{ color: "primary.main" }} />,
        itemLabel: "player",
        description: "List of players that were revived.",
    },
    emergencyPickup: {
        title: "Emergency Pickup Uses",
        icon: (
            <FlightTakeoffIcon
                fontSize="medium"
                sx={{ color: "primary.main" }}
            />
        ),
        itemLabel: "player",
        description: "How many emergency pickups were used.",
    },
    selfRevived: {
        title: "Self-Revived Players",
        icon: <HealingIcon fontSize="medium" sx={{ color: "primary.main" }} />,
        itemLabel: "player",
        description: "Players who self-revived.",
    },
    botList: {
        title: "Bot List",
        icon: (
            <SmartToyIcon fontSize="medium" sx={{ color: "secondary.main" }} />
        ),
        itemLabel: "bot",
        description: "List of bots that appeared in the match.",
    },
    killTree: {
        title: "Kill Tree",
        icon: (
            <DeviceHubIcon fontSize="medium" sx={{ color: "primary.main" }} />
        ),
        description: "Visualization of kills in the match.",
    },
};

// Define allowed sections for TDM mode
const tdmAllowedSections = [
    "winningTeam",
    "teamsLeaderboard",
    "playersLeaderboard",
];

// Extract ordered section keys from `sectionConfig`
const orderedSectionKeys = Object.keys(sectionConfig);

const SectionHeader: React.FC<SectionHeaderProps> = ({
    sectionType,
    itemCount,
    isJumpToSection = false,
    onClick,
    isTdm = false,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { scrollToJumpToSection } = useScrollToTop();

    const { title, icon, itemLabel, description } =
        sectionConfig[sectionType] || {};
    const sectionIndex = orderedSectionKeys.indexOf(sectionType);

    // Hide sections that are not allowed in TDM mode
    if (isTdm && !tdmAllowedSections.includes(sectionType)) {
        return null;
    }

    return (
        <Box
            onClick={onClick} // Makes the section header clickable in JumpToSection
            sx={{
                display: "flex",
                flexDirection: isMobile && isJumpToSection ? "column" : "row", // Stack on mobile
                alignItems: "flex-start",
                width: "100%",
                justifyContent:
                    isMobile && isJumpToSection
                        ? "flex-start"
                        : "space-between",
                mb: 2,
                backgroundColor: theme.palette.background.paper,
                padding: 2,
                borderRadius: 2,
                boxShadow: 1,
                cursor: onClick ? "pointer" : "default",
                "&:hover": onClick
                    ? { backgroundColor: "rgba(0,0,0,0.1)" }
                    : {},
            }}
        >
            {/* Left Side: Title with Icon */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {icon}
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {isJumpToSection ? `${sectionIndex + 1}. ${title}` : title}
                </Typography>
            </Box>

            {/* Right Side: Count or Description */}
            {isJumpToSection && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: { md: "center" },
                    }}
                >
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            mt: isMobile ? 1 : 0, // Adds spacing only on mobile
                            textAlign: isMobile ? "left" : "right", // Aligns left on mobile, right on desktop
                            width: isMobile ? "100%" : "auto",
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
            )}
            {!isJumpToSection && sectionType === "killTree" && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "center",
                    }}
                >
                    {/* Special case for Kill Tree */}

                    <Typography
                        variant="body2"
                        sx={{
                            color: "text.secondary",
                            fontStyle: "italic",
                        }}
                    >
                        Tree may look odd when recalled players get kills.
                    </Typography>
                    <IconButton onClick={scrollToJumpToSection} size="small">
                        <ArrowUpwardIcon />
                    </IconButton>
                </Box>
            )}
            {!isJumpToSection && sectionType !== "killTree" && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "center",
                    }}
                >
                    {itemCount !== undefined && itemLabel && (
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: "bold",
                                color: "text.secondary",
                            }}
                        >
                            {itemCount}{" "}
                            {itemCount === 1 ? itemLabel : `${itemLabel}s`}
                        </Typography>
                    )}
                    <IconButton onClick={scrollToJumpToSection} size="small">
                        <ArrowUpwardIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default SectionHeader;
