// React
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// UI
import { Box, Button, Typography, Link as MuiLink } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

// Component
import { MapSection } from "../../components/MapSection";

// Map Constants
import { esportsMaps } from "../../constants/4kMaps/esportsMaps";
import { nonEsportsMaps } from "../../constants/4kMaps/nonEsportsMaps";
import { smallMaps } from "../../constants/4kMaps/smallMaps";
import { trainingMap } from "../../constants/4kMaps/trainingMap";
import { retiredMap } from "../../constants/4kMaps/retiredMap";
import { maxWidths } from "constants/appWide/maxWidths";
import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";

export const FourKMaps: React.FC = () => {
    const { mapName } = useParams<{ mapName?: string }>();
    const [selectedMap, setSelectedMap] = useState<{
        name: string;
        url: string;
        size: number;
    } | null>(null);

    const [points, setPoints] = useState<{ x: number; y: number }[]>([]);
    const [distance, setDistance] = useState<number | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const allMaps = [
            ...esportsMaps,
            ...nonEsportsMaps,
            ...smallMaps,
            ...trainingMap,
            ...retiredMap,
        ];
        if (mapName) {
            const foundMap = allMaps.find(
                (map) => map.name.toLowerCase() === mapName.toLowerCase()
            );
            if (foundMap) {
                foundMap.url = foundMap.url
                    .replace(
                        "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/",
                        "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/"
                    )
                    .replace("Low_Res", "High_Res");
            }
            setSelectedMap(foundMap || null);
        } else {
            setSelectedMap(null);
        }
    }, [mapName]);

    useEffect(() => {
        setPoints([]);
        setDistance(null);
    }, [selectedMap]);

    const handleMapClick = (e: React.MouseEvent<HTMLImageElement>) => {
        if (!selectedMap) return;

        const mapElement = e.currentTarget;
        const rect = mapElement.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * selectedMap.size;
        const y = ((e.clientY - rect.top) / rect.height) * selectedMap.size;

        setPoints((prev) => {
            const updatedPoints = [...prev, { x, y }];

            if (prev.length > 0) {
                const lastPoint = prev[prev.length - 1];
                const dx = x - lastPoint.x;
                const dy = y - lastPoint.y;
                const pixelDistance = Math.sqrt(dx * dx + dy * dy);
                const calculatedDistance =
                    (pixelDistance / selectedMap.size) * selectedMap.size;
                setDistance(calculatedDistance);
            }

            return updatedPoints.slice(-2);
        });
    };

    const resetSelection = () => {
        setPoints([]);
        setDistance(null);
    };

    const handleBack = () => {
        navigate("/maps/4k-maps");
    };

    const dynamicMessage = () => {
        if (points.length === 0)
            return "Select 2 points to calculate the distance.";
        if (points.length === 1)
            return "1 point selected. Select 1 more point.";
        if (distance !== null) {
            // Convert distance in kilometers to meters and kilometers
            const distanceInMeters = distance * 1000; // Assuming distance is in km
            return `Distance: ${distanceInMeters.toFixed(
                0
            )} meters (${distance.toFixed(3)} km)`;
        }
        return "";
    };

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l,
                margin: "0 auto", // Centers the box
                height: {
                    md: selectedMap ? "100vh" : "auto", // Apply "100vh" if selectedMap exists, otherwise "auto"
                },
                padding: 2,
            }}
        >
            <AnimatePresence>
                {!selectedMap ? (
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Typography variant="h5" gutterBottom>
                            4K-Resolution Maps
                        </Typography>
                        <Typography>
                            Images can be found on the official GitHub
                            repository:{" "}
                            <MuiLink
                                href="https://github.com/pubg/api-assets/tree/master/Assets/Maps"
                                target="_blank"
                                rel="noopener"
                            >
                                PUBG API Assets
                            </MuiLink>
                        </Typography>
                        <MapSection
                            title="Esports Maps (8x8 km)"
                            maps={esportsMaps}
                            onMapSelect={(map) =>
                                navigate(NAVIGATION_ROUTES.FOUR_K_MAP(map.name))
                            }
                        />
                        <MapSection
                            title="Non-Esports 8x8 km Maps"
                            maps={nonEsportsMaps}
                            onMapSelect={(map) =>
                                navigate(NAVIGATION_ROUTES.FOUR_K_MAP(map.name))
                            }
                        />
                        <MapSection
                            title="Non-Esports Small Maps"
                            maps={smallMaps}
                            onMapSelect={(map) =>
                                navigate(NAVIGATION_ROUTES.FOUR_K_MAP(map.name))
                            }
                        />
                        <MapSection
                            title="Training"
                            maps={trainingMap}
                            onMapSelect={(map) =>
                                navigate(NAVIGATION_ROUTES.FOUR_K_MAP(map.name))
                            }
                        />
                        <MapSection
                            title="Retired Map"
                            maps={retiredMap}
                            onMapSelect={(map) =>
                                navigate(NAVIGATION_ROUTES.FOUR_K_MAP(map.name))
                            }
                        />
                    </motion.div>
                ) : (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Typography variant="h6" align="center" gutterBottom>
                            {selectedMap.name} - {selectedMap.size}x
                            {selectedMap.size} km
                        </Typography>
                        <Typography
                            variant="body1"
                            align="center"
                            sx={{ marginBottom: "16px" }}
                        >
                            {dynamicMessage()}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px",
                                marginBottom: "16px",
                            }}
                        >
                            <Button
                                onClick={resetSelection}
                                variant="contained"
                                color="primary"
                            >
                                Reset
                            </Button>
                            <Button
                                onClick={handleBack}
                                variant="contained"
                                color="secondary"
                            >
                                Back to 4K Maps
                            </Button>
                        </Box>
                        <Box
                            component="div"
                            sx={{
                                position: "relative",
                                display: "inline-block",
                                margin: "0 auto",
                            }}
                        >
                            <Box
                                component="img"
                                src={selectedMap.url}
                                alt={selectedMap.name}
                                onClick={handleMapClick}
                                sx={{
                                    maxWidth: "100%",
                                    maxHeight: "80vh",
                                    cursor: "crosshair",
                                    borderRadius: "8px",
                                }}
                            />
                            {points.length === 2 && (
                                <svg
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        pointerEvents: "none",
                                    }}
                                >
                                    <line
                                        x1={
                                            (points[0].x / selectedMap.size) *
                                                100 +
                                            "%"
                                        }
                                        y1={
                                            (points[0].y / selectedMap.size) *
                                                100 +
                                            "%"
                                        }
                                        x2={
                                            (points[1].x / selectedMap.size) *
                                                100 +
                                            "%"
                                        }
                                        y2={
                                            (points[1].y / selectedMap.size) *
                                                100 +
                                            "%"
                                        }
                                        stroke="lime"
                                        strokeWidth="6"
                                    />
                                </svg>
                            )}
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>
        </Box>
    );
};
