export interface WeeklyRotation {
    name?: string;
    maps: Record<string, number>;
}

export const weeklyRotations: WeeklyRotation[] = [
    {
        maps: {
            Erangel: 15,
            Rondo: 15,
            Vikendi: 15,
            Deston: 15,
            Sanhok: 40,
        },
    },
    {
        maps: {
            Erangel: 15,
            Rondo: 15,
            Vikendi: 15,
            Deston: 15,
            Sanhok: 40,
        },
    },
    {
        maps: {
            Erangel: 20,
            Miramar: 20,
            Taego: 20,
            Deston: 20,
            Sanhok: 20,
        },
    },
    {
        maps: {
            Erangel: 22,
            Sanhok: 22,
            Taego: 22,
            Rondo: 22,
            Karakin: 11,
        },
    },
    {
        name: "Patch 33.2 Week 4",
        maps: {
            Erangel: 20,
            Rondo: 20,
            Vikendi: 20,
            Deston: 20,
            Sanhok: 20,
        },
    },
    // Patch 34.1 Weeks 1-4
    {
        name: "Patch 34.1 Week 1",
        maps: {
            Erangel: 22,
            Rondo: 22,
            Vikendi: 22,
            Miramar: 22,
            Sanhok: 11,
        },
    },
    {
        name: "Patch 34.1 Week 2",
        maps: {
            Erangel: 22,
            Vikendi: 22,
            Miramar: 22,
            Taego: 22,
            Deston: 11,
        },
    },
    {
        name: "Patch 34.1 Week 3",
        maps: {
            Erangel: 22,
            Miramar: 22,
            Taego: 22,
            Rondo: 22,
            Paramo: 11,
        },
    },
    {
        name: "Patch 34.1 Week 4",
        maps: {
            Erangel: 22,
            Taego: 22,
            Rondo: 22,
            Vikendi: 22,
            Deston: 11,
        },
    },
    // Patch 34.2
];

export const rankedRotation = {
    name: "Since 34.1",
    maps: {
        Erangel: 25,
        Miramar: 25,
        Taego: 20,
        Rondo: 20,
        Vikendi: 10,
    },
};
