import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
} from "date-fns";

export const getElapsedTime = (createdAt: string, duration: number): string => {
    const endTime = new Date(new Date(createdAt).getTime() + duration * 1000);
    const now = new Date();

    const days = differenceInDays(now, endTime);
    if (days === 1) return "1 day ago";
    if (days > 1) return `${days} days ago`;

    const hours = differenceInHours(now, endTime);
    if (hours === 1) return "1 hr ago";
    if (hours > 1) return `${hours} hrs ago`;

    const minutes = differenceInMinutes(now, endTime);
    if (minutes === 1) return "1 min ago";
    if (minutes > 1) return `${minutes} mins ago`;

    return "Just now";
};
