import React, { useState } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Typography,
    Tooltip,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useTheme } from "@mui/material/styles";
import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";

// Icons
import trophy from "assets/matchCardIcons/trophy.png";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SportsMmaIcon from "@mui/icons-material/SportsMma";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SectionHeader from "components/MatchDetails/SectionHeader";

interface TdmPlayer {
    accountId: string;
    name: string;
    kills: number;
    deaths: number;
    killScore: number;
    damageDealt: number;
    damageTaken: number;
    joinedLate: boolean;
    leftEarly: boolean;
    teamNumber: number;
}

interface TdmPlayerLeaderboardProps {
    tdmStats: {
        team1Roster: TdmPlayer[];
        team2Roster: TdmPlayer[];
    };
    playerName: string;
    platform: string;
    winningTeam: number;
}

const TdmPlayerLeaderboard: React.FC<TdmPlayerLeaderboardProps> = ({
    tdmStats,
    playerName,
    platform,
    winningTeam,
}) => {
    const theme = useTheme();

    const [sortConfig, setSortConfig] = useState<{
        key: keyof TdmPlayer | "kdRatio";
        direction: "asc" | "desc";
    }>({
        key: "killScore",
        direction: "desc",
    });

    const allPlayers: TdmPlayer[] = [
        ...tdmStats.team1Roster.map((p) => ({ ...p, teamNumber: 1 })),
        ...tdmStats.team2Roster.map((p) => ({ ...p, teamNumber: 2 })),
    ];

    // Find Highest Kills and Highest Damage
    const maxKills = Math.max(...allPlayers.map((p) => p.kills), 0);
    const maxDamage = Math.max(...allPlayers.map((p) => p.damageDealt), 0);

    const sortedPlayers = [...allPlayers].sort((a, b) => {
        const key = sortConfig.key;
        const order = sortConfig.direction === "asc" ? 1 : -1;

        if (key === "kdRatio") {
            const aKd = a.deaths > 0 ? a.kills / a.deaths : a.kills;
            const bKd = b.deaths > 0 ? b.kills / b.deaths : b.kills;
            return (aKd - bKd) * order;
        }

        if (
            key in a &&
            key in b &&
            typeof a[key as keyof TdmPlayer] === "number" &&
            typeof b[key as keyof TdmPlayer] === "number"
        ) {
            return (
                ((a[key as keyof TdmPlayer] as number) -
                    (b[key as keyof TdmPlayer] as number)) *
                order
            );
        }

        return 0;
    });

    const handleSort = (key: keyof TdmPlayer | "kdRatio") => {
        setSortConfig((prev) => ({
            key,
            direction:
                prev.key === key && prev.direction === "asc" ? "desc" : "asc",
        }));
    };

    const getKDRatio = (kills: number, deaths: number) =>
        deaths > 0 ? (kills / deaths).toFixed(2) : kills.toFixed(2);

    return (
        <Box sx={{ width: "100%", mt: 0 }}>
            <SectionHeader
                sectionType="playersLeaderboard"
                itemCount={allPlayers.length}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "kills"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("kills")}
                                >
                                    Kills
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "deaths"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("deaths")}
                                >
                                    Deaths
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "kdRatio"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("kdRatio")}
                                >
                                    K/D
                                </TableSortLabel>
                            </TableCell>

                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "damageDealt"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("damageDealt")}
                                >
                                    Damage Dealt
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "damageTaken"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("damageTaken")}
                                >
                                    Damage Taken
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortConfig.key === "killScore"}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort("killScore")}
                                >
                                    Score
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedPlayers.map((player, index) => (
                            <TableRow key={player.accountId}>
                                {/* Player Name */}
                                <TableCell
                                    sx={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                    }}
                                >
                                    {/* Row Number */}
                                    <Typography
                                        sx={{
                                            position: "absolute",
                                            top: "0px",
                                            left: "4px",
                                            fontSize: "12px",
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        {index + 1}
                                    </Typography>
                                    {/* Win/Loss Icon */}
                                    {player.teamNumber === winningTeam ? (
                                        <Tooltip title="Winner">
                                            <img
                                                src={trophy}
                                                alt="Trophy Icon"
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Lost">
                                            <SentimentDissatisfiedIcon
                                                sx={{
                                                    color: "red",
                                                    fontSize: 24,
                                                }}
                                            />
                                        </Tooltip>
                                    )}

                                    {/* Joined Late / Left Early Icons */}
                                    {player.joinedLate && (
                                        <Tooltip title="Joined Late">
                                            <AccessTimeIcon
                                                fontSize="small"
                                                style={{
                                                    color: theme.palette.warning
                                                        .main,
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                    {player.leftEarly && (
                                        <Tooltip title="Left Early">
                                            <ExitToAppIcon
                                                fontSize="small"
                                                style={{
                                                    color: theme.palette.error
                                                        .main,
                                                }}
                                            />
                                        </Tooltip>
                                    )}

                                    {/* Highest Kills Icon */}
                                    {player.kills === maxKills &&
                                        player.kills > 0 && (
                                            <Tooltip title="Most Kills">
                                                <SportsMmaIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "red",
                                                    }}
                                                />
                                            </Tooltip>
                                        )}

                                    {/* Highest Damage Icon */}
                                    {player.damageDealt === maxDamage &&
                                        player.damageDealt > 0 && (
                                            <Tooltip title="Highest Damage">
                                                <LocalFireDepartmentIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: "orange",
                                                    }}
                                                />
                                            </Tooltip>
                                        )}

                                    {player.name === playerName && (
                                        <Tooltip title="Me">
                                            <ArrowForwardIcon
                                                sx={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                            />
                                        </Tooltip>
                                    )}

                                    {/* Clickable Player Name */}
                                    <Typography
                                        component="a"
                                        href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                            platform,
                                            player.name
                                        )}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            textDecoration: "none",
                                            color: theme.palette.text.primary,
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                    >
                                        {player.name}
                                    </Typography>
                                </TableCell>

                                {/* Player Stats */}
                                <TableCell align="center">
                                    {player.kills}
                                </TableCell>
                                <TableCell align="center">
                                    {player.deaths}
                                </TableCell>
                                <TableCell align="center">
                                    {getKDRatio(player.kills, player.deaths)}
                                </TableCell>
                                <TableCell align="center">
                                    {player.damageDealt.toLocaleString()}
                                </TableCell>
                                <TableCell align="center">
                                    {player.damageTaken.toLocaleString()}
                                </TableCell>
                                <TableCell align="center">
                                    {player.killScore.toLocaleString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TdmPlayerLeaderboard;
