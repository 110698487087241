import { Match } from "types/match"; // Assuming Match is the match type

export const processTdmTelemetry = async (
    match: Match,
    accountId: string | null,
    playerName: string | null
): Promise<Match> => {
    try {
        const telemetryResponse = await fetch(match.telemetryUrl);

        if (!telemetryResponse.ok) {
            console.error(
                `Failed to fetch telemetry for match ${match.matchId}`
            );
            return match;
        }

        const telemetryData: any = await telemetryResponse.json();

        const accountIdToTeamId: Record<string, number> = {};
        const team1Roster: Record<string, any> = {};
        const team2Roster: Record<string, any> = {};
        const roundResults: {
            roundNumber: number;
            team1Kills: number;
            team2Kills: number;
        }[] = [];
        let team1Kills = 0;
        let team2Kills = 0;
        let roundNumber = 1;

        let totalTeam1Kills = 0;
        let totalTeam2Kills = 0;
        let totalTeam1Damage = 0;
        let totalTeam2Damage = 0;
        let totalTeam1RoundsWon = 0;
        let totalTeam2RoundsWon = 0;
        let team1Points = 0;
        let team2Points = 0;

        let playerTeamId = -1;

        // Initialize players from LogPlayerCreate
        telemetryData
            .filter((event: any) => event._T === "LogPlayerCreate")
            .forEach((event: any) => {
                const eventAccountId = event.character.accountId;
                const eventTeamId = event.character.teamId;
                const eventPlayerName = event.character.name;

                // Check if the current event belongs to the focused player
                if (
                    eventAccountId === accountId ||
                    eventPlayerName === playerName
                ) {
                    playerTeamId = eventTeamId;
                }

                accountIdToTeamId[eventAccountId] = eventTeamId;

                const playerData = {
                    accountId: eventAccountId,
                    name: eventPlayerName,
                    kills: 0,
                    deaths: 0,
                    killScore: 0,
                    hasDied: false,
                    damageDealt: 0,
                    damageTaken: 0,
                };

                if (eventTeamId === 1) {
                    team1Roster[eventAccountId] = playerData;
                } else if (eventTeamId === 2) {
                    team2Roster[eventAccountId] = playerData;
                }
            });

        // Process LogPlayerKillV2 events
        telemetryData
            .filter((event: any) => event._T === "LogPlayerKillV2")
            .forEach((event: any) => {
                const killerAccountId = event.killer?.accountId;
                const victimAccountId = event.victim?.accountId;
                const killerTeamId = event.killer?.teamId;

                // Increment kills and calculate killScore for the killer
                if (killerAccountId && killerTeamId) {
                    if (killerTeamId === 1) {
                        team1Kills++;
                        totalTeam1Kills++;
                        if (team1Roster[killerAccountId]) {
                            const killer = team1Roster[killerAccountId];
                            killer.kills++;
                            killer.killScore += killer.hasDied ? 100 : 110;
                            killer.hasDied = false;
                        }
                    } else if (killerTeamId === 2) {
                        team2Kills++;
                        totalTeam2Kills++;
                        if (team2Roster[killerAccountId]) {
                            const killer = team2Roster[killerAccountId];
                            killer.kills++;
                            killer.killScore += killer.hasDied ? 100 : 110;
                            killer.hasDied = false;
                        }
                    }
                }

                // Increment deaths for the victim
                if (victimAccountId) {
                    if (team1Roster[victimAccountId]) {
                        const victim = team1Roster[victimAccountId];
                        victim.deaths++;
                        victim.hasDied = true;
                    } else if (team2Roster[victimAccountId]) {
                        const victim = team2Roster[victimAccountId];
                        victim.deaths++;
                        victim.hasDied = true;
                    }
                }

                // Check if a round ends
                if (team1Kills === 50 || team2Kills === 50) {
                    roundResults.push({ roundNumber, team1Kills, team2Kills });

                    // Update round points
                    if (team1Kills > team2Kills) {
                        totalTeam1RoundsWon++;
                        team1Points += 400; // Round win
                        team2Points += 200; // Round loss
                    } else {
                        totalTeam2RoundsWon++;
                        team2Points += 400; // Round win
                        team1Points += 200; // Round loss
                    }

                    team1Kills = 0;
                    team2Kills = 0;
                    roundNumber++;
                }
            });

        // **Process LogPlayerTakeDamage events**
        telemetryData
            .filter((event: any) => event._T === "LogPlayerTakeDamage")
            .forEach((event: any) => {
                const attackerAccountId = event.attacker?.accountId;
                const victimAccountId = event.victim?.accountId;
                const damage = event.damage;

                // Add damage to the victim
                if (victimAccountId) {
                    if (team1Roster[victimAccountId]) {
                        team1Roster[victimAccountId].damageTaken += damage;
                    } else if (team2Roster[victimAccountId]) {
                        team2Roster[victimAccountId].damageTaken += damage;
                    }
                }

                // Add damage to the attacker
                if (attackerAccountId) {
                    if (team1Roster[attackerAccountId]) {
                        team1Roster[attackerAccountId].damageDealt += damage;
                        totalTeam1Damage += damage;
                    } else if (team2Roster[attackerAccountId]) {
                        team2Roster[attackerAccountId].damageDealt += damage;
                        totalTeam2Damage += damage;
                    }
                }
            });

        // **Determine Joined Late & Left Early**
        Object.values(team1Roster).forEach((player) => {
            const participant = match.participants.find(
                (p) => p.playerId === player.accountId
            );
            if (!participant) {
                player.leftEarly = true;
            } else if (!participant.name) {
                player.joinedLate = true;
            }
        });

        Object.values(team2Roster).forEach((player) => {
            const participant = match.participants.find(
                (p) => p.playerId === player.accountId
            );
            if (!participant) {
                player.leftEarly = true;
            } else if (!participant.name) {
                player.joinedLate = true;
            }
        });

        // Add match-level points
        if (totalTeam1RoundsWon > totalTeam2RoundsWon) {
            team1Points += 200; // Match win
            team2Points += 100; // Match loss
        } else {
            team2Points += 200; // Match win
            team1Points += 100; // Match loss
        }

        const winningTeamId = totalTeam1RoundsWon > totalTeam2RoundsWon ? 1 : 2;

        // Format rosters
        // Format rosters
        const formattedTeam1Roster = Object.values(team1Roster).map(
            (player) => ({
                ...player,
                damageDealt: Math.round(player.damageDealt), // Ensure no decimals
                damageTaken: Math.round(player.damageTaken), // Ensure no decimals
            })
        );
        const formattedTeam2Roster = Object.values(team2Roster).map(
            (player) => ({
                ...player,
                damageDealt: Math.round(player.damageDealt), // Ensure no decimals
                damageTaken: Math.round(player.damageTaken), // Ensure no decimals
            })
        );

        // Attach TDM stats to match
        return {
            ...match,
            tdmStats: {
                playerTeamId,
                winningTeamId,
                roundResults,
                roundsPlayed: roundResults.length,
                totalTeam1Kills,
                totalTeam2Kills,
                totalTeam1Damage,
                totalTeam2Damage,
                totalTeam1RoundsWon,
                totalTeam2RoundsWon,
                team1Points,
                team2Points,
                team1Roster: formattedTeam1Roster,
                team2Roster: formattedTeam2Roster,
            },
        };
    } catch (error) {
        console.error(
            `Error processing telemetry for match ${match.matchId}:`,
            error
        );
        return match;
    }
};
