import { Paper, Typography } from "@mui/material";
import React from "react";
import SectionHeader from "./SectionHeader";

interface JumpToSectionProps {
    sections: { id: string }[];
    scrollToSection: (id: string) => void;
    isTdm?: boolean;
}

const JumpToSection: React.FC<JumpToSectionProps> = ({
    sections,
    scrollToSection,
    isTdm = false,
}) => {
    return (
        <Paper
            elevation={3}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                p: 2,
                mt: 2,
                borderRadius: 2,
                backgroundColor: "rgba(0,0,0,0.05)",
                width: "100%",
            }}
        >
            {/* Title for the Jump to Section */}
            <Typography
                variant="h6"
                sx={{
                    fontWeight: "bold",
                    mb: 2,
                    textAlign: "left",
                    width: "100%",
                }}
            >
                Jump to Section
            </Typography>

            {sections.map((section) => (
                <SectionHeader
                    key={section.id}
                    sectionType={section.id} // Matches sectionConfig key
                    isJumpToSection={true}
                    onClick={() => scrollToSection(section.id)}
                    isTdm={isTdm}
                />
            ))}
        </Paper>
    );
};

export default JumpToSection;
