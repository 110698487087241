import { Participant, Roster } from "./rosterStats";

export const fixParticipantWinPlace = (
    rosters: Roster[],
    participants: Participant[]
): Participant[] => {
    // Map roster ID to its rank
    const rosterRankMap = new Map<string, number>();
    rosters.forEach((roster) => {
        rosterRankMap.set(roster.id, roster.rank);
    });

    // Update participants' winPlace based on their roster's rank
    return participants.map((participant) => {
        for (const roster of rosters) {
            if (roster.participants.includes(participant.participantId)) {
                return { ...participant, winPlace: roster.rank }; // ✅ Update winPlace
            }
        }
        return participant; // If no matching roster, return as is
    });
};
