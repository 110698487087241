import React, { createContext, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

interface ScrollContextType {
    scrollToJumpToSection: () => void;
    jumpToSectionRef: React.RefObject<HTMLDivElement>;
}

// Create Context
const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

// Context Provider Component
export const ScrollToTopProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const location = useLocation();

    // Reference for "Jump to Section"
    const jumpToSectionRef = useRef<HTMLDivElement>(null);

    // Function to scroll to "Jump to Section"
    const scrollToJumpToSection = () => {
        jumpToSectionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    // Scroll to top on mobile when changing pages
    useEffect(() => {
        if (isMobile) {
            window.scrollTo({ top: 0 });
        }
    }, [isMobile, location.pathname]);

    return (
        <ScrollContext.Provider
            value={{ scrollToJumpToSection, jumpToSectionRef }}
        >
            {children}
        </ScrollContext.Provider>
    );
};

// Custom hook for accessing the scroll function
export const useScrollToTop = () => {
    const context = useContext(ScrollContext);
    if (!context) {
        throw new Error(
            "useScrollToTop must be used within a ScrollToTopProvider"
        );
    }
    return context;
};
