import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Typography,
    CircularProgress,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery,
    useTheme,
} from "@mui/material";

export const C4Timer: React.FC = () => {
    const TOTAL_TIME = 16;
    const [timeLeft, setTimeLeft] = useState<number>(TOTAL_TIME);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [position, setPosition] = useState<"left" | "center" | "right">(
        "center"
    );
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Function to start/restart timer
    const startTimer = useCallback(() => {
        if (timerId) clearInterval(timerId); // Stop existing interval

        setTimeLeft(TOTAL_TIME);
        setIsRunning(true);

        const newTimerId = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(newTimerId);
                    setIsRunning(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        setTimerId(newTimerId);
    }, [timerId]);

    // Handle spacebar press to trigger/reset timer
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.code === "Space") {
                event.preventDefault();
                startTimer();
            }
        };

        window.addEventListener("keydown", handleKeyPress);
        return () => window.removeEventListener("keydown", handleKeyPress);
    }, [startTimer]);

    // Clicking anywhere on the page resets the timer (except navbar/footer)
    useEffect(() => {
        const handlePageClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            const isInsideNavOrFooter =
                target.closest("nav") || target.closest("footer");

            if (!isInsideNavOrFooter) {
                startTimer();
            }
        };

        document.addEventListener("click", handlePageClick);
        return () => document.removeEventListener("click", handlePageClick);
    }, [startTimer]);

    // Determine progress color
    const getProgressColor = () => {
        if (timeLeft > 8) return "green";
        if (timeLeft > 4) return "yellow";
        return "red";
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: isMobile
                    ? "center" // Always center on mobile
                    : position === "left"
                    ? "flex-start"
                    : position === "right"
                    ? "flex-end"
                    : "center", // Move left, center, or right on desktop
                alignItems: "center", // Keep it centered vertically
                height: { xs: "70vh", md: "80vh" },
                width: "100%",
                paddingX: position === "left" || position === "right" ? 1 : 0, // Padding for left/right
            }}
        >
            {/* This is the box that moves left/center/right as a unit */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Center everything inside
                    textAlign: "center",
                    maxWidth: 350, // Prevents it from stretching too much
                }}
            >
                {/* Position Buttons (Hidden on Mobile) */}
                {!isMobile && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 2,
                        }}
                    >
                        <ToggleButtonGroup
                            value={position}
                            exclusive
                            onChange={(_, newPosition) =>
                                newPosition && setPosition(newPosition)
                            }
                            size="small"
                        >
                            <ToggleButton value="left">Left</ToggleButton>
                            <ToggleButton value="center">Center</ToggleButton>
                            <ToggleButton value="right">Right</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                )}

                {/* Timer Display */}
                <Typography variant="h5" sx={{ mb: 2 }}>
                    C4 Timer
                </Typography>

                <Box position="relative" display="inline-flex">
                    <CircularProgress
                        variant="determinate"
                        value={(timeLeft / TOTAL_TIME) * 100}
                        size={350} // Adjusted size
                        thickness={17} // Adjusted thickness
                        sx={{
                            color: getProgressColor(),
                        }}
                    />
                    <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        sx={{
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <Typography variant="h3" fontWeight="bold">
                            {timeLeft}
                        </Typography>
                    </Box>
                </Box>

                <Typography variant="body1" sx={{ mt: 2 }}>
                    {isMobile
                        ? "Press anywhere to reset."
                        : "Click anywhere or press space to reset."}
                </Typography>
            </Box>
        </Box>
    );
};

export default C4Timer;
