import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Footer from "components/Footer";
import ReactGA from "react-ga4";
import { TouchBackend } from "react-dnd-touch-backend";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";

// Constants
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

// Auth Context
import { AuthProvider } from "./AuthContext";

// Turnstile
import TurnstileWrapper from "components/TurnstileWrapper";

// Theme
import { getDynamicTheme } from "themes/getDynamicTheme";

// Pages
import { Navbar } from "./components/navbar/Navbar";
import { Home } from "./pages/Home";
import { AccountSettings } from "./pages/usersOnly/AccountSettings";
import { Tech } from "./pages/Tech";
import { Register } from "./pages/usersOnly/Register";
import { Login } from "./pages/usersOnly/Login";
import { MapsLanding } from "./pages/maps/MapsLanding";
import { TipsStrategies } from "./pages/TipsStrategies";
import TierListMaker from "./pages/tierLists/TierListMaker";
import { PatchesPage } from "./pages/PatchesPage";
import { PublicTierLists } from "./pages/tierLists/PublicTierLists";
import ProtectedRoute from "./routers/ProtectedRoute";
import { TierListsLanding } from "./pages/tierLists/TierListsLanding";
import { ErangelSecretRooms } from "./pages/maps/ErangelSecretRooms";
import { TaegoSecretRooms } from "./pages/maps/TaegoSecretRooms";
import { DestonSecretRooms } from "./pages/maps/DestonSecretRooms";
import { FourKMaps } from "./pages/maps/FourKMaps";
import IBRCrates from "routers/IBRRouter";
import { FAQ } from "pages/FAQ";
import { Contact } from "pages/Contact";
import PlayerStatsLandingPage from "pages/playerStats/PlayerStatsLanding";
import PlayerSearch from "pages/playerStats/PlayerSearch";
import Resources from "pages/Resources";
import PrivacyPolicy from "pages/PrivacyPolicy";
import SupportUsPage from "pages/SupportUsPage";
import SavedPlayers from "pages/usersOnly/SavedPlayers";
import SavedMatches from "pages/usersOnly/SavedMatches";
import { ForgotPassword } from "pages/usersOnly/ForgotPassword";
import { ResetPassword } from "pages/usersOnly/ResetPassword";
import { EmailVerification } from "pages/usersOnly/EmailVerification";
import { Preferences } from "pages/usersOnly/Preferences";
import VersionHistory from "pages/VersionHistory";
import { TDMMaps } from "pages/maps/TDMMaps";
import { LandingPageProvider } from "components/LandingPageProvider";
import MapRotationPage from "pages/MapRotationPage";
import { FocusProvider } from "hooks/FocusContext";
import C4Timer from "pages/c4Timer";
import BotList from "pages/BotList";
import WeaponsWiki from "pages/WeaponsWiki";
import { COLORS } from "constants/appWide/colors";
import { ScrollToTopProvider } from "components/ScrollToTop";
import { VikendiSecretRooms } from "pages/maps/VikendiSecretRooms";

const App: React.FC = () => {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_ANALYTICS_ID;

    // Turnstile verification
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);

    // Check if the user is already verified
    useEffect(() => {
        const checkVerification = async () => {
            // Check client-side cookie first
            const clientVerified = Cookies.get("turnstileVerifiedClient");

            if (clientVerified === "true") {
                setIsVerified(true);
                setLoading(false); // Skip backend validation if cookie exists
                return;
            }

            // Fallback to backend validation
            try {
                const response = await axios.get(
                    API_ENDPOINTS.CHECK_VERIFICATION,
                    { withCredentials: true }
                );

                setIsVerified(response.data.verified);
            } catch (error) {
                console.error("Error checking verification:", error);
                setIsVerified(false);
            } finally {
                setLoading(false);
            }
        };

        checkVerification();
    }, []);

    // Turnstile verification
    const handleVerification = async (token: string) => {
        try {
            // Validate token with the backend
            const response = await axios.post(
                API_ENDPOINTS.VALIDATE_TURNSTILE,
                { token },
                { withCredentials: true } // Include cookies in the request
            );

            if (response.data.success) {
                setIsVerified(true); // Successfully verified
            } else {
                console.error("Turnstile validation failed.");
            }
        } catch (error) {
            console.error("Error validating Turnstile token:", error);
        }
    };
    //////////////////////////////

    useEffect(() => {
        if (process.env.NODE_ENV === "production" && GA_MEASUREMENT_ID) {
            ReactGA.initialize(GA_MEASUREMENT_ID);
            ReactGA.send("pageview");
        }
    }, [GA_MEASUREMENT_ID]);

    const [themePreferences, setThemePreferences] = useState(() => {
        const storedPreferences = localStorage.getItem("themePreferences");
        return storedPreferences
            ? JSON.parse(storedPreferences)
            : {
                  primaryColor: COLORS.orange,
                  accentColor1: COLORS.green,
                  accentColor2: COLORS.blue,
                  backgroundColor: "#222222",
                  fontSize: 14,
                  fontFamily: "'Arial', sans-serif",
                  landingPage: "home",
              };
    });

    const theme = getDynamicTheme(themePreferences);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (process.env.REACT_APP_API_URL != "http://localhost:5000") {
        console.log = () => {}; // Disable console.log
        console.warn = () => {}; // Optionally disable console.warn
        console.error = () => {}; // Optionally disable console.error
    }

    if (loading) {
        return null;
    }

    if (!isVerified) {
        return <TurnstileWrapper onVerified={handleVerification} />;
    }

    return (
        <FocusProvider>
            <LandingPageProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <AuthProvider>
                        <Router>
                            <ScrollToTopProvider>
                                {/* Main layout container */}
                                <div
                                    className="App"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        minHeight: "100vh",
                                    }}
                                >
                                    <Navbar />
                                    {/* Content wrapper with flex-grow to fill space */}
                                    <div style={{ flex: 1 }}>
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={<Home />}
                                            />
                                            {/* Login / Registration / Account Routes */}
                                            <Route
                                                path="/login"
                                                element={<Login />}
                                            />
                                            <Route
                                                path="/account-settings"
                                                element={
                                                    <ProtectedRoute>
                                                        <AccountSettings />
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="/preferences"
                                                element={
                                                    <ProtectedRoute>
                                                        <Preferences
                                                            setThemePreferences={
                                                                setThemePreferences
                                                            }
                                                        />
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="/saved-players"
                                                element={
                                                    <ProtectedRoute>
                                                        <SavedPlayers />
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="/saved-matches"
                                                element={
                                                    <ProtectedRoute>
                                                        <SavedMatches />
                                                    </ProtectedRoute>
                                                }
                                            />

                                            {/* Tech Stack */}
                                            <Route
                                                path="/tech"
                                                element={<Tech />}
                                            />
                                            <Route
                                                path="/showTechStack"
                                                element={<Tech />}
                                            />
                                            {/* ---------------------------- */}
                                            {/* FAQ / Feedback / Register Routes */}
                                            <Route
                                                path="/faq"
                                                element={<FAQ />}
                                            />
                                            <Route
                                                path="/contact"
                                                element={<Contact />}
                                            />
                                            <Route
                                                path="/privacy-policy"
                                                element={<PrivacyPolicy />}
                                            />
                                            <Route
                                                path="/version-history"
                                                element={<VersionHistory />}
                                            />
                                            <Route
                                                path="/register"
                                                element={<Register />}
                                            />
                                            <Route
                                                path="/verify"
                                                element={<EmailVerification />}
                                            />
                                            <Route
                                                path="/forgot-password"
                                                element={<ForgotPassword />}
                                            />
                                            <Route
                                                path="/reset-password"
                                                element={<ResetPassword />}
                                            />
                                            {/* ---------------------------- */}
                                            {/* Player Stats Routes */}
                                            <Route
                                                path="/player-search"
                                                element={<PlayerSearch />}
                                            />

                                            <Route
                                                path="/player-stats/:platform/:playerName/*"
                                                element={
                                                    <PlayerStatsLandingPage />
                                                }
                                            />

                                            {/* ---------------------------- */}
                                            {/* Tier List Routes */}
                                            <Route
                                                path="/tier-lists"
                                                element={<TierListsLanding />}
                                            />

                                            <Route
                                                path="/tier-lists/maker"
                                                element={
                                                    <DndProvider
                                                        backend={
                                                            isMobile
                                                                ? TouchBackend
                                                                : HTML5Backend
                                                        }
                                                    >
                                                        <TierListMaker />
                                                    </DndProvider>
                                                }
                                            />
                                            <Route
                                                path="/tier-lists/public"
                                                element={<PublicTierLists />}
                                            />
                                            <Route
                                                path="/tier-lists/maker/:id"
                                                element={
                                                    <DndProvider
                                                        backend={
                                                            isMobile
                                                                ? TouchBackend
                                                                : HTML5Backend
                                                        }
                                                    >
                                                        <TierListMaker />
                                                    </DndProvider>
                                                }
                                            />
                                            {/* ---------------------------- */}

                                            {/* Maps Routes */}
                                            <Route
                                                path="/maps"
                                                element={<MapsLanding />}
                                            />
                                            <Route
                                                path="/maps/4k-maps"
                                                element={<FourKMaps />}
                                            />
                                            <Route
                                                path="/maps/4k-maps/:mapName"
                                                element={<FourKMaps />}
                                            />
                                            <Route
                                                path="/maps/tdm"
                                                element={<TDMMaps />}
                                            />
                                            <Route
                                                path="/maps/tdm/:mapName"
                                                element={<TDMMaps />}
                                            />
                                            <Route
                                                path="/maps/erangel-secret-rooms"
                                                element={<ErangelSecretRooms />}
                                            />
                                            <Route
                                                path="/maps/vikendi-secret-rooms"
                                                element={<VikendiSecretRooms />}
                                            />
                                            <Route
                                                path="/maps/taego-secret-rooms"
                                                element={<TaegoSecretRooms />}
                                            />
                                            <Route
                                                path="/maps/deston-secret-rooms"
                                                element={<DestonSecretRooms />}
                                            />
                                            {/* IBR Routes */}
                                            <Route
                                                path="/maps/ibr-crates/*"
                                                element={<IBRCrates />}
                                            />
                                            <Route
                                                path="/rotations"
                                                element={<MapRotationPage />}
                                            />
                                            {/* ---------------------------- */}
                                            <Route
                                                path="/tips-strategies"
                                                element={<TipsStrategies />}
                                            />
                                            <Route
                                                path="/patches"
                                                element={<PatchesPage />}
                                            />
                                            <Route
                                                path="/resources"
                                                element={<Resources />}
                                            />
                                            <Route
                                                path="/support-us"
                                                element={<SupportUsPage />}
                                            />
                                            <Route
                                                path="/c4-timer"
                                                element={<C4Timer />}
                                            />
                                            <Route
                                                path="/bot-list"
                                                element={<BotList />}
                                            />
                                            <Route
                                                path="/weapons-wiki"
                                                element={<WeaponsWiki />}
                                            />
                                        </Routes>
                                    </div>
                                    <Footer />
                                </div>
                            </ScrollToTopProvider>
                        </Router>
                    </AuthProvider>
                </ThemeProvider>
            </LandingPageProvider>
        </FocusProvider>
    );
};

export default App;
