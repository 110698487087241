import { Theme } from "@mui/material/styles";
import { hexToRgb } from "../utils/hexToRgb";

export const emphasizedTypographyStyle = (theme: Theme) => ({
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)}, 0.1)`,
    padding: "0px 4px",
    borderRadius: "8px",
    textAlign: "center",
    display: "inline-block",
});

// utils/tableStyles.ts
export const getHighlightedRowStyles = (
    isActivePlayer: boolean,
    theme: any
) => ({
    backgroundColor: isActivePlayer ? theme.palette.primary.main : "inherit",
    color: isActivePlayer ? "black" : "inherit",
    "& td, & th": {
        color: isActivePlayer ? "black" : "inherit",
    },
});

/**
 * Returns styles for a custom scrollbar appearance.
 * Supports both WebKit (Chrome, Edge, Safari) and Firefox.
 */
export const getScrollbarStyles = () => ({
    /* WebKit-based browsers (Chrome, Edge, Safari) */
    "&::-webkit-scrollbar": {
        width: "15px",
        height: "10px",
    },
    "&::-webkit-scrollbar-track": {
        background: "#222", // Dark background track
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#666", // Medium gray scrollbar thumb
        borderRadius: "10px",
        "&:hover": {
            background: "#888", // Lighter gray when hovered
        },
    },

    /* 🛠 Fix Double Arrows: Hide Default Scroll Buttons */
    "&::-webkit-scrollbar-button": {
        display: "none", // Prevents default arrows from appearing
    },

    /* 🎯 Custom Horizontal Scrollbar Arrows (Left & Right) */
    "&::-webkit-scrollbar-button:horizontal:start:decrement": {
        display: "block",
        background:
            'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23aaa"><path d="M8 12l6-6v12z"/></svg>\') center no-repeat',
        backgroundSize: "contain",
        width: "15px",
        height: "15px",
    },
    "&::-webkit-scrollbar-button:horizontal:end:increment": {
        display: "block",
        background:
            'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23aaa"><path d="M16 12l-6 6V6z"/></svg>\') center no-repeat',
        backgroundSize: "contain",
        width: "15px",
        height: "15px",
    },

    /* 🚫 Hide Vertical Scrollbar Arrows (Only Horizontal Arrows) */
    "&::-webkit-scrollbar-button:vertical:start:decrement, &::-webkit-scrollbar-button:vertical:end:increment":
        {
            display: "none",
        },
});
