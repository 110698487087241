// used in the RecentMatches and MatchDetails components

import React from "react";
import {
    Card,
    CardContent,
    Typography,
    LinearProgress,
    Box,
    CardActions,
    Button,
    useTheme,
    IconButton,
    Divider,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// Types
import { Match } from "types/match";

// Mapping
import { mapNameMapping } from "constants/mapNameMapping";

// Utils
import { formatMatchType } from "utils/format/formatMatchType";
import { formatGameMode } from "utils/format/formatGameMode";

// Colors
import { COLORS } from "constants/appWide/colors";

// Icons
import { mapIcons } from "constants/mapIcons";
import dinner from "assets/matchCardIcons/dinner.png";
import trophy from "assets/matchCardIcons/trophy.png";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Icon for joinedLate
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; // Icon for leftEarly
import SportsScoreIcon from "@mui/icons-material/SportsScore"; // Icon for Team Stats

// Constants
import { mapImages } from "constants/mapImages";
import { getElapsedTime } from "utils/getElapsedTime";
import { isSurvivor, isWinner } from "utils/matchIcons";
import { format } from "date-fns";
import { partyImages } from "constants/partyImages";
import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";
import { getScrollbarStyles } from "styles/styles";
import DonutChart from "components/dataViz/MatchCard/DonutChart";
import { getSixteenPredefinedColors } from "constants/getSixteenPredefinedColors";

interface MatchCardProps {
    match: Match;
    matchIndex?: number;
    accountId: string | null;
    viewType: "compactCard" | "detailedCard";
    onMatchHistoryPage?: boolean;
    platform: string;
    playerName: string;
    favoriteGameMode: string;
    showImages: boolean;
    isSaved?: boolean;
}

const renderTableHead = (gameMode: string) => {
    if (gameMode === "tdm") {
        return (
            <thead>
                <tr>
                    <th
                        style={{
                            padding: "8px",
                            textAlign: "left",
                        }}
                    >
                        Name
                    </th>
                    <th
                        style={{
                            padding: "8px",
                            textAlign: "center",
                        }}
                    >
                        Kills
                    </th>
                    <th
                        style={{
                            padding: "8px",
                            textAlign: "center",
                        }}
                    >
                        Deaths
                    </th>
                    <th
                        style={{
                            padding: "8px",
                            textAlign: "center",
                        }}
                    >
                        K/D
                    </th>
                    <th
                        style={{
                            padding: "8px",
                            textAlign: "center",
                        }}
                    >
                        Damage Dealt
                    </th>
                    <th
                        style={{
                            padding: "8px",
                            textAlign: "center",
                        }}
                    >
                        Damage Taken
                    </th>
                    <th
                        style={{
                            padding: "8px",
                            textAlign: "center",
                        }}
                    >
                        Score
                    </th>
                </tr>
            </thead>
        );
    } else {
        return (
            <thead>
                <tr
                    style={{
                        textAlign: "left",
                    }}
                >
                    <th style={{ padding: "8px" }}>Name</th>
                    <th style={{ padding: "8px" }}>Survived</th>
                    <th style={{ padding: "8px" }}>Kills</th>
                    <th style={{ padding: "8px" }}>Damage</th>
                    <th style={{ padding: "8px" }}>DBNOs</th>
                    <th style={{ padding: "8px" }}>Assists</th>
                </tr>
            </thead>
        );
    }
};

const MatchCard: React.FC<MatchCardProps> = ({
    match,
    matchIndex,
    accountId,
    viewType,
    platform,
    playerName,
    onMatchHistoryPage,
    favoriteGameMode,
    showImages,
    isSaved = false,
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const mapName = match.mapName;
    const icon = mapIcons[mapNameMapping[mapName]];
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    // Calculate dynamic minHeight
    const height =
        viewType === "compactCard"
            ? "408px"
            : onMatchHistoryPage // detailed card, on match history page
            ? "810px" // Shorter height when the button is visible
            : "990px"; // Default detailed view height without button

    // Maintain a consistent color map for players across both charts
    const playerColorMap: { [key: string]: string } = {};

    const colors = getSixteenPredefinedColors(theme);

    // Function to assign a color to each player and ensure consistency
    const getPlayerColor = (name: string, index: number) => {
        if (name === playerName) {
            // Compare against the component-level prop
            return theme.palette.primary.main; // Always assign the main theme color to the highlight player
        }
        if (!playerColorMap[name]) {
            playerColorMap[name] = colors[index % colors.length]; // Cycle through colors
        }
        return playerColorMap[name];
    };

    // Determine the correct roster based on game mode
    const playerTeamRoster =
        match.gameMode === "tdm" && match.tdmStats
            ? match.tdmStats?.playerTeamId === 1
                ? match.tdmStats.team1Roster
                : match.tdmStats.team2Roster
            : match.playerRoster;

    // Aggregate player kills and damage
    const killData = playerTeamRoster.map((player, index) => ({
        name: player.name,
        value: Math.round(player.kills), // Ensure integers
        color: getPlayerColor(player.name, index),
    }));

    const damageData = playerTeamRoster.map((player, index) => ({
        name: player.name,
        value: player.damageDealt, // Ensure integers
        color: getPlayerColor(player.name, index), // Reuse the same color
    }));

    // Calculate team-wide kills and damage differently for TDM vs Normal
    const teamKills =
        match.gameMode === "tdm" && match.tdmStats
            ? match.tdmStats.playerTeamId === 1
                ? match.tdmStats.totalTeam1Kills
                : match.tdmStats.totalTeam2Kills
            : match.playerRosterKills;

    const teamDamage =
        match.gameMode === "tdm" && match.tdmStats
            ? match.tdmStats.playerTeamId === 1
                ? match.tdmStats.totalTeam1Damage
                : match.tdmStats.totalTeam2Damage
            : match.playerRosterDamage;

    return (
        <Card
            sx={{
                position: "relative",
                height: onMatchHistoryPage ? height : "auto",
                marginBottom: "16px",
                border:
                    favoriteGameMode === match.gameMode
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
            }}
        >
            <CardContent
                sx={{
                    padding: "2px 12px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #ccc", // Divider-like style
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1, // Space between index and game mode/match type
                    }}
                >
                    {/* Index */}
                    {matchIndex !== undefined && (
                        <Typography
                            variant="subtitle2"
                            sx={{
                                fontWeight: "bold",
                                color: theme.palette.primary.main,
                                fontSize: "14px",
                            }}
                        >
                            {matchIndex}
                        </Typography>
                    )}
                    {/* Game Mode and Match Type */}
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        {formatGameMode(match.gameMode)} -{" "}
                        {formatMatchType(match.matchType)}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0, // Space between icons and elapsed time
                        justifyContent: "flex-end", // Align to the right
                        minHeight: 48, // Ensures consistent height (adjust if needed)
                    }}
                >
                    {isWinner(match) && (
                        <Tooltip
                            title={
                                ["ibr", "tdm"].includes(match.gameMode) ||
                                match.matchType === "airoyale"
                                    ? "You won! - Intense Battle Royale / Team Deathmatch / Casual"
                                    : "Winner Winner Chicken Dinner!"
                            }
                            arrow
                        >
                            <img
                                src={
                                    ["ibr", "tdm"].includes(match.gameMode) ||
                                    match.matchType === "airoyale"
                                        ? trophy
                                        : dinner
                                }
                                alt={
                                    ["ibr", "tdm"].includes(match.gameMode) ||
                                    match.matchType === "airoyale"
                                        ? "Trophy Icon"
                                        : "Chicken Dinner Icon"
                                }
                                style={{
                                    width: 40,
                                    height: 40,
                                }}
                            />
                        </Tooltip>
                    )}
                    {isSurvivor(match) && (
                        <Tooltip
                            title="Survived until the last 15 seconds of the match!"
                            arrow
                        >
                            <VerifiedUserIcon
                                sx={{
                                    fontSize: 40, // Adjust size
                                    color: COLORS.green, // Green color to signify survival
                                }}
                            />
                        </Tooltip>
                    )}

                    {/* Elapsed Time */}
                    <Tooltip
                        title={`Ended: ${format(
                            new Date(
                                new Date(match.createdAt).getTime() +
                                    match.duration * 1000
                            ),
                            "PPpp" // Use date-fns formatting to show local time
                        )}`}
                        arrow
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                fontStyle: "italic",
                                color: theme.palette.text.secondary,
                            }}
                        >
                            {getElapsedTime(match.createdAt, match.duration)}
                        </Typography>
                    </Tooltip>
                </Box>
            </CardContent>

            <CardContent
                sx={{
                    padding: "12px 12px 0 12px",
                }}
            >
                {/* Trophy or Chicken Dinner Icon */}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center", // Center horizontally within the available space
                        alignItems: "center", // Center vertically between text and icon
                        gap: 1, // Space between text and icon
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            lineHeight: 1, // Prevent extra vertical spacing
                        }}
                    >
                        Map: {mapNameMapping[mapName]}
                    </Typography>
                    {icon &&
                        (typeof icon === "string" ? (
                            <img
                                src={icon}
                                alt={`${mapName} Icon`}
                                style={{
                                    width: "32px",
                                    height: "32px",
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            React.createElement(icon, {
                                sx: { width: 32, height: 32 },
                            })
                        ))}
                </Box>

                {match.gameMode !== "tdm" &&
                match.matchType !== "training" &&
                match.matchType !== "trainingroom" ? (
                    <Typography>
                        Place: #{match.playerWinPlace} / {match.rosters.length}
                    </Typography>
                ) : match.gameMode === "tdm" ? (
                    <Typography>
                        {match.playerWinPlace === 1 ? "Won TDM" : "Lost TDM"}{" "}
                        {match.tdmStats?.roundsPlayed === 3
                            ? match.playerWinPlace === 1
                                ? `2:1`
                                : `1:2`
                            : match.playerWinPlace === 1
                            ? `2:0`
                            : `0:2`}{" "}
                        (
                        {match.tdmStats?.playerTeamId === 1
                            ? `${match.tdmStats?.totalTeam1Kills || 0} - ${
                                  match.tdmStats?.totalTeam2Kills || 0
                              }`
                            : `${match.tdmStats?.totalTeam2Kills || 0} - ${
                                  match.tdmStats?.totalTeam1Kills || 0
                              }`}
                        )
                    </Typography>
                ) : null}

                {showImages && (
                    <>
                        {!isMobile ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between", // Push left/right charts to edges
                                    gap: 3,
                                    width: "100%",
                                }}
                            >
                                {viewType === "detailedCard" &&
                                    !onMatchHistoryPage && (
                                        <Box
                                            sx={{
                                                flex: 1,
                                                minWidth: 200,
                                                textAlign: "left",
                                            }}
                                        >
                                            <DonutChart
                                                data={killData}
                                                title="Kill Distribution"
                                                total={killData.reduce(
                                                    (sum, p) => sum + p.value,
                                                    0
                                                )}
                                                highlightPlayer={playerName}
                                            />
                                        </Box>
                                    )}

                                <Box sx={{ flex: 2, textAlign: "center" }}>
                                    <img
                                        src={
                                            mapImages[
                                                mapNameMapping[match.mapName]
                                            ]
                                        }
                                        alt={`${match.mapName} Thumbnail`}
                                        style={{
                                            width: "100%", // Use available space
                                            maxWidth: onMatchHistoryPage
                                                ? "600px"
                                                : "1200px", // Increased
                                            borderRadius: "8px",
                                        }}
                                    />
                                </Box>

                                {viewType === "detailedCard" &&
                                    !onMatchHistoryPage && (
                                        <Box
                                            sx={{
                                                flex: 1,
                                                minWidth: 200,
                                                textAlign: "right",
                                            }}
                                        >
                                            <DonutChart
                                                data={damageData}
                                                title="Damage Distribution"
                                                total={damageData.reduce(
                                                    (sum, p) => sum + p.value,
                                                    0
                                                )}
                                                highlightPlayer={playerName}
                                            />
                                        </Box>
                                    )}
                            </Box>
                        ) : (
                            /* Mobile: Full Donut Charts Below Map */
                            <Box
                                sx={{
                                    textAlign: "center",
                                    mt: 2,
                                    width: "100%",
                                }}
                            >
                                <img
                                    src={
                                        mapImages[mapNameMapping[match.mapName]]
                                    }
                                    alt={`${match.mapName} Thumbnail`}
                                    style={{
                                        maxWidth: "100%", // Responsive image
                                        width: onMatchHistoryPage
                                            ? "400px"
                                            : "600px",
                                    }}
                                />
                                {viewType === "detailedCard" &&
                                    !onMatchHistoryPage && (
                                        <Box sx={{ width: "100%", mt: 2 }}>
                                            <DonutChart
                                                data={killData}
                                                title="Kill Distribution"
                                                total={killData.reduce(
                                                    (sum, p) => sum + p.value,
                                                    0
                                                )}
                                                highlightPlayer={playerName}
                                            />
                                        </Box>
                                    )}
                                {viewType === "detailedCard" &&
                                    !onMatchHistoryPage && (
                                        <Box sx={{ width: "100%", mt: 2 }}>
                                            <DonutChart
                                                data={damageData}
                                                title="Damage Distribution"
                                                total={damageData.reduce(
                                                    (sum, p) => sum + p.value,
                                                    0
                                                )}
                                                highlightPlayer={playerName}
                                            />
                                        </Box>
                                    )}
                            </Box>
                        )}
                    </>
                )}

                {/* Divider Below Game Mode and Match Type */}
                <Divider sx={{ marginTop: 2 }} />

                {viewType === "detailedCard" && (
                    <>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            mt={2}
                            textAlign="left"
                        >
                            <Box>
                                <Typography>
                                    My Kills: {match.playerKills}
                                </Typography>

                                {match.playerRoster.length > 1 && (
                                    <Typography>
                                        Our Kills: {teamKills}
                                    </Typography>
                                )}
                            </Box>
                            {accountId && (
                                <Box textAlign={"right"}>
                                    <Typography>
                                        My Damage:{" "}
                                        {Number(
                                            match.playerDamage.toFixed(0)
                                        ).toLocaleString()}
                                    </Typography>
                                    {playerTeamRoster.length > 1 && ( // ✅ Uses correct roster
                                        <Typography>
                                            Our Damage:{" "}
                                            {Number(
                                                teamDamage.toFixed(0)
                                            ).toLocaleString()}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </Box>

                        {/* Roster Table */}
                        {/* Roster Table */}
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    padding: "8px 16px",
                                    borderRadius: 2,
                                    boxShadow: 1,
                                    mt: 2,
                                    gap: 1, // Space between icon and text
                                }}
                            >
                                <SportsScoreIcon
                                    sx={{ color: theme.palette.primary.main }}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    Team Stats
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    overflowX: "auto",
                                    maxHeight: onMatchHistoryPage
                                        ? "246px"
                                        : "none",
                                    overflowY: "auto",
                                    padding: 1,
                                    borderRadius: 1,
                                    [theme.breakpoints.up("md")]:
                                        getScrollbarStyles(),
                                }}
                            >
                                <table
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                    }}
                                >
                                    {renderTableHead(match.gameMode)}
                                    <tbody>
                                        {match.gameMode === "tdm" &&
                                            match.tdmStats &&
                                            (() => {
                                                // Determine which roster to use (player's team roster)
                                                const playerTeamRoster =
                                                    match.tdmStats
                                                        ?.playerTeamId === 1
                                                        ? match.tdmStats
                                                              .team1Roster
                                                        : match.tdmStats
                                                              ?.team2Roster;

                                                // Sort the roster by total score
                                                // Sort the roster by total score
                                                const sortedRoster =
                                                    playerTeamRoster.sort(
                                                        (a, b) => {
                                                            // Calculate total score for each player
                                                            const aTotalScore =
                                                                a.killScore +
                                                                (match.tdmStats
                                                                    ?.playerTeamId ===
                                                                1
                                                                    ? match
                                                                          .tdmStats
                                                                          ?.team1Points ||
                                                                      0
                                                                    : match
                                                                          .tdmStats
                                                                          ?.team2Points ||
                                                                      0);
                                                            const bTotalScore =
                                                                b.killScore +
                                                                (match.tdmStats
                                                                    ?.playerTeamId ===
                                                                1
                                                                    ? match
                                                                          .tdmStats
                                                                          ?.team1Points ||
                                                                      0
                                                                    : match
                                                                          .tdmStats
                                                                          ?.team2Points ||
                                                                      0);

                                                            // Sort by total score descending
                                                            if (
                                                                bTotalScore !==
                                                                aTotalScore
                                                            ) {
                                                                return (
                                                                    bTotalScore -
                                                                    aTotalScore
                                                                );
                                                            }

                                                            // If total scores are equal, sort by kills descending
                                                            if (
                                                                b.kills !==
                                                                a.kills
                                                            ) {
                                                                return (
                                                                    b.kills -
                                                                    a.kills
                                                                );
                                                            }

                                                            // If all else is equal, return 0
                                                            return 0;
                                                        }
                                                    );

                                                return sortedRoster.map(
                                                    (player) => {
                                                        // Find the player's damage in `playerRoster`

                                                        // Calculate K/D ratio
                                                        const kdRatio =
                                                            player.deaths > 0
                                                                ? (
                                                                      player.kills /
                                                                      player.deaths
                                                                  ).toFixed(2)
                                                                : player.kills >
                                                                  0
                                                                ? player.kills.toFixed(
                                                                      2
                                                                  )
                                                                : "0";

                                                        // Calculate total score
                                                        const totalScore =
                                                            player.killScore +
                                                            (match.tdmStats
                                                                ?.playerTeamId ===
                                                            1
                                                                ? match.tdmStats
                                                                      ?.team1Points ||
                                                                  0
                                                                : match.tdmStats
                                                                      ?.team2Points ||
                                                                  0);

                                                        return (
                                                            <tr
                                                                key={
                                                                    player.accountId
                                                                }
                                                                style={{
                                                                    borderBottom:
                                                                        "1px solid #ddd",
                                                                    backgroundColor:
                                                                        player.name ===
                                                                        playerName
                                                                            ? theme
                                                                                  .palette
                                                                                  .primary
                                                                                  .main
                                                                            : "transparent",
                                                                    color:
                                                                        player.name ===
                                                                        playerName
                                                                            ? theme
                                                                                  .palette
                                                                                  .primary
                                                                                  .contrastText
                                                                            : "inherit",
                                                                }}
                                                            >
                                                                <td
                                                                    style={{
                                                                        padding:
                                                                            "8px",
                                                                        textAlign:
                                                                            "left",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        gap: "8px", // Spacing between icon and name
                                                                    }}
                                                                >
                                                                    {player.joinedLate && (
                                                                        <Tooltip
                                                                            title="Joined Late"
                                                                            arrow
                                                                        >
                                                                            <AccessTimeIcon
                                                                                fontSize="small"
                                                                                style={{
                                                                                    color:
                                                                                        player.name ===
                                                                                        playerName
                                                                                            ? theme
                                                                                                  .palette
                                                                                                  .common
                                                                                                  .black // Black if highlighted row
                                                                                            : theme
                                                                                                  .palette
                                                                                                  .warning
                                                                                                  .main, // Default orange
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                    {player.leftEarly && (
                                                                        <Tooltip
                                                                            title="Left Early"
                                                                            arrow
                                                                        >
                                                                            <ExitToAppIcon
                                                                                fontSize="small"
                                                                                style={{
                                                                                    color:
                                                                                        player.name ===
                                                                                        playerName
                                                                                            ? theme
                                                                                                  .palette
                                                                                                  .common
                                                                                                  .black // Black if highlighted row
                                                                                            : theme
                                                                                                  .palette
                                                                                                  .error
                                                                                                  .main, // Default red
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                    <Typography
                                                                        component="a"
                                                                        href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                                                            platform,
                                                                            player.name
                                                                        )}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        sx={{
                                                                            textDecoration:
                                                                                "none",
                                                                            color:
                                                                                player.name ===
                                                                                playerName
                                                                                    ? "black"
                                                                                    : theme
                                                                                          .palette
                                                                                          .text
                                                                                          .primary,

                                                                            "&:hover":
                                                                                {
                                                                                    textDecoration:
                                                                                        "underline",
                                                                                },
                                                                        }}
                                                                    >
                                                                        {
                                                                            player.name
                                                                        }
                                                                    </Typography>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding:
                                                                            "8px",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {player.kills ||
                                                                        "0"}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding:
                                                                            "8px",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {player.deaths ||
                                                                        "0"}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding:
                                                                            "8px",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {kdRatio}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding:
                                                                            "8px",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {player.damageDealt.toLocaleString()}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding:
                                                                            "8px",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        player.damageTaken
                                                                    }
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding:
                                                                            "8px",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {totalScore.toLocaleString()}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                );
                                            })()}

                                        {match.gameMode !== "tdm" &&
                                            match.playerRoster &&
                                            [...match.playerRoster].map(
                                                (player) => (
                                                    <tr
                                                        key={player.name}
                                                        style={{
                                                            borderBottom:
                                                                "1px solid #ddd",
                                                            backgroundColor:
                                                                player.name ===
                                                                    playerName ||
                                                                player.accountId ===
                                                                    accountId
                                                                    ? theme
                                                                          .palette
                                                                          .primary
                                                                          .main
                                                                    : "transparent",
                                                            color:
                                                                player.name ===
                                                                    playerName ||
                                                                player.accountId ===
                                                                    accountId
                                                                    ? theme
                                                                          .palette
                                                                          .primary
                                                                          .contrastText
                                                                    : "inherit",
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                padding: "8px",
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            <Typography
                                                                component="a"
                                                                href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                                                    platform,
                                                                    player.name
                                                                )}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                sx={{
                                                                    textDecoration:
                                                                        "none",
                                                                    color:
                                                                        player.name ===
                                                                        playerName
                                                                            ? "black"
                                                                            : theme
                                                                                  .palette
                                                                                  .text
                                                                                  .primary,

                                                                    "&:hover": {
                                                                        textDecoration:
                                                                            "underline",
                                                                    },
                                                                }}
                                                            >
                                                                {player.name}
                                                            </Typography>
                                                        </td>
                                                        {match.gameMode !==
                                                            "tdm" && (
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        "8px",
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                {Math.floor(
                                                                    (player.timeSurvived /
                                                                        match.duration) *
                                                                        100
                                                                )}
                                                                %
                                                            </td>
                                                        )}
                                                        <td
                                                            style={{
                                                                padding: "8px",
                                                            }}
                                                        >
                                                            {player.kills}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "8px",
                                                            }}
                                                        >
                                                            {Number(
                                                                player.damageDealt.toFixed(
                                                                    0
                                                                )
                                                            ).toLocaleString()}
                                                        </td>
                                                        {match.gameMode !==
                                                            "tdm" && (
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        "8px",
                                                                }}
                                                            >
                                                                {player.DBNOs}
                                                            </td>
                                                        )}
                                                        {match.gameMode !==
                                                            "tdm" && (
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        "8px",
                                                                }}
                                                            >
                                                                {player.assists}
                                                            </td>
                                                        )}
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </Box>
                        </Box>

                        {(match.gameMode.includes("squad") ||
                            match.gameMode.includes("ibr")) &&
                            match.playerRoster.length === 1 && (
                                <Box
                                    sx={{
                                        marginTop: 2,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 2,
                                        border: (theme) =>
                                            `2px solid ${theme.palette.primary.main}`, // Main theme color for the border
                                        borderRadius: 2, // Rounded corners
                                        gap: 1,
                                    }}
                                >
                                    <img
                                        src={partyImages.oneManSquad}
                                        alt="One Man Squad"
                                        style={{
                                            width: "50px", // Adjust as per your design
                                            height: "50px", // Adjust as per your design
                                            objectFit: "contain", // Ensure the image retains its aspect ratio
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                        }}
                                    >
                                        {`1-man ${
                                            match.gameMode.includes("ibr")
                                                ? "IBR"
                                                : "Squad"
                                        }! You are insane!`}
                                    </Typography>
                                </Box>
                            )}

                        {!onMatchHistoryPage &&
                            match.playerRoster.length === 4 && (
                                <Box
                                    sx={{
                                        marginTop: 2,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 2,
                                        border: (theme) =>
                                            `2px solid ${theme.palette.primary.main}`, // Main theme color for the border
                                        borderRadius: 2, // Rounded corners
                                        gap: 1,
                                    }}
                                >
                                    <img
                                        src={partyImages.squad}
                                        alt="Four Man Squad"
                                        style={{
                                            width: "50px", // Adjust as per your design
                                            height: "50px", // Adjust as per your design
                                            objectFit: "contain", // Ensure the image retains its aspect ratio
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                        }}
                                    >
                                        4 Strong! Your squad was as prepared as
                                        it could be and gave it your all!
                                    </Typography>
                                </Box>
                            )}

                        <Box sx={{ marginTop: 2 }}>
                            <Typography variant="body2">
                                Survival Time:{" "}
                                {Math.floor(match.playerSurvivalTime / 60)}m{" "}
                                {match.playerSurvivalTime % 60}s /{" "}
                                {Math.floor(match.duration / 60)}m{" "}
                                {match.duration % 60}s
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={
                                    (match.playerSurvivalTime /
                                        match.duration) *
                                    100
                                }
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    backgroundColor: "#ddd",
                                    "& .MuiLinearProgress-bar": {
                                        backgroundColor: COLORS.green,
                                    },
                                }}
                            />
                        </Box>
                    </>
                )}
            </CardContent>
            {onMatchHistoryPage && (
                <CardActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Button
                        component="a"
                        href={`/player-stats/${platform}/${playerName}/matches/${match.matchId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                            // Prevent default if Match ID is undefined
                            if (!match.matchId) {
                                e.preventDefault();
                                console.error("Match ID is undefined:", match);
                            }
                        }}
                    >
                        View Details
                    </Button>
                    {isSaved && (
                        <IconButton
                            disableRipple
                            sx={{
                                color: "red",
                                marginLeft: "auto",
                            }}
                        >
                            <FavoriteIcon />
                        </IconButton>
                    )}
                </CardActions>
            )}
        </Card>
    );
};

export default MatchCard;
