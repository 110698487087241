export const processSelfRevives = (
    telemetryData: any[],
    matchStartTime: string
) => {
    const selfRevives: {
        name: string;
        phase: number;
        reviveTime: string;
    }[] = [];

    const matchStartMs = new Date(matchStartTime).getTime(); // Convert start time to milliseconds

    telemetryData.forEach((event) => {
        if (
            event._T === "LogPlayerRevive" &&
            event.reviver.name === event.victim.name
        ) {
            const playerName = event.reviver.name;
            const revivePhase = event.common.isGame;
            const reviveTimeMs = new Date(event._D).getTime(); // Convert event timestamp to milliseconds
            const timeDiffSec = Math.floor(
                (reviveTimeMs - matchStartMs) / 1000
            ); // Time difference in seconds

            const minutes = Math.floor(timeDiffSec / 60);
            const seconds = timeDiffSec % 60;
            const formattedReviveTime = `${minutes}m ${seconds}s`;

            selfRevives.push({
                name: playerName,
                phase: revivePhase,
                reviveTime: formattedReviveTime,
            });
        }
    });

    return selfRevives;
};
