import React, { useState } from "react";
import {
    Box,
    Button,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import NiceError from "./NiceError";
import { usePlayerSearch } from "../hooks/usePlayerSearch";
import { useSavedPlayers } from "../hooks/useSavedPlayers";
import { useQuickActionPlayer } from "../hooks/useQuickActionPlayer";
import { usePlayerProfileNavigation } from "../hooks/usePlayerProfileNavigation";
import { handleSearchAction } from "../utils/handleSearchAction";
import { SavedPlayersDropdown } from "./SavedPlayersDropdown";
import QuickActionButton from "./navbar/QuickActionButton";
import { maxWidths } from "constants/appWide/maxWidths";
import { platformLogos } from "constants/platformLogos";

interface PlayerSearchBarProps {
    isAuthenticated: boolean;
}

export const PlayerSearchBox: React.FC<PlayerSearchBarProps> = ({
    isAuthenticated,
}) => {
    const [playerName, setPlayerName] = useState("");
    const [platform, setPlatform] = useState("steam");
    const [errorMessage, setErrorMessage] = useState("");

    const { savedPlayers, loadingSavedPlayers } =
        useSavedPlayers(isAuthenticated);
    const [quickActionPlayer] = useQuickActionPlayer();
    const handleSearch = usePlayerSearch();
    const handleSavedPlayerClick = usePlayerProfileNavigation();

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSearchAction(
                () => handleSearch(playerName, platform),
                setErrorMessage
            );
        }
    };

    const handleButtonClick = () => {
        handleSearchAction(
            () => handleSearch(playerName, platform),
            setErrorMessage
        );
    };

    return (
        <Box
            sx={{
                textAlign: "center",
                width: maxWidths.s,
                maxWidth: "100%",
                margin: "0 auto",
                marginBottom: 2,
                padding: 2,
                border: `2px solid`,
                borderColor: "secondary.main",
                borderRadius: 2,
                boxShadow: 2,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        flexShrink: 0,
                        minWidth: 200,
                    }}
                >
                    {/* Required for wrapping interactive components like dropdown */}
                    <SavedPlayersDropdown
                        isAuthenticated={isAuthenticated}
                        savedPlayers={savedPlayers}
                        loading={loadingSavedPlayers}
                        onSelect={handleSavedPlayerClick}
                    />
                </Box>

                <QuickActionButton
                    isAuthenticated={isAuthenticated}
                    playerName={quickActionPlayer?.playerName || ""}
                    platform={quickActionPlayer?.platform || ""}
                />
            </Box>

            {/* Search Bar */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "center",
                    justifyContent: "center",
                    gap: { xs: 2, sm: 1 },
                    width: "100%",
                }}
            >
                <FormControl
                    sx={{
                        minWidth: "100px",
                        marginRight: { xs: 0, sm: 1 },
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Select
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value)}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            "& img": {
                                width: "20px",
                                height: "20px",
                            },
                        }}
                    >
                        {Object.entries(platformLogos).map(([key, logo]) => (
                            <MenuItem key={key} value={key}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <img
                                        src={logo}
                                        alt={`${key} logo`}
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            objectFit: "contain",
                                        }}
                                    />
                                    {key.toUpperCase()}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Enter Player Name"
                    value={playerName}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 16) {
                            setPlayerName(value);
                        }
                    }}
                    variant="outlined"
                    onKeyDown={handleKeyPress}
                    sx={{ marginRight: 1, width: "300px" }}
                />
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "uppercase",
                    }}
                    onClick={handleButtonClick}
                    disabled={!playerName.trim()}
                >
                    Search
                </Button>
            </Box>
            <Typography
                variant="body2"
                sx={{
                    marginTop: 1,
                    color: "text.secondary",
                }}
            >
                The player name must match the exact in-game name, including
                capitalization.
            </Typography>

            {errorMessage && <NiceError errorMessage={errorMessage} />}
        </Box>
    );
};
