import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    useTheme,
    Tooltip,
} from "@mui/material";

import { crateWeaponsMap } from "constants/crateWeaponsMap";
import { weapons } from "constants/tierListWeapons";

import brdm from "assets/brdm.png";
import SectionHeader from "components/MatchDetails/SectionHeader";

interface CarePackage {
    type: "Red Crate" | "BRDM";
    phase: number;
    landedAt: string;
    weapon?: string;
    lootedAfter?: string;
    lootedBy?: string;
}

interface CarePackageTableProps {
    carePackages: CarePackage[];
}

const CarePackageTable: React.FC<CarePackageTableProps> = ({
    carePackages,
}) => {
    const theme = useTheme();

    return (
        <Box>
            <SectionHeader
                sectionType="carePackages"
                itemCount={carePackages.length}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                Type
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                            >
                                Landed in Phase
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                            >
                                Landed at
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                Content
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                            >
                                Looted After
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                            >
                                Looted By
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {carePackages.map((pkg, index) => (
                            <TableRow key={index}>
                                {/* Type Column */}
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                        }}
                                    >
                                        {pkg.type === "Red Crate" ? (
                                            <img
                                                src={
                                                    pkg.lootedAfter
                                                        ? "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Icons/CarePackage/CarePackage_Open.png"
                                                        : "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Icons/CarePackage/CarePackage_Normal.png"
                                                }
                                                alt={
                                                    pkg.lootedAfter
                                                        ? "Looted"
                                                        : "Unlooted"
                                                }
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        ) : pkg.type === "BRDM" ? (
                                            <img
                                                src={brdm}
                                                alt="BRDM"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        ) : null}
                                        <Typography>{pkg.type}</Typography>
                                    </Box>
                                </TableCell>

                                {/* Landed in Phase Column */}
                                <TableCell align="center">
                                    {pkg.phase}
                                </TableCell>

                                {/* Landed At Column */}
                                <TableCell align="center">
                                    {pkg.landedAt}
                                </TableCell>

                                {/* Content Column */}
                                <TableCell>
                                    {pkg.type === "Red Crate" && pkg.weapon ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                            }}
                                        >
                                            {crateWeaponsMap[pkg.weapon] ? (
                                                (() => {
                                                    // Find the weapon in the weapons array by title
                                                    const weaponName =
                                                        crateWeaponsMap[
                                                            pkg.weapon
                                                        ];
                                                    const weaponEntry =
                                                        weapons.find(
                                                            (w) =>
                                                                w.title ===
                                                                weaponName
                                                        );

                                                    return weaponEntry ? (
                                                        <>
                                                            <img
                                                                src={
                                                                    weaponEntry.src
                                                                }
                                                                alt={
                                                                    weaponEntry.title
                                                                }
                                                                style={{
                                                                    width: "48px",
                                                                    height: "48px",
                                                                    objectFit:
                                                                        "contain",
                                                                }}
                                                            />
                                                            <Box
                                                                sx={{
                                                                    backgroundColor:
                                                                        "rgba(0,0,0,0.1)", // Subtle background for readability
                                                                    padding:
                                                                        "4px 8px",
                                                                    borderRadius:
                                                                        "4px",
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize:
                                                                            "0.9rem",
                                                                        color: "primary.main",
                                                                    }}
                                                                >
                                                                    {
                                                                        weaponEntry.title
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </>
                                                    ) : (
                                                        <Typography>
                                                            {pkg.weapon}
                                                        </Typography> // Fallback if weapon not found
                                                    );
                                                })()
                                            ) : (
                                                <Typography>
                                                    {pkg.weapon}
                                                </Typography> // Fallback if no mapping found
                                            )}
                                        </Box>
                                    ) : pkg.type === "BRDM" ? (
                                        /** Styled N/A for BRDM */
                                        <Tooltip
                                            title="Not applicable for BRDM drops."
                                            arrow
                                        >
                                            <Box
                                                sx={{
                                                    backgroundImage: `repeating-linear-gradient(
                        45deg,
                        rgba(0, 0, 0, 0.1) 0px,
                        rgba(0, 0, 0, 0.1) 10px,
                        transparent 10px,
                        transparent 20px
                    )`,
                                                    backgroundColor:
                                                        "rgba(0, 0, 0, 0.05)",
                                                    borderRadius: "4px",
                                                    textAlign: "center",
                                                    padding: "4px 8px",
                                                }}
                                            >
                                                <Typography color="text.secondary">
                                                    N/A
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    ) : (
                                        "N/A"
                                    )}
                                </TableCell>

                                {/* Looted After Column */}
                                <TableCell align="center">
                                    {pkg.type === "Red Crate" ? (
                                        pkg.lootedAfter ? (
                                            pkg.lootedAfter
                                        ) : (
                                            /** Styled "Unlooted" message */
                                            <Tooltip
                                                title="This weapon was never looted."
                                                arrow
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundImage: `repeating-linear-gradient(
                                        45deg,
                                        rgba(0, 0, 0, 0.1) 0px,
                                        rgba(0, 0, 0, 0.1) 10px,
                                        transparent 10px,
                                        transparent 20px
                                    )`,
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.05)",
                                                        borderRadius: "4px",
                                                        textAlign: "center",
                                                        padding: "4px 8px",
                                                    }}
                                                >
                                                    <Typography color="text.secondary">
                                                        Unlooted
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        )
                                    ) : (
                                        /** Styled N/A for BRDM */
                                        <Tooltip
                                            title="Not applicable for BRDM drops."
                                            arrow
                                        >
                                            <Box
                                                sx={{
                                                    backgroundImage: `repeating-linear-gradient(
                                    45deg,
                                    rgba(0, 0, 0, 0.1) 0px,
                                    rgba(0, 0, 0, 0.1) 10px,
                                    transparent 10px,
                                    transparent 20px
                                )`,
                                                    backgroundColor:
                                                        "rgba(0, 0, 0, 0.05)",
                                                    borderRadius: "4px",
                                                    textAlign: "center",
                                                    padding: "4px 8px",
                                                }}
                                            >
                                                <Typography color="text.secondary">
                                                    N/A
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    )}
                                </TableCell>

                                {/* Looted By Column */}
                                <TableCell align="center">
                                    {pkg.type === "Red Crate" ? (
                                        pkg.lootedBy ? (
                                            pkg.lootedBy
                                        ) : (
                                            /** Styled N/A for "Looted By" when crate is unlooted */
                                            <Tooltip
                                                title="This weapon was never looted."
                                                arrow
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundImage: `repeating-linear-gradient(
                                        45deg,
                                        rgba(0, 0, 0, 0.1) 0px,
                                        rgba(0, 0, 0, 0.1) 10px,
                                        transparent 10px,
                                        transparent 20px
                                    )`,
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.05)",
                                                        borderRadius: "4px",
                                                        textAlign: "center",
                                                        padding: "4px 8px",
                                                    }}
                                                >
                                                    <Typography color="text.secondary">
                                                        N/A
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        )
                                    ) : (
                                        /** Styled N/A for BRDM */
                                        <Tooltip
                                            title="Not applicable for BRDM drops."
                                            arrow
                                        >
                                            <Box
                                                sx={{
                                                    backgroundImage: `repeating-linear-gradient(
                                    45deg,
                                    rgba(0, 0, 0, 0.1) 0px,
                                    rgba(0, 0, 0, 0.1) 10px,
                                    transparent 10px,
                                    transparent 20px
                                )`,
                                                    backgroundColor:
                                                        "rgba(0, 0, 0, 0.05)",
                                                    borderRadius: "4px",
                                                    textAlign: "center",
                                                    padding: "4px 8px",
                                                }}
                                            >
                                                <Typography color="text.secondary">
                                                    N/A
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CarePackageTable;
