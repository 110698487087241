import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Tooltip,
    useTheme,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop"; // Recall Icon
import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";
import SectionHeader from "components/MatchDetails/SectionHeader";

interface RecalledPlayer {
    name: string;
    phases: number[];
    timestamps: string[];
    respawnTimes: string[];
}

interface RecalledPlayersTableProps {
    recalledPlayers: RecalledPlayer[];
    platform: string;
}

const RecalledPlayersTable: React.FC<RecalledPlayersTableProps> = ({
    recalledPlayers,
    platform,
}) => {
    const theme = useTheme();
    return (
        <Box>
            <SectionHeader
                sectionType="recalledPlayers"
                itemCount={recalledPlayers.length}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Respawned in Phase
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Respawn At
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recalledPlayers.map((player) =>
                            player.phases.map((phase, index) => (
                                <TableRow key={`${player.name}-${index}`}>
                                    {/* Show player name & recall icons only on the first row of their recalls */}
                                    {index === 0 ? (
                                        <TableCell
                                            rowSpan={player.phases.length}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 1,
                                                }}
                                            >
                                                {/* Recall Icons (repeated for each recall) */}
                                                <Tooltip title="Recalled">
                                                    <Box>
                                                        {Array(
                                                            player.phases.length
                                                        )
                                                            .fill(null)
                                                            .map((_, i) => (
                                                                <LoopIcon
                                                                    key={i}
                                                                    fontSize="small"
                                                                    sx={{
                                                                        color: "goldenrod",
                                                                        mr: 0.5,
                                                                    }}
                                                                />
                                                            ))}
                                                    </Box>
                                                </Tooltip>

                                                {/* Clickable Player Name */}
                                                <Typography
                                                    component="a"
                                                    href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                                        platform,
                                                        player.name
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: "primary.main",
                                                        "&:hover": {
                                                            textDecoration:
                                                                "underline",
                                                        },
                                                    }}
                                                >
                                                    <strong>
                                                        {player.name}
                                                    </strong>
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    ) : null}

                                    {/* Phase & Respawn Time */}
                                    <TableCell align="center">
                                        {phase}
                                    </TableCell>
                                    <TableCell align="center">
                                        {player.respawnTimes[index]}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default RecalledPlayersTable;
