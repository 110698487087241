import { Match } from "types/match"; // Assuming Match type exists

export const processNormalMatchTelemetry = async (
    match: Match
): Promise<Match> => {
    try {
        const telemetryResponse = await fetch(match.telemetryUrl);

        if (!telemetryResponse.ok) {
            console.error(
                `Failed to fetch telemetry for match ${match.matchId}`
            );
            return match;
        }

        const telemetryData: any = await telemetryResponse.json();

        const playerStats: Record<string, any> = {}; // Store stats per player

        // **Initialize players from LogPlayerCreate**
        telemetryData
            .filter((event: any) => event._T === "LogPlayerCreate")
            .forEach((event: any) => {
                const eventAccountId = event.character.accountId;
                const eventPlayerName = event.character.name;

                playerStats[eventAccountId] = {
                    accountId: eventAccountId,
                    name: eventPlayerName,
                    kills: 0,
                    deaths: 0,
                    damageDealt: 0,
                    damageTaken: 0,
                };
            });

        // **Process LogPlayerKillV2 events**
        telemetryData
            .filter((event: any) => event._T === "LogPlayerKillV2")
            .forEach((event: any) => {
                const killerAccountId = event.killer?.accountId;
                const victimAccountId = event.victim?.accountId;

                // Update kills for killer
                if (killerAccountId && playerStats[killerAccountId]) {
                    playerStats[killerAccountId].kills++;
                }

                // Update deaths for victim
                if (victimAccountId && playerStats[victimAccountId]) {
                    playerStats[victimAccountId].deaths++;
                }
            });

        // **Process LogPlayerTakeDamage events**
        telemetryData
            .filter(
                (event: any) =>
                    event._T === "LogPlayerTakeDamage" && event.damage > 0
            ) // Ignore 0 damage
            .forEach((event: any) => {
                const attackerAccountId = event.attacker?.accountId;
                const victimAccountId = event.victim?.accountId;
                const damage = event.damage;

                // Add damage to the victim
                if (victimAccountId && playerStats[victimAccountId]) {
                    playerStats[victimAccountId].damageTaken += damage;
                }

                // Add damage to the attacker
                if (attackerAccountId && playerStats[attackerAccountId]) {
                    playerStats[attackerAccountId].damageDealt += damage;
                }
            });

        // Convert stats into an array
        const formattedPlayerStats = Object.values(playerStats).map(
            (player) => ({
                ...player,
                damageDealt: Math.floor(player.damageDealt), // Ensure no decimals
                damageTaken: Math.floor(player.damageTaken), // Ensure no decimals
            })
        );

        // Attach computed stats to match result
        return {
            ...match,
            normalMatchStats: formattedPlayerStats,
        };
    } catch (error) {
        console.error(
            `Error processing telemetry for match ${match.matchId}:`,
            error
        );
        return match;
    }
};
