export const processEmergencyPickup = (telemetryData: any[]) => {
    const epickupUsers: Record<
        string,
        { attachPhase: number; detachPhase?: number }
    > = {};

    telemetryData.forEach((event) => {
        if (
            event._T === "LogVehicleRide" &&
            event.vehicle?.vehicleType === "EmergencyPickup"
        ) {
            const playerName = event.character.name;
            const attachPhase = event.common.isGame;

            if (!epickupUsers[playerName]) {
                epickupUsers[playerName] = { attachPhase };
            }
        }

        if (
            event._T === "LogVehicleLeave" &&
            event.vehicle?.vehicleType === "EmergencyPickup"
        ) {
            const playerName = event.character.name;
            const detachPhase = event.common.isGame;

            if (epickupUsers[playerName]) {
                epickupUsers[playerName].detachPhase = detachPhase;
            }
        }
    });

    // Convert to array format
    return Object.entries(epickupUsers).map(([name, data]) => ({
        name,
        attachPhase: data.attachPhase,
        detachPhase: data.detachPhase ?? null, // Default to null if never detached
    }));
};
