export const processRecalls = (
    telemetryData: any[],
    matchCreatedAt: string
) => {
    const recallMap: Record<
        string,
        { phases: number[]; timestamps: string[]; respawnTimes: string[] }
    > = {};

    const matchStartTime = new Date(matchCreatedAt).getTime();

    telemetryData.forEach((event) => {
        if (event._T === "LogPlayerCreate" && event.common.isGame !== 0) {
            const playerName = event.character.name;
            const recallPhase = event.common.isGame;
            const recallTime = new Date(event._D).getTime(); // Convert event timestamp to milliseconds

            if (!recallMap[playerName]) {
                recallMap[playerName] = {
                    phases: [],
                    timestamps: [],
                    respawnTimes: [],
                };
            }

            // Calculate time difference from match start
            const timeDiff = (recallTime - matchStartTime) / 1000; // Convert ms to seconds
            const minutes = Math.floor(timeDiff / 60);
            const seconds = Math.floor(timeDiff % 60);
            const formattedTime = `${minutes}m ${seconds}s`;

            recallMap[playerName].phases.push(recallPhase);
            recallMap[playerName].timestamps.push(event._D);
            recallMap[playerName].respawnTimes.push(formattedTime);
        }
    });

    // Ensure we are returning only recalled players with multiple entries
    const recalledPlayers = Object.entries(recallMap).map(([name, data]) => ({
        name,
        phases: data.phases,
        timestamps: data.timestamps,
        respawnTimes: data.respawnTimes,
    }));

    return recalledPlayers;
};
