import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    CircularProgress,
    TextField,
    Grid,
    Button,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const BotList: React.FC = () => {
    const [botList, setBotList] = useState<string[]>([]);
    const [filteredBots, setFilteredBots] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
    const [groupByLetter, setGroupByLetter] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchBotList = async () => {
            try {
                const response = await fetch(API_ENDPOINTS.BOT_LIST, {
                    method: "GET",
                });

                const data = await response.json();
                const sortedBots = data
                    .map((bot: { botName: string }) => bot.botName)
                    .sort((a: string, b: string) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    );

                setBotList(sortedBots);
                setFilteredBots(sortedBots);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching bot list:", error);
                setLoading(false);
            }
        };

        fetchBotList();
    }, []);

    // Handle Search & Letter Filtering
    useEffect(() => {
        let updatedBots = botList;

        if (searchTerm) {
            updatedBots = updatedBots.filter((bot) =>
                bot.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (selectedLetter) {
            updatedBots = updatedBots.filter((bot) =>
                bot.toUpperCase().startsWith(selectedLetter)
            );
        }

        setFilteredBots(updatedBots);
    }, [searchTerm, selectedLetter, botList]);

    return (
        <Box sx={{ padding: 2, pb: 4, maxWidth: 1200, margin: "auto" }}>
            <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
                Bot List
            </Typography>

            {/* Search Box */}
            <TextField
                label="Search Bots"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Checkbox for Grouping */}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={groupByLetter}
                        onChange={() => setGroupByLetter(!groupByLetter)}
                    />
                }
                label="Group by Initial Letter"
                sx={{ mb: 2 }}
            />

            {/* Alphabet Filter */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: 1,
                    mb: 2,
                }}
            >
                {alphabet.map((letter) => (
                    <Button
                        key={letter}
                        variant={
                            selectedLetter === letter ? "contained" : "outlined"
                        }
                        size="small"
                        onClick={() =>
                            setSelectedLetter(
                                selectedLetter === letter ? null : letter
                            )
                        }
                    >
                        {letter}
                    </Button>
                ))}
            </Box>

            {/* Showing Count */}
            <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                Showing {filteredBots.length} out of {botList.length} bots
            </Typography>

            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {filteredBots.length > 0 ? (
                        groupByLetter ? (
                            // Group by letter
                            Object.entries(
                                filteredBots.reduce(
                                    (acc: Record<string, string[]>, bot) => {
                                        const firstLetter =
                                            bot[0].toUpperCase();
                                        if (!acc[firstLetter])
                                            acc[firstLetter] = [];
                                        acc[firstLetter].push(bot);
                                        return acc;
                                    },
                                    {}
                                )
                            ).map(([letter, bots]) => (
                                <Box key={letter} sx={{ mb: 2 }}>
                                    {/* Letter Header */}
                                    <Typography
                                        variant="h6"
                                        sx={{ mt: 2, mb: 1 }}
                                    >
                                        {letter}
                                    </Typography>

                                    {/* Bots in Grid Layout */}
                                    <Grid container spacing={1}>
                                        {bots.map((bot, index) => (
                                            <Grid
                                                item
                                                xs={4}
                                                sm={3}
                                                md={2}
                                                key={index}
                                            >
                                                <Box
                                                    sx={{
                                                        padding: 1,
                                                        textAlign: "center",
                                                        backgroundColor:
                                                            "background.paper",
                                                        borderRadius: 1,
                                                        boxShadow: 1,
                                                    }}
                                                >
                                                    {bot}
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            ))
                        ) : (
                            // No grouping, just a plain list
                            <Grid container spacing={1}>
                                {filteredBots.map((bot, index) => (
                                    <Grid item xs={4} sm={3} md={2} key={index}>
                                        <Box
                                            sx={{
                                                padding: 1,
                                                textAlign: "center",
                                                backgroundColor:
                                                    "background.paper",
                                                borderRadius: 1,
                                                boxShadow: 1,
                                            }}
                                        >
                                            {bot}
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        )
                    ) : (
                        <Typography
                            variant="body1"
                            sx={{ textAlign: "center", mt: 2 }}
                        >
                            No bots found.
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default BotList;
