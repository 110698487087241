import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReactGA from "react-ga4";
import { formatSeasonName, getSeasonStatus } from "constants/seasonStartDates";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS } from "constants/appWide/colors";
import FunnyLoading from "components/FunnyLoading";
import { maxWidths } from "constants/appWide/maxWidths";
import { useAccountData } from "hooks/pubgApi/useAccountData";
import { useClanInfo } from "hooks/pubgApi/useClanInfo";
import { useSurvivalMastery } from "hooks/pubgApi/useSurvivalMastery";
import PlayerTag from "components/PlayerTag";
import BanStatus from "components/BanStatus";
import { getPlatformLogo } from "utils/getPlatformLogo";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { emphasizedTypographyStyle } from "styles/styles";

// Helper function to format data point names
const formatDataPointName = (key: string): string => {
    return key
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

const getRankIcon = (tier: string, subTier: string): string => {
    if (tier === "Unranked") {
        return require(`../../assets/rankIcons/Unranked.png`);
    }
    return require(`../../assets/rankIcons/${tier}-${subTier}.png`);
};

// Helper function to format game mode names
const formatGameModeName = (gameMode: string): string => {
    return gameMode.includes("-fpp")
        ? gameMode.replace("-fpp", " FPP").toUpperCase()
        : `${gameMode.toUpperCase()} TPP`;
};

const filterSeasons = (seasons: any[], platform: string): any[] => {
    return seasons.filter((season) => {
        const isConsoleSeason = season.id.includes("console");
        const is2017Season = season.id.includes("2017");
        const isLegacySeason =
            !season.id.includes("pc") && !season.id.includes("console");

        return (
            !(isConsoleSeason && platform === "steam") &&
            !is2017Season &&
            !isLegacySeason
        );
    });
};

const SeasonStats: React.FC = () => {
    // Navigate and Location
    const navigate = useNavigate();
    const theme = useTheme();

    // Player Name and Platform
    const { platform = "steam", playerName = "Unknown Player" } = useParams<{
        platform?: string;
        playerName?: string;
    }>();

    const { accountId, clanId, banType, accountLoading, accountError } =
        useAccountData(platform, playerName);
    // Using Banner Hooks
    const { clanInfo, clanLoading, clanError } = useClanInfo(clanId, platform);

    const { survivalMastery, survivalLoading, survivalError } =
        useSurvivalMastery(accountId, platform);

    // Season List
    const [seasons, setSeasons] = useState<any[]>([]);

    // Current Season and selected season
    const [currentSeasonId, setCurrentSeasonId] = useState<string | null>(null);
    const [selectedSeasonId, setSelectedSeasonId] = useState<string | null>(
        useParams<{ seasonId?: string }>().seasonId || null
    );
    // Season Stats and Selections
    const [seasonStats, setSeasonStats] = useState<any | null>(null);
    const [selectedStatType, setSelectedStatType] = useState<
        "normal" | "ranked"
    >("normal");
    const [selectedGameMode, setSelectedGameMode] =
        useState<string>("squad-fpp");
    const [availableGameModes, setAvailableGameModes] = useState<string[]>([]);

    // Loading and error
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const statsForGameMode = seasonStats?.[selectedGameMode];

    // Handle rank-specific data only if `selectedStatType` is "ranked"
    const currentTier =
        selectedStatType === "ranked" ? statsForGameMode?.currentTier : null;
    const bestTier =
        selectedStatType === "ranked" ? statsForGameMode?.bestTier : null;

    // Filtered stats for the table (exclude rank-related stats in ranked mode)
    const filteredStats =
        statsForGameMode && selectedStatType === "ranked"
            ? Object.entries(statsForGameMode).filter(
                  ([key]) =>
                      ![
                          "currentTier",
                          "currentRankPoint",
                          "bestTier",
                          "bestRankPoint",
                      ].includes(key)
              )
            : statsForGameMode
            ? Object.entries(statsForGameMode)
            : [];

    useEffect(() => {
        // Update the URL when selectedSeasonId changes
        if (selectedSeasonId) {
            navigate(
                `/player-stats/${platform}/${playerName}/season/${selectedSeasonId}`,
                { replace: true }
            );
        }
    }, [selectedSeasonId, navigate, platform, playerName]);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/season",
            title: "Season Stats Page",
        });
    }, []);

    // Step 2: Fetch seasons
    useEffect(() => {
        const fetchSeasons = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await fetch(
                    API_ENDPOINTS.SEASON_LIST(platform)
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch seasons");
                }
                const data = await response.json();
                const filteredSeasons = filterSeasons(data.data, platform);
                setSeasons(filteredSeasons);

                const currentSeason = filteredSeasons.find(
                    (season: any) => season.attributes.isCurrentSeason
                );

                // Set the current season if no `season` is found in the URL
                if (!selectedSeasonId && currentSeason) {
                    setCurrentSeasonId(currentSeason.id);
                    setSelectedSeasonId(currentSeason.id);
                } else {
                    setCurrentSeasonId(currentSeason?.id || null);
                }
            } catch (err) {
                setError((err as Error).message);
            } finally {
                setLoading(false);
            }
        };

        fetchSeasons();
    }, [platform, selectedSeasonId]);

    // Step 3: Fetch season stats
    useEffect(() => {
        const fetchSeasonStats = async () => {
            if (!selectedSeasonId || !accountId || !selectedStatType) return;

            // Check if ranked stats are supported for this season
            const isRankedAvailable =
                platform !== "steam" ||
                selectedSeasonId >= "division.bro.official.pc-2018-07";

            // Prevent fetch if Ranked is selected but not available
            if (selectedStatType === "ranked" && !isRankedAvailable) {
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const url = API_ENDPOINTS.SEASON_STATS(
                    platform,
                    accountId,
                    selectedSeasonId,
                    selectedStatType
                );

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Failed to fetch season stats");
                }

                const data = await response.json();
                if (selectedStatType === "ranked") {
                    setSeasonStats(data.data.attributes.rankedGameModeStats);
                    setAvailableGameModes(
                        Object.keys(data.data.attributes.rankedGameModeStats)
                    );
                } else {
                    setSeasonStats(data.data.attributes.gameModeStats);
                    setAvailableGameModes(
                        Object.keys(data.data.attributes.gameModeStats)
                    );
                }
            } catch (err) {
                setError((err as Error).message);
            } finally {
                setLoading(false);
            }
        };

        fetchSeasonStats();
    }, [selectedSeasonId, platform, accountId, selectedStatType]);

    useEffect(() => {
        if (selectedSeasonId) {
            const isRankedAvailable =
                platform !== "steam" ||
                selectedSeasonId >= "division.bro.official.pc-2018-07";

            // Automatically switch to normal if Ranked is unavailable
            if (!isRankedAvailable && selectedStatType === "ranked") {
                setSelectedStatType("normal");
            }
        }
    }, [selectedSeasonId, platform, selectedStatType]);

    if (
        loading ||
        accountLoading ||
        clanLoading ||
        survivalLoading ||
        !survivalMastery
    ) {
        return <FunnyLoading />;
    }

    if (error) {
        return (
            <Typography color="error" variant="body1">
                {error}
            </Typography>
        );
    }

    if (!accountId || !selectedSeasonId) {
        return (
            <Typography color="error" variant="body1">
                Missing account or season information. Please try again.
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l,
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                gap: 3,
            }}
        >
            {/* Left Section: Page Title and Banner */}
            <Box
                sx={{
                    display: "flex",
                    width: "100%", // Full width for the banner
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "center",
                    gap: 2, // Gap between banner and favorite icon
                    margin: "0 auto",
                    border: "2px solid #444",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    padding: "16px",
                    backgroundColor: "#2e2e2e",
                    marginTop: "16px",
                    marginBottom: "24px",
                    alignContent: "center",
                }}
            >
                {/* Banner Section */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        <img
                            src={getPlatformLogo(platform)}
                            alt={`${platform} logo`}
                            style={{ width: 26, height: 26 }}
                        />
                        <Typography
                            variant="h6"
                            marginRight={1}
                            sx={emphasizedTypographyStyle(theme)}
                        >
                            Season Stats
                        </Typography>
                    </Box>
                    {/* Player Tag */}
                    <PlayerTag
                        playerName={playerName!}
                        survivalTier={survivalMastery?.tier}
                        survivalLevel={survivalMastery?.level}
                        clanInfo={clanInfo}
                    />
                </Box>

                <BanStatus banType={banType} />
            </Box>

            {/* Dropdown for Seasons */}
            <FormControl fullWidth>
                <InputLabel>Season</InputLabel>
                <Select
                    value={selectedSeasonId || ""}
                    onChange={(e) => setSelectedSeasonId(e.target.value)}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: "400px", // Limit height to 200px
                                overflowY: "auto", // Enable vertical scrolling
                            },
                        },
                    }}
                >
                    {seasons.map((season) => (
                        <MenuItem key={season.id} value={season.id}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body1">
                                    {formatSeasonName(season.id)}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{ fontStyle: "italic" }}
                                >
                                    {getSeasonStatus(
                                        season,
                                        currentSeasonId || ""
                                    )}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                    gap: 2,
                    alignItems: "flex-start",
                }}
            >
                {/* Buttons for Normal and Ranked */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        padding: 2,
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        width: { xs: "100%", md: "20%", sm: "20%" },
                        justifyContent: {
                            xs: "center",
                            md: "left",
                            sm: "left",
                        }, // Center the buttons inside the box
                    }}
                >
                    <Button
                        variant={
                            selectedStatType === "normal"
                                ? "contained"
                                : "outlined"
                        }
                        onClick={() => setSelectedStatType("normal")}
                        sx={{
                            borderColor: COLORS.orange,
                            color:
                                selectedStatType === "normal"
                                    ? COLORS.black
                                    : COLORS.orange,
                            backgroundColor:
                                selectedStatType === "normal"
                                    ? COLORS.orange
                                    : "",
                        }}
                    >
                        Normal
                    </Button>
                    <Button
                        variant={
                            selectedStatType === "ranked"
                                ? "contained"
                                : "outlined"
                        }
                        onClick={() => setSelectedStatType("ranked")}
                        disabled={
                            platform === "steam" &&
                            selectedSeasonId <
                                "division.bro.official.pc-2018-07"
                        }
                        sx={{
                            borderColor: COLORS.orange,
                            color:
                                selectedStatType === "ranked"
                                    ? COLORS.black
                                    : COLORS.orange,
                            backgroundColor:
                                selectedStatType === "ranked"
                                    ? COLORS.orange
                                    : "",
                        }}
                    >
                        Ranked
                    </Button>
                </Box>

                {/* Game Mode Buttons */}
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "1fr 1fr",
                            md: "repeat(6, 1fr)",
                        }, // 2 per row on mobile, 4 per row on desktop
                        gap: 2,
                        padding: 2,
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        width: "100%",
                    }}
                >
                    {[
                        "solo",
                        "solo-fpp",
                        "duo",
                        "duo-fpp",
                        "squad",
                        "squad-fpp",
                    ].map((gameMode) => (
                        <Button
                            key={gameMode}
                            variant={
                                selectedGameMode === gameMode
                                    ? "contained"
                                    : "outlined"
                            }
                            onClick={() => setSelectedGameMode(gameMode)}
                            disabled={!availableGameModes.includes(gameMode)}
                            sx={{
                                borderColor: COLORS.orange,
                                color:
                                    selectedGameMode === gameMode
                                        ? COLORS.black
                                        : COLORS.orange,
                                backgroundColor:
                                    selectedGameMode === gameMode
                                        ? COLORS.orange
                                        : "",
                            }}
                        >
                            {formatGameModeName(gameMode)}
                        </Button>
                    ))}
                </Box>
            </Box>

            {/* Rank Cards for Ranked Stats */}
            {selectedStatType === "ranked" && currentTier && bestTier && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row on desktop
                        gap: 4,
                        marginBottom: 2,
                    }}
                >
                    {/* Latest Rank */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 3,
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            width: { xs: "100%", md: "50%" }, // Full width on mobile, half on desktop
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>
                            Latest Rank
                        </Typography>
                        <img
                            src={getRankIcon(
                                currentTier.tier,
                                currentTier.subTier
                            )}
                            alt={`Rank Icon for ${currentTier.tier} ${currentTier.subTier}`}
                            style={{
                                width: "330px", // Larger icon size
                                height: "280px",
                                marginBottom: "16px",
                            }}
                        />
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", marginBottom: 1 }}
                        >
                            {currentTier.tier}{" "}
                            {currentTier.tier !== "Master"
                                ? currentTier.subTier
                                : ""}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {statsForGameMode.currentRankPoint} RP
                        </Typography>
                    </Box>

                    {/* Highest Rank */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 3,
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            width: { xs: "100%", md: "50%" }, // Full width on mobile, half on desktop
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>
                            Highest Rank
                        </Typography>
                        <img
                            src={getRankIcon(bestTier.tier, bestTier.subTier)}
                            alt={`Rank Icon for ${bestTier.tier} ${bestTier.subTier}`}
                            style={{
                                width: "330px", // Larger icon size
                                height: "280px",
                                marginBottom: "16px",
                            }}
                        />
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", marginBottom: 1 }}
                        >
                            {bestTier.tier}{" "}
                            {bestTier.tier !== "Master" ? bestTier.subTier : ""}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {statsForGameMode.bestRankPoint} RP
                        </Typography>
                    </Box>
                </Box>
            )}

            {/* Table for Season Stats */}
            {filteredStats.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {filteredStats.map(([statKey, statValue]) => (
                                <TableRow key={statKey}>
                                    <TableCell>
                                        {formatDataPointName(statKey)}
                                    </TableCell>
                                    <TableCell>
                                        {typeof statValue === "number"
                                            ? Math.round(
                                                  statValue
                                              ).toLocaleString()
                                            : String(statValue)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        textAlign: "center",
                        gap: 2,
                        padding: 3,
                    }}
                >
                    <SearchIcon
                        sx={{
                            fontSize: 60,
                            color: "text.secondary",
                        }}
                    />
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: "bold",
                            color: "text.primary",
                        }}
                    >
                        No Matches
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "text.secondary",
                            textAlign: "center", // Center-align the text for better readability
                            fontWeight: 400, // Slightly adjust font weight for a cleaner look
                            marginY: 2, // Add vertical spacing
                        }}
                    >
                        <strong>{playerName}</strong> has no{" "}
                        {selectedStatType.charAt(0).toUpperCase() +
                            selectedStatType.slice(1)}{" "}
                        stats for{" "}
                        <strong>{formatSeasonName(selectedSeasonId)}</strong>.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default SeasonStats;
