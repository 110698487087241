import { Theme } from "@mui/material/styles";

export const getSixteenPredefinedColors = (theme: Theme) => [
    theme.palette.secondary.main, // Green (Assumed)
    theme.palette.custom?.accentColor2 || "#2196F3", // Light Blue (Fallback)

    // Carefully selected unique colors ensuring contrast
    "#FF0000", // Red
    "#800080", // Purple
    "#FFD700", // Gold
    "#008080", // Dark Teal
    "#A52A2A", // Brown
    "#FF69B4", // Hot Pink
    "#4B0082", // Indigo
    "#00FF7F", // Spring Green
    "#DC143C", // Crimson
    "#8B4513", // Saddle Brown
    "#4682B4", // Steel Blue
    "#808000", // Olive
    "#C71585", // Medium Violet Red
];
