import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import steamLogo from "assets/platformLogos/steam.png";
import { patches } from "constants/patches";

interface PlayerCountComponentProps {
    playerCount: number | null;
}

const PlayerCountComponent: React.FC<PlayerCountComponentProps> = ({
    playerCount,
}) => {
    if (!playerCount) return null;

    const isMaintenance = playerCount < 50000;

    return isMaintenance ? (
        // Maintenance Message Path
        <Grid container spacing={2} alignItems="center">
            {/* Player Count Section */}
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                    }}
                >
                    <a
                        href="https://steamcommunity.com/app/578080"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={steamLogo}
                            alt="Steam Logo"
                            style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "contain",
                                cursor: "pointer",
                            }}
                        />
                    </a>
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: "bold",
                                color: "#c7d5e0",
                            }}
                        >
                            {Number(playerCount).toLocaleString()}
                        </Typography>
                        <Typography variant="body1" color="inherit">
                            Players In-Game Right Now
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            {/* Maintenance Message Section */}
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        backgroundColor: "#2a3747",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="body2"
                        color="inherit"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                        }}
                    >
                        <WarningAmberIcon
                            sx={{
                                color: "#f57c00", // Warning orange color
                                fontSize: "20px",
                            }}
                        />
                        {/* The game is likely under maintenance as the player count
                        is under 50k. */}
                        PUBG Servers are under maintenance right now.
                    </Typography>
                    <Typography variant="body2" color="inherit" mt={1}>
                        In the meantime, check out the latest patch notes on the{" "}
                        <a
                            href={patches[0].link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                color: "#66c0f4",
                                textDecoration: "underline",
                            }}
                        >
                            PUBG website
                        </a>
                        .
                    </Typography>{" "}
                    <Divider sx={{ paddingTop: 2 }} />
                    {/* <Typography variant="body2" color="inherit" mt={2}>
                        Want to be notified when the servers are back online?{" "}
                        <a
                            href=""
                            style={{
                                color: "#66c0f4",
                                textDecoration: "underline",
                                fontWeight: "bold",
                            }}
                        >
                            Click here to subscribe to email notifications
                        </a>
                        .
                    </Typography> */}
                    <Typography variant="body2" color="inherit" mt={2}>
                        And don't forget to delete any new game intro videos
                        from{" "}
                        <Box
                            component="span"
                            sx={{
                                display: "inline-block",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                                padding: "2px 6px",
                                borderRadius: "4px",
                                fontFamily: "monospace",
                                wordBreak: "break-word", // Ensures wrapping
                                overflowWrap: "break-word",
                            }}
                        >
                            steamapps\common\PUBG\TslGame\Content\Movies
                        </Box>{" "}
                        after the patch is done to enjoy faster game boot times!
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    ) : (
        // Original Player Count Design
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
            }}
        >
            <a
                href="https://steamcommunity.com/app/578080"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    display: "inline-flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={steamLogo}
                    alt="Steam Logo"
                    style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "contain",
                        cursor: "pointer",
                    }}
                />
            </a>
            <Box>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "bold",
                        color: "#c7d5e0",
                    }}
                >
                    {Number(playerCount).toLocaleString()}
                </Typography>
                <Typography variant="body1" color="inherit">
                    Players In-Game Right Now
                </Typography>
            </Box>
        </Box>
    );
};

export default PlayerCountComponent;
