import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { maxWidths } from "constants/appWide/maxWidths";

export const TaegoSecretRooms: React.FC = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/maps"); // Navigate back to the maps landing page
    };

    return (
        <Box
            sx={{
                // maxWidth: maxWidths.l,
                margin: "0 auto",
                marginTop: 2,
                marginBottom: 4,
            }}
        >
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.5 }}
                >
                    {/* Buttons Section */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            marginBottom: "16px",
                        }}
                    >
                        <Button
                            onClick={handleBack}
                            variant="contained"
                            color="primary"
                        >
                            Back to Map Categories
                        </Button>
                    </Box>

                    {/* Page Title */}
                    <Typography variant="h4" align="center" gutterBottom>
                        Taego Secret Rooms
                    </Typography>

                    {/* Page Description */}
                    <Typography variant="body1" align="center" gutterBottom>
                        Unlock the hidden rooms of Taego and uncover amazing
                        loot!
                    </Typography>

                    {/* Image Section */}
                    <Box
                        sx={{
                            width: { xs: "98%", sm: "80%", md: "60%" },
                            overflow: "hidden",
                            margin: "0 auto",
                            border: "1px solid black",
                            position: "relative",
                        }}
                    >
                        <Box
                            component="img"
                            src="https://steamuserimages-a.akamaihd.net/ugc/2039609430495408396/3E9DCDC6D818790E2E97BA6C31B3A478F234339B/"
                            alt="Taego Secret Rooms"
                            sx={{
                                width: "calc(100% + 250px)", // Increase width by 250px
                                marginLeft: "-125px", // Crop 125px from each side
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>
                </motion.div>
            </AnimatePresence>
        </Box>
    );
};
