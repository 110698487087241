/**
 * Formats the given time in minutes into hours and minutes.
 * @param minutes - The total time in minutes.
 * @returns A formatted string in the form "Xh Ym".
 */
export const formatMinutesToHours = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${hours > 0 ? `${hours}h ` : ""}`;
};
