import React, { useState } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Typography,
    Tooltip,
} from "@mui/material";

// MUI Icons
import SmartToyIcon from "@mui/icons-material/SmartToy"; // Bot Icon
import LoopIcon from "@mui/icons-material/Loop"; // Recall Icon
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"; // Highest Damage Icon
import SportsMmaIcon from "@mui/icons-material/SportsMma"; // Most Kills Icon
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Active Roster Icon
////

import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";
import { useTheme } from "@mui/material/styles";

// Placement Icons
import { NormalMatchStats } from "pages/playerStats/MatchDetails";
import { Participant } from "utils/matchData/rosterStats";
import PlacementIcon from "utils/PlacementIcon";
import { COLORS } from "constants/appWide/colors";
import { getScrollbarStyles } from "styles/styles";
import SectionHeader from "components/MatchDetails/SectionHeader";

export interface ProcessedParticipant extends Participant {
    deaths: number;
    kdRatio: number;
    damageTaken: number;
}

export interface RecalledPlayer {
    name: string;
    phases: number[];
}

interface ParticipantsTableProps {
    playerName: string;
    participants: Participant[];
    botList: { name: string }[];
    recalledPlayers: RecalledPlayer[];
    platform: string;
    normalMatchStats: NormalMatchStats[];
    isCasualOrIbr: boolean;
}

const ParticipantsTable: React.FC<ParticipantsTableProps> = ({
    playerName,
    participants,
    botList,
    recalledPlayers,
    platform,
    normalMatchStats,
    isCasualOrIbr,
}) => {
    const theme = useTheme();

    // Default Sorting: Placement Descending
    const [sortKey, setSortKey] = useState<
        keyof ProcessedParticipant | "deaths" | "kdRatio" | "damageTaken"
    >("winPlace");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

    // Extract bot names (Optimized outside loop)
    const botNames = new Set(botList.map((bot) => bot.name));

    // Process Participants with Derived Stats
    const processedParticipants: ProcessedParticipant[] = participants.map(
        (participant) => {
            const playerStats = normalMatchStats.find(
                (stats) => stats.name === participant.name
            );

            const deaths = playerStats?.deaths ?? 0;
            const kdRatio = playerStats
                ? playerStats.kills / Math.max(deaths, 1)
                : 0;
            const damageTaken = playerStats?.damageTaken ?? 0;

            return {
                ...participant,
                deaths,
                kdRatio,
                damageTaken,
            };
        }
    );

    // Find Highest Kill and Damage Leaders
    const maxKills = Math.max(...participants.map((p) => p.kills), 0);
    const maxDamage = Math.max(...participants.map((p) => p.damageDealt), 0);

    // Sorting Function
    const sortedParticipants = [...processedParticipants].sort((a, b) => {
        let aValue = a[sortKey as keyof ProcessedParticipant];
        let bValue = b[sortKey as keyof ProcessedParticipant];

        // Convert to numbers explicitly for proper sorting
        if (
            sortKey === "kdRatio" ||
            sortKey === "deaths" ||
            sortKey === "damageTaken"
        ) {
            aValue = Number(aValue);
            bValue = Number(bValue);
        } else {
            aValue = aValue as number;
            bValue = bValue as number;
        }

        // **WinPlace Sorting with Nested Sorting**
        if (sortKey === "winPlace") {
            if (a.winPlace === b.winPlace) {
                if (a.kills !== b.kills) {
                    return b.kills - a.kills; // Higher kills should be on top
                }
                return b.damageDealt - a.damageDealt; // Higher damage should be on top
            }
            return sortOrder === "asc"
                ? a.winPlace - b.winPlace // Lower placement number (1st) should be on top in ascending order
                : b.winPlace - a.winPlace; // Higher placement number (last) should be on top in descending order
        }

        // **Kills Sorting with Nested Sorting**
        if (sortKey === "kills") {
            if (a.kills === b.kills) {
                return sortOrder === "asc"
                    ? a.damageDealt - b.damageDealt
                    : b.damageDealt - a.damageDealt;
            }
            return sortOrder === "asc" ? a.kills - b.kills : b.kills - a.kills;
        }

        // **Default Sorting**
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
    });

    // Change Sorting Order (Toggle Asc/Desc)
    const handleSort = (key: keyof ProcessedParticipant) => {
        setSortOrder(sortKey === key && sortOrder === "asc" ? "desc" : "asc");
        setSortKey(key);
    };

    return (
        <Box>
            <SectionHeader
                sectionType="playersLeaderboard"
                itemCount={participants.length}
            />
            <TableContainer
                component={Paper}
                sx={{
                    maxHeight: 800,
                    overflowY: "auto",
                    [theme.breakpoints.up("md")]: getScrollbarStyles(),
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    width: "50px",
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                                align="center"
                            >
                                <TableSortLabel
                                    active={sortKey === "winPlace"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("winPlace")}
                                >
                                    Place
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Player
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "kills"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("kills")}
                                >
                                    Kills
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "deaths"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("deaths")}
                                >
                                    Deaths
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "kdRatio"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("kdRatio")}
                                >
                                    K/D
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "damageDealt"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("damageDealt")}
                                >
                                    Damage Dealt
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "damageTaken"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("damageTaken")}
                                >
                                    Damage Taken
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                <TableSortLabel
                                    active={sortKey === "timeSurvived"}
                                    direction={sortOrder}
                                    onClick={() => handleSort("timeSurvived")}
                                >
                                    Survival Time
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedParticipants.map((participant, index) => {
                            const isBot = botNames.has(participant.name);
                            // Find if anyone on this participant's team is a bot
                            const isBotTeam = sortedParticipants.some(
                                (p) =>
                                    p.winPlace === participant.winPlace &&
                                    botNames.has(p.name)
                            );
                            const recallData = recalledPlayers.find(
                                (p) => p.name === participant.name
                            );

                            return (
                                <TableRow
                                    key={participant.participantId}
                                    sx={{ position: "relative" }}
                                >
                                    {/* Placement Icons */}
                                    <TableCell align="left">
                                        <Typography
                                            sx={{
                                                position: "absolute",
                                                top: "0px",
                                                left: "4px",
                                                fontSize: "12px",
                                                color: COLORS.orange,
                                            }}
                                        >
                                            {index + 1}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexWrap: "nowrap",
                                            }}
                                        >
                                            {participant.winPlace <= 3 ? (
                                                <PlacementIcon
                                                    rank={participant.winPlace}
                                                    isCasualOrIbr={
                                                        isCasualOrIbr
                                                    }
                                                />
                                            ) : (
                                                // Boxed Placement Number for Others
                                                <Box
                                                    sx={{
                                                        display: "inline-block",
                                                        minWidth: 26,
                                                        height: 26,
                                                        borderRadius: 1,
                                                        border: "2px solid",
                                                        fontWeight: "bold",
                                                        textAlign: "center",
                                                        lineHeight: "26px",
                                                        backgroundColor:
                                                            theme.palette
                                                                .background
                                                                .default,
                                                        borderColor: isBotTeam
                                                            ? theme.palette
                                                                  .secondary
                                                                  .main
                                                            : theme.palette
                                                                  .primary.main, // ✅ Use secondary color if it's a bot team
                                                        color: isBotTeam
                                                            ? theme.palette
                                                                  .secondary
                                                                  .main
                                                            : theme.palette
                                                                  .primary.main,
                                                    }}
                                                >
                                                    {participant.winPlace}
                                                </Box>
                                            )}

                                            {/* Most Kills Icon */}
                                            {participant.kills === maxKills &&
                                                participant.kills > 0 && (
                                                    <Tooltip title="Most Kills">
                                                        <SportsMmaIcon
                                                            fontSize="small"
                                                            sx={{
                                                                color: "red",
                                                                ml: 1,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}

                                            {/* Highest Damage Icon */}
                                            {participant.damageDealt ===
                                                maxDamage &&
                                                participant.damageDealt > 0 && (
                                                    <Tooltip title="Highest Damage">
                                                        <LocalFireDepartmentIcon
                                                            fontSize="small"
                                                            sx={{
                                                                color: "orange",
                                                                ml: 1,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                        </Box>
                                    </TableCell>

                                    {/* Player Name with Icons */}
                                    <TableCell>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {/* Bot Icon */}
                                            {isBot && (
                                                <Tooltip title="Bot Player">
                                                    <SmartToyIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: theme.palette
                                                                .secondary.main,
                                                            mr: 1,
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}

                                            {/* Active Player Icon */}
                                            {participant.name ===
                                                playerName && (
                                                <Tooltip title="Me">
                                                    <ArrowForwardIcon
                                                        sx={{
                                                            color: theme.palette
                                                                .primary.main,
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}

                                            {/* Recall Icons (Only in Name Column) */}
                                            {recallData && (
                                                <Tooltip
                                                    title={`Recalled ${
                                                        recallData.phases.length
                                                    } time${
                                                        recallData.phases
                                                            .length > 1
                                                            ? "s"
                                                            : ""
                                                    }`}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        {Array(
                                                            recallData.phases
                                                                .length
                                                        )
                                                            .fill(null)
                                                            .map((_, i) => (
                                                                <LoopIcon
                                                                    key={i}
                                                                    fontSize="small"
                                                                    sx={{
                                                                        color: "goldenrod",
                                                                        mr: 0.5,
                                                                    }}
                                                                />
                                                            ))}
                                                    </Box>
                                                </Tooltip>
                                            )}

                                            {/* Clickable Player Name (Not for bots) */}
                                            {!isBot ? (
                                                <Typography
                                                    component="a"
                                                    href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                                        platform,
                                                        participant.name
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        textDecoration: "none",
                                                        color: theme.palette
                                                            .text.primary,
                                                        "&:hover": {
                                                            textDecoration:
                                                                "underline",
                                                        },
                                                    }}
                                                >
                                                    {participant.name}
                                                </Typography>
                                            ) : (
                                                <Typography>
                                                    {participant.name}
                                                </Typography>
                                            )}
                                        </Box>
                                    </TableCell>

                                    <TableCell align="center">
                                        {participant.kills}
                                    </TableCell>

                                    {/* Deaths */}
                                    <TableCell align="center">
                                        {participant.deaths}
                                    </TableCell>

                                    {/* K/D Ratio */}
                                    <TableCell align="center">
                                        {participant.kdRatio}
                                    </TableCell>

                                    <TableCell align="center">
                                        {Math.round(
                                            participant.damageDealt
                                        ).toLocaleString()}
                                    </TableCell>

                                    {/* Damage Taken */}
                                    <TableCell align="center">
                                        {Math.round(
                                            participant.damageTaken
                                        ).toLocaleString()}
                                    </TableCell>

                                    <TableCell align="center">
                                        {Math.floor(
                                            participant.timeSurvived / 60
                                        )}
                                        m {participant.timeSurvived % 60}s
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ParticipantsTable;
