import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Tooltip,
    useTheme,
} from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff"; // Emergency Pickup Icon
import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";

interface EmergencyPickupPlayer {
    name: string;
    attachPhase: number;
    detachPhase: number | null; // Null if they never detached
}

interface EmergencyPickupTableProps {
    emergencyPickupUsers: EmergencyPickupPlayer[];
    platform: string;
}

const EmergencyPickupTable: React.FC<EmergencyPickupTableProps> = ({
    emergencyPickupUsers,
    platform,
}) => {
    const theme = useTheme();

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                    backgroundColor: theme.palette.background.paper,
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                }}
            >
                {/* Left Side: Title with Icon */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <FlightTakeoffIcon
                        fontSize="medium"
                        sx={{ color: theme.palette.primary.main }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Emergency Pickup Uses
                    </Typography>
                </Box>

                {/* Right Side: Number of Players Who Used Emergency Pickup */}
                <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "text.secondary" }}
                >
                    {emergencyPickupUsers.length}{" "}
                    {emergencyPickupUsers.length === 1 ? "player" : "players"}
                </Typography>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Attached in Phase
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Detached in Phase
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emergencyPickupUsers.map((player) => (
                            <TableRow key={player.name}>
                                {/* Player Name with Clickable Profile Link */}
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                        }}
                                    >
                                        {/* Airplane Icon before the Player Name */}
                                        <FlightTakeoffIcon
                                            fontSize="small"
                                            sx={{
                                                color: theme.palette.primary
                                                    .main,
                                            }}
                                        />
                                        {/* Clickable Player Name */}
                                        <Typography
                                            component="a"
                                            href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                                platform,
                                                player.name
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                textDecoration: "none",
                                                color: "primary.main",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                        >
                                            <strong>{player.name}</strong>
                                        </Typography>
                                    </Box>
                                </TableCell>

                                {/* Attach Phase */}
                                <TableCell align="center">
                                    {player.attachPhase}
                                </TableCell>

                                {/* Detach Phase (Show "-" if never detached) */}
                                <TableCell align="center">
                                    {player.detachPhase !== null
                                        ? player.detachPhase
                                        : "-"}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default EmergencyPickupTable;
