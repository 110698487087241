import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Tooltip,
    useTheme,
} from "@mui/material";
import HealingIcon from "@mui/icons-material/Healing"; // Self-revive Icon
import { NAVIGATION_ROUTES } from "constants/NAVIGATION_ROUTES";
import SectionHeader from "components/MatchDetails/SectionHeader";

interface SelfRevivedPlayer {
    name: string;
    phase: number;
    reviveTime: string;
}

interface SelfRevivesTableProps {
    selfRevives: SelfRevivedPlayer[];
    platform: string;
}

const SelfRevivesTable: React.FC<SelfRevivesTableProps> = ({
    selfRevives,
    platform,
}) => {
    const theme = useTheme();

    return (
        <Box>
            <SectionHeader
                sectionType="selfRevived"
                itemCount={selfRevives.length}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Used In Phase
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    fontWeight: "bold",
                                }}
                            >
                                Used At
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selfRevives.map((player, index) => (
                            <TableRow key={`${player.name}-${index}`}>
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                        }}
                                    >
                                        {/* Self Revive Icon */}
                                        <Tooltip title="Self Revived">
                                            <HealingIcon
                                                fontSize="small"
                                                sx={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                            />
                                        </Tooltip>

                                        {/* Clickable Player Name */}
                                        <Typography
                                            component="a"
                                            href={NAVIGATION_ROUTES.PLAYER_PROFILE(
                                                platform,
                                                player.name
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                textDecoration: "none",
                                                color: "primary.main",
                                                "&:hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                        >
                                            <strong>{player.name}</strong>
                                        </Typography>
                                    </Box>
                                </TableCell>

                                <TableCell align="center">
                                    {player.phase}
                                </TableCell>
                                <TableCell align="center">
                                    {player.reviveTime}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SelfRevivesTable;
