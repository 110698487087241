import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Slider,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    useTheme,
    useMediaQuery,
    Divider,
} from "@mui/material";
import { ChromePicker } from "react-color";
import { COLORS } from "constants/appWide/colors";
import { useLandingPage } from "components/LandingPageProvider";
import { useSavedPlayers } from "hooks/useSavedPlayers";
import { useAuth } from "AuthContext";
import { SavedPlayersDropdown } from "components/SavedPlayersDropdown";
import QuickActionButton from "components/navbar/QuickActionButton";
import { MetaLogo } from "components/navbar/MetaLogo";
import { useShowMapImages } from "hooks/useShowMapImages";
import { useShowWeaponCategories } from "hooks/useShowWeaponCategories";

export const Preferences: React.FC<{
    setThemePreferences: (themePreferences: {
        primaryColor: string;
        accentColor1: string;
        accentColor2: string;
        backgroundColor: string;
        fontSize: number;
        fontFamily: string;
    }) => void;
}> = ({ setThemePreferences }) => {
    const [primaryColor, setPrimaryColor] = useState(COLORS.orange);
    const [accentColor1, setAccentColor1] = useState(COLORS.green);
    const [accentColor2, setAccentColor2] = useState(COLORS.blue);
    const [backgroundColor, setBackgroundColor] = useState("#222222");
    const [fontSize, setFontSize] = useState(14);
    const [fontFamily, setFontFamily] = useState("'Arial', sans-serif");

    const { isAuthenticated } = useAuth();

    const { showMapImages, toggleShowMapImages } = useShowMapImages();

    const { landingPage, setLandingPage } = useLandingPage();

    const handleLandingPageChange = (newLandingPage: string) => {
        setLandingPage(newLandingPage);
    };

    const [favoriteGameMode, setFavoriteGameMode] = useState<string>("");

    // Update theme preferences in real time
    useEffect(() => {
        setThemePreferences({
            primaryColor,
            accentColor1,
            accentColor2,
            backgroundColor,
            fontSize,
            fontFamily,
        });

        localStorage.setItem(
            "themePreferences",
            JSON.stringify({
                primaryColor,
                accentColor1,
                accentColor2,
                backgroundColor,
                fontSize,
                fontFamily,
            })
        );
    }, [
        primaryColor,
        accentColor1,
        accentColor2,
        backgroundColor,
        fontSize,
        fontFamily,
    ]);

    const { savedPlayers, loadingSavedPlayers } =
        useSavedPlayers(isAuthenticated);
    const [quickActionPlayer, setQuickActionPlayer] = useState<{
        playerName: string;
        platform: string;
    } | null>(null);

    const { showWeaponCategories, toggleShowWeaponCategories } =
        useShowWeaponCategories();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const savedQuickActionPlayer =
            localStorage.getItem("quickActionPlayer");
        const savedGameMode = localStorage.getItem("favoriteGameMode");

        if (savedQuickActionPlayer) {
            try {
                const parsedPlayer = JSON.parse(savedQuickActionPlayer);
                setQuickActionPlayer(parsedPlayer);
            } catch (error) {
                console.error("Error parsing quick action player:", error);
            }
        }

        if (savedGameMode) {
            setFavoriteGameMode(savedGameMode);
        }
    }, []);

    // This has to be below the above useEffect to prevent the event from being triggered before the state is set
    useEffect(() => {
        localStorage.setItem("favoriteGameMode", favoriteGameMode);
    }, [favoriteGameMode]);

    const handleQuickActionSelect = (playerName: string, platform: string) => {
        const playerData = { playerName, platform };
        setQuickActionPlayer(playerData);
        localStorage.setItem("quickActionPlayer", JSON.stringify(playerData));

        // Notify other components of the change
        const event = new CustomEvent("quickActionPlayerUpdated", {
            detail: playerData,
        });
        window.dispatchEvent(event);
    };

    const resetToDefault = () => {
        setPrimaryColor(COLORS.orange);
        setAccentColor1(COLORS.green);
        setAccentColor2(COLORS.blue);
        setBackgroundColor("#222222");
        setFontSize(14);
        setFontFamily("'Arial', sans-serif");
    };

    const renderColorPickerCard = (
        title: string,
        description: string,
        color: string,
        onChange: (color: any) => void
    ) => (
        <Card sx={{ flex: "1 1 300px", padding: 2 }}>
            <CardContent>
                <Typography variant="body1" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    {description}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ChromePicker
                        color={color}
                        onChange={onChange}
                        disableAlpha
                    />
                </Box>
            </CardContent>
        </Card>
    );

    return (
        <Box sx={{ padding: 2, maxWidth: 1400, margin: "0 auto" }}>
            <Typography variant="h5" gutterBottom>
                Preferences
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "8px",
                    padding: 2,
                    marginBottom: 4,
                }}
            >
                <Box sx={{ flex: 1, padding: 2 }}>
                    {/* Landing Page Preferences */}

                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Landing Page Preferences
                        </Typography>
                        <MetaLogo to={landingPage} />
                    </Box>

                    <Typography
                        variant="body2"
                        sx={{ marginBottom: 3, marginTop: 2 }}
                    >
                        The selected landing page is the page you will be
                        directed to when clicking the logo in the navbar.
                    </Typography>
                    <FormControl>
                        <Select
                            value={landingPage || ""}
                            onChange={(e) =>
                                handleLandingPageChange(e.target.value)
                            }
                            sx={{ minWidth: 200 }}
                            displayEmpty
                        >
                            <MenuItem value="">Home (Default)</MenuItem>
                            <MenuItem value="/player-search">
                                Player Search
                            </MenuItem>
                            <MenuItem value="/maps">Maps</MenuItem>
                            <MenuItem value="/tier-lists">Tier Lists</MenuItem>
                            <MenuItem value="/tips-strategies">
                                Tips & Strategies
                            </MenuItem>
                            <MenuItem value="/patches">Patch History</MenuItem>
                            <MenuItem value="/resources">Resources</MenuItem>
                            <MenuItem value="/saved-players">
                                Saved Players
                            </MenuItem>
                            <MenuItem value="/saved-matches">
                                Saved Matches
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {isMobile ? (
                    <Divider sx={{ marginY: 2 }} />
                ) : (
                    <Divider orientation="vertical" flexItem />
                )}

                <Box sx={{ flex: 1, padding: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Quick Action Player
                        </Typography>

                        <QuickActionButton
                            isAuthenticated={isAuthenticated}
                            playerName={quickActionPlayer?.playerName || ""}
                            platform={quickActionPlayer?.platform || ""}
                        />
                    </Box>

                    <Typography
                        variant="body2"
                        sx={{ marginBottom: 4, marginTop: 2 }}
                    >
                        Select a player to set as your quick action profile. You
                        can quickly access this player's stats via the button in
                        the navbar or player search page.
                    </Typography>
                    <SavedPlayersDropdown
                        isAuthenticated={isAuthenticated}
                        savedPlayers={savedPlayers}
                        loading={loadingSavedPlayers}
                        currentSelection={quickActionPlayer}
                        onSelect={(playerName, platform) =>
                            handleQuickActionSelect(playerName, platform)
                        }
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    border: `1px solid ${theme.palette.secondary.main}`,
                    borderRadius: "8px",
                    padding: 2,
                    marginBottom: 4,
                }}
            >
                <Box sx={{ flex: 1, padding: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Favorite Game Mode
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 2 }}>
                        Select your favorite game mode. Matches in this mode
                        will have a highlighted border in your recent matches,
                        making them easier to find.
                    </Typography>
                    <FormControl>
                        <Select
                            value={favoriteGameMode || ""}
                            onChange={(e) =>
                                setFavoriteGameMode(e.target.value)
                            }
                            displayEmpty
                        >
                            <MenuItem value="">None</MenuItem>
                            {/* FPP Category */}
                            <MenuItem
                                disabled
                                sx={{
                                    "&.Mui-disabled": {
                                        opacity: 1,
                                        marginLeft: 2,
                                    },
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: COLORS.orange }}
                                >
                                    FPP
                                </Typography>
                            </MenuItem>
                            <MenuItem value="solo-fpp">Solo FPP</MenuItem>
                            <MenuItem value="duo-fpp">Duo FPP</MenuItem>
                            <MenuItem value="squad-fpp">Squad FPP</MenuItem>

                            {/* TPP Category */}
                            <MenuItem
                                disabled
                                sx={{
                                    "&.Mui-disabled": {
                                        opacity: 1,
                                        marginLeft: 2,
                                    },
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: COLORS.orange }}
                                >
                                    TPP
                                </Typography>
                            </MenuItem>
                            <MenuItem value="solo-tpp">Solo TPP</MenuItem>
                            <MenuItem value="duo-tpp">Duo TPP</MenuItem>
                            <MenuItem value="squad-tpp">Squad TPP</MenuItem>

                            {/* Arcade Category */}
                            <MenuItem
                                disabled
                                sx={{
                                    "&.Mui-disabled": {
                                        opacity: 1,
                                        marginLeft: 2,
                                    },
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: COLORS.orange }}
                                >
                                    Arcade
                                </Typography>
                            </MenuItem>
                            <MenuItem value="ibr">
                                Intense Battle Royale
                            </MenuItem>
                            <MenuItem value="tdm">Team Deathmatch</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {isMobile ? (
                    <Divider sx={{ marginY: 2 }} />
                ) : (
                    <Divider orientation="vertical" flexItem />
                )}
                <Box sx={{ flex: 1, padding: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Show Map Images in Match Cards
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 2 }}>
                        Toggle whether map images should be displayed in match
                        cards. Your preference will be saved.
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showMapImages}
                                onChange={(e) =>
                                    toggleShowMapImages(e.target.checked)
                                }
                            />
                        }
                        label="Show Map Images in Match Cards"
                    />
                </Box>
                {isMobile ? (
                    <Divider sx={{ marginY: 2 }} />
                ) : (
                    <Divider orientation="vertical" flexItem />
                )}
                <Box sx={{ flex: 1, padding: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Group Weapons by Category in Tier List
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 2 }}>
                        Toggle whether weapons should be grouped by category in
                        the tier list creator.
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showWeaponCategories}
                                onChange={(e) =>
                                    toggleShowWeaponCategories(e.target.checked)
                                }
                            />
                        }
                        label="Group Weapons by Category"
                    />
                </Box>
            </Box>

            {/* Custom Theme Preferences */}
            <Box
                sx={{
                    border: `1px solid ${theme.palette.custom.accentColor2}`,
                    borderRadius: "8px",
                    padding: 2,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Custom Theme Preferences
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 4,
                        justifyContent: "center",
                    }}
                >
                    {renderColorPickerCard(
                        "Primary Color",
                        "Used for most components of the web app.",
                        primaryColor,
                        (color) => setPrimaryColor(color.hex)
                    )}
                    {renderColorPickerCard(
                        "Accent Color 1",
                        "Used in headers and active navbar items.",
                        accentColor1,
                        (color) => setAccentColor1(color.hex)
                    )}
                    {renderColorPickerCard(
                        "Accent Color 2",
                        "Used for secondary components and subtle highlights.",
                        accentColor2,
                        (color) => setAccentColor2(color.hex)
                    )}
                    {renderColorPickerCard(
                        "Background Color",
                        "Sets the background for the entire web app.",
                        backgroundColor,
                        (color) => setBackgroundColor(color.hex)
                    )}
                </Box>

                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        Font Size: {fontSize}px
                    </Typography>
                    <Slider
                        value={fontSize}
                        onChange={(e, value) => setFontSize(value as number)}
                        min={12}
                        max={24}
                        sx={{ maxWidth: 300 }}
                    />
                </Box>
                <Box sx={{ marginBottom: 3 }}>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        Font Style:
                    </Typography>
                    <FormControl>
                        <Select
                            value={fontFamily}
                            onChange={(e) => setFontFamily(e.target.value)}
                            sx={{ minWidth: 200 }}
                        >
                            <MenuItem
                                value="'Arial', sans-serif"
                                sx={{ fontFamily: "'Arial', sans-serif" }}
                            >
                                Arial
                            </MenuItem>
                            <MenuItem
                                value="'Times New Roman', serif"
                                sx={{ fontFamily: "'Times New Roman', serif" }}
                            >
                                Times New Roman
                            </MenuItem>
                            <MenuItem
                                value="'Courier New', monospace"
                                sx={{ fontFamily: "'Courier New', monospace" }}
                            >
                                Courier New
                            </MenuItem>
                            <MenuItem
                                value="'Roboto', sans-serif"
                                sx={{ fontFamily: "'Roboto', sans-serif" }}
                            >
                                Roboto
                            </MenuItem>
                            <MenuItem
                                value="'Lato', sans-serif"
                                sx={{ fontFamily: "'Lato', sans-serif" }}
                            >
                                Lato
                            </MenuItem>
                            <MenuItem
                                value="'Open Sans', sans-serif"
                                sx={{ fontFamily: "'Open Sans', sans-serif" }}
                            >
                                Open Sans
                            </MenuItem>
                            <MenuItem
                                value="'Montserrat', sans-serif"
                                sx={{ fontFamily: "'Montserrat', sans-serif" }}
                            >
                                Montserrat
                            </MenuItem>
                            <MenuItem
                                value="'Source Sans Pro', sans-serif"
                                sx={{
                                    fontFamily: "'Source Sans Pro', sans-serif",
                                }}
                            >
                                Source Sans Pro
                            </MenuItem>
                            <MenuItem
                                value="'Raleway', sans-serif"
                                sx={{ fontFamily: "'Raleway', sans-serif" }}
                            >
                                Raleway
                            </MenuItem>
                            <MenuItem
                                value="'Merriweather', serif"
                                sx={{ fontFamily: "'Merriweather', serif" }}
                            >
                                Merriweather
                            </MenuItem>
                            <MenuItem
                                value="'Nunito', sans-serif"
                                sx={{ fontFamily: "'Nunito', sans-serif" }}
                            >
                                Nunito
                            </MenuItem>
                            <MenuItem
                                value="'Ubuntu', sans-serif"
                                sx={{ fontFamily: "'Ubuntu', sans-serif" }}
                            >
                                Ubuntu
                            </MenuItem>
                            <MenuItem
                                value="'PT Sans', sans-serif"
                                sx={{ fontFamily: "'PT Sans', sans-serif" }}
                            >
                                PT Sans
                            </MenuItem>
                            <MenuItem
                                value="'Georgia', serif"
                                sx={{ fontFamily: "'Georgia', serif" }}
                            >
                                Georgia
                            </MenuItem>
                            <MenuItem
                                value="'Tahoma', sans-serif"
                                sx={{ fontFamily: "'Tahoma', sans-serif" }}
                            >
                                Tahoma
                            </MenuItem>
                            <MenuItem
                                value="'Verdana', sans-serif"
                                sx={{ fontFamily: "'Verdana', sans-serif" }}
                            >
                                Verdana
                            </MenuItem>
                            <MenuItem
                                value="'Poppins', sans-serif"
                                sx={{ fontFamily: "'Poppins', sans-serif" }}
                            >
                                Poppins
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Typography
                    variant="body2"
                    sx={{
                        marginBottom: 2,
                        color: "gray",
                        textAlign: "center",
                        fontStyle: "italic",
                    }}
                >
                    Default theme is #FFA500 #4CAF50 #2196F3 #2196F3 #222222
                    14px Arial
                </Typography>
                <Button variant="outlined" onClick={resetToDefault}>
                    Reset to Default Theme
                </Button>
            </Box>
        </Box>
    );
};
