// MUI Icons
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import SportsMmaIcon from "@mui/icons-material/SportsMma";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";

// Icon map
export const metricIcons = {
    matches: <SportsEsportsIcon color="primary" />,
    wins: <EmojiEventsIcon color="secondary" />,
    duration: <HourglassEmptyIcon color="secondary" />,
    kills: <SportsMmaIcon color="error" />,
    dbnos: <AccessibleForwardIcon color="success" />,
    damage: <WhatshotIcon color="warning" />,
    sessions: <GroupWorkIcon color="info" />,
};
