import React from "react";
import { Tooltip } from "@mui/material";
import ChickenDinnerIcon from "assets/matchCardIcons/dinner.png";
import SilverTrophyIcon from "assets/matchCardIcons/silver.png";
import BronzeTrophyIcon from "assets/matchCardIcons/bronze.png";
import GoldTrophyIcon from "assets/matchCardIcons/trophy.png";

interface PlacementIconProps {
    rank: number; // Rank or Placement (1st, 2nd, 3rd)
    isCasualOrIbr: boolean;
}

const PlacementIcon: React.FC<PlacementIconProps> = ({
    rank,
    isCasualOrIbr,
}) => {
    let iconSrc = null;
    let tooltipText = "";

    // Use trophy icon ONLY if ibr is true AND rank is 1
    if (isCasualOrIbr && rank === 1) {
        iconSrc = GoldTrophyIcon;
        tooltipText =
            "You won! - Intense Battle Royale / Team Deathmatch / Casual";
    } else {
        // Otherwise, use the default placement icons
        switch (rank) {
            case 1:
                iconSrc = ChickenDinnerIcon;
                tooltipText = "Winner Winner Chicken Dinner!";
                break;
            case 2:
                iconSrc = SilverTrophyIcon;
                tooltipText = "2nd Place";
                break;
            case 3:
                iconSrc = BronzeTrophyIcon;
                tooltipText = "3rd Place";
                break;
            default:
                return null; // No icon for placements beyond 3rd
        }
    }

    return (
        <Tooltip title={tooltipText}>
            <img src={iconSrc} alt={tooltipText} style={{ width: 28 }} />
        </Tooltip>
    );
};

export default PlacementIcon;
