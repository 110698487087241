import React from "react";
import {
    PieChart,
    Pie,
    Cell,
    Label,
    Tooltip,
    Sector,
    ResponsiveContainer,
} from "recharts";
import { Box, Typography, useTheme } from "@mui/material";

// MUI Icons
import SportsMmaIcon from "@mui/icons-material/SportsMma"; // MMA Glove Icon for Kills
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"; // Fire Icon for Damage

interface KillsDamageDonutChartProps {
    data: { name: string; value: number; color: string }[];
    title: string;
    total: number;
    highlightPlayer?: string; // Player to highlight
}

const DonutChart: React.FC<KillsDamageDonutChartProps> = ({
    data,
    title,
    total,
    highlightPlayer,
}) => {
    const theme = useTheme();

    // Determine the correct icon based on the title
    const getChartIcon = (chartTitle: string) => {
        if (chartTitle.toLowerCase().includes("kill")) {
            return <SportsMmaIcon sx={{ color: theme.palette.error.main }} />;
        } else if (chartTitle.toLowerCase().includes("damage")) {
            return (
                <LocalFireDepartmentIcon
                    sx={{ color: theme.palette.warning.main }}
                />
            );
        }
        return null;
    };

    // Sort data from highest to lowest value, ensuring integer formatting
    const sortedData = [...data]
        .map((entry) => ({
            ...entry,
            value: Math.round(entry.value), // Ensure no decimals
            formattedValue: Math.round(entry.value).toLocaleString(), // Add formatted string for display
        }))
        .sort((a, b) => b.value - a.value);

    return (
        <Box sx={{ textAlign: "center", width: "100%", height: "100%" }}>
            {/* Title in Styled Box with Icon */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: theme.palette.background.paper,
                    padding: "8px 16px",
                    borderRadius: 2,
                    boxShadow: 1,
                    mb: 2,
                    gap: 1, // Space between icon and text
                }}
            >
                {getChartIcon(title)}
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                    {title}
                </Typography>
            </Box>

            {/* Make the chart responsive */}
            <Box sx={{ width: "100%", height: 250 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={sortedData} // Use sorted data
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={50}
                            outerRadius={120}
                            startAngle={90} // Start at 12 o'clock
                            endAngle={-270} // Full clockwise rotation
                        >
                            {sortedData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            ))}
                            <Label
                                value={Number(
                                    total.toFixed(0)
                                ).toLocaleString()}
                                position="center"
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    fill: theme.palette.text.primary,
                                }}
                            />
                        </Pie>

                        {/* Tooltip */}
                        <Tooltip
                            formatter={(value, name) => {
                                const percentage = Math.round(
                                    (Number(value) / total) * 100
                                );
                                return [
                                    `${value.toLocaleString()} (${percentage}%)`,
                                    name,
                                ];
                            }}
                            cursor={{ fill: "rgba(255,255,255,0.1)" }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default DonutChart;
