// utils/sessionStats.ts
import { Match } from "types/match";

/**
 * Formats a given duration in seconds into a human-readable string.
 * @param totalSeconds - The total duration in seconds.
 * @returns A formatted string representing the duration in hours and minutes.
 */
export const formatDuration = (totalSeconds: number): string => {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours > 0 ? `${hours}h ` : ""}${
        minutes > 0 ? `${minutes}m` : ""
    }`;
};

export interface SessionStats {
    sessionId: number;

    // Matches and Wins
    matchesCount: number;
    totalWins: number;
    winsPercent: number;

    // Kills
    totalKills: string;
    totalRosterKills: string;
    killsPercent: number;

    // DBNOs
    totalDBNOs: string;
    totalRosterDBNOs: string;
    dbnosPercent: number;

    // Damage
    totalDamage: string;
    totalRosterDamage: string;
    damagePercent: number;

    // Survival Time
    totalSurvivalTime: string;
    totalMatchesDuration: string;
    survivalPercent: number;

    // Session Duration
    duration: string;
    startTime: string;
    endTime: string;

    // Frequent Teammates
    frequentTeammates: { name: string; count: number }[];
}

export const calculateSessionStats = (
    sessions: { sessionId: number; matches: Match[] }[],
    playerName: string
): SessionStats[] => {
    return sessions.map((session) => {
        const totalWins = session.matches.filter(
            (m) => m.playerWinPlace === 1
        ).length;

        // My Stats
        const totalKills = session.matches.reduce(
            (sum, match) => sum + (match.playerKills || 0),
            0
        );
        const totalDamage = session.matches.reduce(
            (sum, match) => sum + (match.playerDamage || 0),
            0
        );

        const totalDBNOs = session.matches.reduce(
            (sum, match) => sum + (match.playerDBNOs || 0),
            0
        );
        ///////////
        // Our Stats
        const totalRosterDBNOs = session.matches.reduce(
            (sum, match) => sum + (match.playerRosterDBNOs || 0),
            0
        );

        const totalRosterKills = session.matches.reduce(
            (sum, match) => sum + (match.playerRosterKills || 0),
            0
        );

        const totalRosterDamage = session.matches.reduce(
            (sum, match) => sum + (match.playerRosterDamage || 0),
            0
        );
        ///////////
        const totalSurvivalTime = session.matches.reduce(
            (sum, match) => sum + (match.playerSurvivalTime || 0),
            0
        );

        const totalMatchesDuration = session.matches.reduce(
            (sum, match) => sum + match.duration,
            0
        );

        const sessionStartTime = new Date(
            session.matches[0].createdAt
        ).getTime();
        const lastMatch = session.matches[session.matches.length - 1];
        const sessionEndTime =
            new Date(lastMatch.createdAt).getTime() + lastMatch.duration * 1000;

        const durationMinutes = Math.floor(
            (sessionEndTime - sessionStartTime) / 60000
        );
        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;

        // Calculate frequent teammates
        const teammateCounts: Record<string, number> = {};
        session.matches.forEach((match) => {
            match.playerRoster.forEach((teammate) => {
                const name = teammate.name;
                if (name !== "Unknown" && name !== playerName) {
                    if (teammateCounts[name]) {
                        teammateCounts[name]++;
                    } else {
                        teammateCounts[name] = 1;
                    }
                }
            });
        });

        const frequentTeammates = Object.entries(teammateCounts)
            .filter(([_, count]) => count >= 2) // Only include teammates with at least 2 matches
            .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
            .map(([name, count]) => ({ name, count })); // Map to an array of objects

        return {
            sessionId: session.sessionId,

            // Matches and Wins
            matchesCount: session.matches.length,
            totalWins,
            winsPercent:
                session.matches.length > 0
                    ? Math.round((totalWins / session.matches.length) * 100)
                    : 0, // Avoid NaN

            // Kills
            totalKills: Math.round(totalKills).toLocaleString(),
            totalRosterKills: Math.round(totalRosterKills).toLocaleString(),
            killsPercent:
                totalRosterKills > 0
                    ? Math.round((totalKills / totalRosterKills) * 100)
                    : 0, // Avoid NaN

            // DBNOs
            totalDBNOs: Math.round(totalDBNOs).toLocaleString(),
            totalRosterDBNOs: Math.round(totalRosterDBNOs).toLocaleString(),
            dbnosPercent:
                totalRosterDBNOs > 0
                    ? Math.round((totalDBNOs / totalRosterDBNOs) * 100)
                    : 0, // Avoid NaN

            // Damage
            totalDamage: Math.round(totalDamage).toLocaleString(),
            totalRosterDamage: Math.round(totalRosterDamage).toLocaleString(),
            damagePercent:
                totalRosterDamage > 0
                    ? Math.round((totalDamage / totalRosterDamage) * 100)
                    : 0, // Avoid NaN

            // Survival Time
            totalSurvivalTime: formatDuration(totalSurvivalTime),
            totalMatchesDuration: formatDuration(totalMatchesDuration),
            survivalPercent:
                totalMatchesDuration > 0
                    ? Math.round(
                          (totalSurvivalTime / totalMatchesDuration) * 100
                      )
                    : 0, // Avoid NaN

            // Session Duration
            startTime: new Date(sessionStartTime).toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            }),
            endTime: new Date(sessionEndTime).toLocaleString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            }),
            duration: `${hours > 0 ? `${hours}h ` : ""}${minutes}m`,
            // Frequent Teammates
            frequentTeammates,
        };
    });
};
