import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import vikendiSecretRooms from "assets/vikendiSecretRooms.jpg";
import { maxWidths } from "constants/appWide/maxWidths";

export const VikendiSecretRooms: React.FC = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/maps"); // Navigate back to the maps landing page
    };

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l,
                margin: "0 auto",
                marginTop: 2,
                marginBottom: 4,
            }}
        >
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.5 }}
                >
                    {/* Buttons Section */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            marginBottom: "16px",
                        }}
                    >
                        <Button
                            onClick={handleBack}
                            variant="contained"
                            color="primary"
                        >
                            Back to Map Categories
                        </Button>
                    </Box>
                    {/* Page Title */}
                    <Typography variant="h4" align="center" gutterBottom>
                        Vikendi Secret Rooms
                    </Typography>

                    {/* Page Description */}
                    <Typography variant="body1" align="center" gutterBottom>
                        Discover the secret room locations on Vikendi and their
                        loot!
                    </Typography>

                    {/* Image Section */}
                    <Box
                        component="img"
                        src={vikendiSecretRooms}
                        alt="Vikendi Secret Rooms"
                        sx={{
                            maxHeight: "80vh",
                            margin: "0 auto",
                            display: "block",
                            borderRadius: "8px",
                            border: "1px solid black",
                            maxWidth: { xs: "98%", sm: "80%", md: "60%" },
                        }}
                    />
                </motion.div>
            </AnimatePresence>
        </Box>
    );
};
