import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Drawer,
    List,
    ListItemText,
    Box,
    useMediaQuery,
    useTheme,
    Button,
    ListItemButton,
} from "@mui/material";

import { Link, useLocation } from "react-router-dom";

import { useAuth } from "AuthContext";
import { useSwipeable } from "react-swipeable";
import useFeatureToggle from "hooks/useFeatureToggle";
import { useNavigate } from "react-router-dom";

// MUI Icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ArrowRightOutlined from "@mui/icons-material/ArrowRightOutlined";
import {
    BarChartOutlined,
    FavoriteBorderOutlined,
    HelpOutlineOutlined,
    HistoryOutlined,
    HomeOutlined,
    LibraryBooksOutlined,
    LightbulbOutlined,
    MailOutlineOutlined,
    MapOutlined,
    MemoryOutlined,
    MoreHorizOutlined,
    SportsEsportsOutlined,
    WidgetsOutlined,
    HourglassTop,
    SmartToy,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
//////////

import { COLORS } from "constants/appWide/colors";
import { useLandingPage } from "../LandingPageProvider";
import QuickActionButton from "./QuickActionButton";
import AvatarButton from "./AvatarButton";
import { MetaLogo } from "./MetaLogo";

const isActive = (path: string) => {
    const { pathname } = useLocation();

    // Special handling for the root path "/"
    if (path === "/") {
        return pathname === "/";
    }

    // For "/tech", also consider "/showTechStack" as active
    if (path === "/tech") {
        return pathname === "/tech" || pathname === "/showTechStack";
    }

    // For all other paths, check if pathname starts with the given path
    return pathname.startsWith(path);
};

export const Navbar: React.FC = () => {
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();

    const { isAuthenticated, logout } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
        useState<null | HTMLElement>(null);

    const showTechStack = useFeatureToggle();

    const [quickActionPlayer, setQuickActionPlayer] = useState<{
        playerName: string;
        platform: string;
    } | null>(null);

    useEffect(() => {
        const savedQuickActionPlayer =
            localStorage.getItem("quickActionPlayer");
        if (savedQuickActionPlayer) {
            try {
                const parsedPlayer = JSON.parse(savedQuickActionPlayer);
                setQuickActionPlayer(parsedPlayer);
            } catch (error) {
                console.error("Error parsing quick action player:", error);
            }
        }

        // Event listener for quickActionPlayer updates
        const handleQuickActionPlayerUpdate = (event: Event) => {
            const customEvent = event as CustomEvent;
            setQuickActionPlayer(customEvent.detail);
        };

        window.addEventListener(
            "quickActionPlayerUpdated",
            handleQuickActionPlayerUpdate as EventListener
        );

        return () => {
            // Cleanup listener on unmount
            window.removeEventListener(
                "quickActionPlayerUpdated",
                handleQuickActionPlayerUpdate as EventListener
            );
        };
    }, []);

    const mobileActiveStyle = {
        color: COLORS.orange,
        fontWeight: "bold",
    };

    const { landingPage } = useLandingPage();

    const mobileInactiveStyle = {};

    const getNavItemStyle = (path: string) => {
        const isHome = path === "/";
        const isActivePath = isHome
            ? pathname === "/"
            : path === "/tech"
            ? pathname === "/tech" || pathname === "/showTechStack"
            : pathname.startsWith(path);

        return {
            textDecoration: "none",
            color: "black", // Keep the text color consistent
            backgroundColor: isActivePath ? COLORS.green : "transparent", // Light orange background for active
            border: isActivePath ? "2px solid black" : "none",
            padding: "0px 4px", // Add some padding for better appearance
            borderRadius: "4px", // Rounded corners for the background
        };
    };

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuAnchorEl(null);
    };

    const handleLogout = () => {
        handleProfileMenuClose();
        logout();
        navigate("/login");
    };

    const drawerLinks = (
        <List>
            {showTechStack && (
                <ListItemButton
                    component={Link}
                    to="/tech"
                    onClick={toggleDrawer(false)}
                    sx={
                        isActive("/tech")
                            ? mobileActiveStyle
                            : mobileInactiveStyle
                    }
                >
                    <MemoryOutlined
                        sx={{ marginRight: "16px", color: COLORS.orange }}
                    />
                    <ListItemText primary="PUBG Meta Tech Stack" />
                </ListItemButton>
            )}
            <ListItemButton
                component={Link}
                to="/"
                onClick={toggleDrawer(false)}
                sx={isActive("/") ? mobileActiveStyle : mobileInactiveStyle}
            >
                {isActive("/") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <HomeOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Home" />
            </ListItemButton>
            <Typography
                variant="subtitle1"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 16px",
                    backgroundColor: "#333", // Optional background for emphasis
                    borderRadius: "4px", // Rounded edges for a modern feel
                    color: "#fff4e5",
                    fontWeight: "bold",
                }}
            >
                <WidgetsOutlined sx={{ marginRight: "8px", color: "green" }} />
                Features
            </Typography>

            <ListItemButton
                component={Link}
                to="/player-search"
                onClick={toggleDrawer(false)}
                sx={
                    isActive("/player")
                        ? mobileActiveStyle
                        : mobileInactiveStyle
                }
            >
                {isActive("/player") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <SportsEsportsOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Player Stats" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/maps"
                onClick={toggleDrawer(false)}
                sx={isActive("/maps") ? mobileActiveStyle : mobileInactiveStyle}
            >
                {isActive("/maps") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <MapOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Maps" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/rotations"
                onClick={toggleDrawer(false)}
                sx={
                    isActive("/rotations")
                        ? mobileActiveStyle
                        : mobileInactiveStyle
                }
            >
                {isActive("/rotations") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <TravelExploreIcon
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Map Rotations" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/tier-lists"
                onClick={toggleDrawer(false)}
                sx={isActive("/tier") ? mobileActiveStyle : mobileInactiveStyle}
            >
                {isActive("/tier-lists") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <BarChartOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Tier Lists" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/c4-timer"
                onClick={toggleDrawer(false)}
                sx={isActive("/c4") ? mobileActiveStyle : mobileInactiveStyle}
            >
                {isActive("/c4") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <HourglassTop
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="C4 Timer" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/bot-list"
                onClick={toggleDrawer(false)}
                sx={
                    isActive("/bot-list")
                        ? mobileActiveStyle
                        : mobileInactiveStyle
                }
            >
                {isActive("/bot-list") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <SmartToy sx={{ marginRight: "16px", color: COLORS.orange }} />
                <ListItemText primary="Bots" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/weapons-wiki"
                onClick={toggleDrawer(false)}
                sx={
                    isActive("/weapons-wiki")
                        ? mobileActiveStyle
                        : mobileInactiveStyle
                }
            >
                {isActive("/weapons-wiki") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <ListIcon sx={{ marginRight: "16px", color: COLORS.orange }} />
                <ListItemText primary="Weapons" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/tips-strategies"
                onClick={toggleDrawer(false)}
                sx={isActive("/maps") ? mobileActiveStyle : mobileInactiveStyle}
            >
                {isActive("/tips-strategies") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <LightbulbOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Tips" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/patches"
                onClick={toggleDrawer(false)}
                sx={
                    isActive("/patch") ? mobileActiveStyle : mobileInactiveStyle
                }
            >
                {isActive("/patch") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <HistoryOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Patches" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/resources"
                onClick={toggleDrawer(false)}
                sx={
                    isActive("/resource")
                        ? mobileActiveStyle
                        : mobileInactiveStyle
                }
            >
                {isActive("/resource") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <LibraryBooksOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Resources" />
            </ListItemButton>
            <Typography
                variant="subtitle1"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 16px",
                    backgroundColor: "#333", // Optional background for emphasis
                    borderRadius: "4px", // Rounded edges for a modern feel
                    color: "#fff4e5",
                    fontWeight: "bold",
                }}
            >
                <MoreHorizOutlined
                    sx={{ marginRight: "8px", color: "green" }}
                />
                Miscellaneous
            </Typography>
            <ListItemButton
                component={Link}
                to="/faq"
                onClick={toggleDrawer(false)}
                sx={isActive("/faq") ? mobileActiveStyle : mobileInactiveStyle}
            >
                {isActive("/faq") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <HelpOutlineOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="FAQ" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/contact"
                onClick={toggleDrawer(false)}
                sx={
                    isActive("/contact")
                        ? mobileActiveStyle
                        : mobileInactiveStyle
                }
            >
                {isActive("/contact") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <MailOutlineOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Contact" />
            </ListItemButton>
            <ListItemButton
                component={Link}
                to="/support-us"
                onClick={toggleDrawer(false)}
                sx={
                    isActive("/support-us")
                        ? mobileActiveStyle
                        : mobileInactiveStyle
                }
            >
                {isActive("/support-us") && (
                    <ArrowRightOutlined
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                <FavoriteBorderOutlined
                    sx={{ marginRight: "16px", color: COLORS.orange }}
                />
                <ListItemText primary="Support" />
            </ListItemButton>
        </List>
    );

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setDrawerOpen(false), // Close drawer on swipe left
        onSwipedRight: () => setDrawerOpen(true), // Open drawer on swipe right
        preventScrollOnSwipe: true, // Prevent scrolling while swiping
        trackTouch: true, // Enable touch tracking
    });

    return (
        <Box
            {...swipeHandlers}
            sx={{
                paddingTop: isMobile ? "64px" : "0", // Padding only on mobile
            }}
        >
            <AppBar
                sx={{
                    backgroundColor: theme.palette.primary.main, // Use primary color from theme
                }}
                position={isMobile ? "fixed" : "static"} // Fixed for mobile, static for desktop
            >
                <Toolbar>
                    {isMobile ? (
                        <>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon sx={{ color: "black" }} />
                            </IconButton>
                            <Drawer
                                anchor="left"
                                open={drawerOpen}
                                onClose={toggleDrawer(false)}
                                PaperProps={{
                                    sx: {
                                        marginTop: "56px", // Adjust this value based on your AppBar height
                                        height: `calc(100% - 56px)`, // Ensure the drawer doesn't exceed the screen height
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 280,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        padding: 2,
                                    }}
                                >
                                    {drawerLinks}
                                </Box>
                            </Drawer>
                            <MetaLogo to={landingPage} />

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "auto",
                                    gap: 2,
                                }}
                            >
                                <QuickActionButton
                                    isAuthenticated={isAuthenticated}
                                    playerName={
                                        quickActionPlayer?.playerName || ""
                                    }
                                    platform={quickActionPlayer?.platform || ""}
                                />
                                {isAuthenticated ? (
                                    <>
                                        <AvatarButton
                                            onClick={handleProfileMenuClick}
                                            avatarSrc={`${process.env.PUBLIC_URL}/favicon.ico`}
                                            altText="Website Favicon"
                                        />

                                        <Menu
                                            anchorEl={profileMenuAnchorEl}
                                            open={Boolean(profileMenuAnchorEl)}
                                            onClose={handleProfileMenuClose}
                                        >
                                            <MenuItem
                                                component={Link}
                                                to="/account-settings"
                                                onClick={handleProfileMenuClose}
                                            >
                                                <SettingsIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Account Settings
                                            </MenuItem>
                                            <MenuItem
                                                component={Link}
                                                to="/preferences"
                                                onClick={handleProfileMenuClose}
                                            >
                                                <AccountCircleIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Preferences
                                            </MenuItem>
                                            <MenuItem
                                                component={Link}
                                                to="/saved-players"
                                                onClick={handleProfileMenuClose}
                                            >
                                                <FavoriteIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Saved Players
                                            </MenuItem>
                                            <MenuItem
                                                component={Link}
                                                to="/saved-matches"
                                                onClick={handleProfileMenuClose}
                                            >
                                                <VideogameAssetIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Saved Matches
                                            </MenuItem>
                                            <MenuItem onClick={handleLogout}>
                                                <LogoutIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Logout
                                            </MenuItem>
                                        </Menu>
                                    </>
                                ) : (
                                    process.env.REACT_APP_SHOW_SIGN_IN ===
                                        "true" && (
                                        <Button
                                            sx={{
                                                color: "black",
                                            }}
                                            component={Link}
                                            to="/login"
                                        >
                                            <LoginIcon
                                                sx={{
                                                    marginRight: "4px",
                                                    color: "black",
                                                }}
                                            />
                                            Sign In
                                        </Button>
                                    )
                                )}
                            </Box>
                        </>
                    ) : (
                        // Desktop Navbar
                        <>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2, color: "black" }}
                                onClick={handleMenuClick}
                            >
                                <MenuIcon />
                            </IconButton>
                            <MetaLogo to={landingPage} />
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    component={Link}
                                    to="/faq"
                                    onClick={handleMenuClose}
                                >
                                    <HelpOutlineOutlined
                                        sx={{
                                            marginRight: "16px",
                                            color: COLORS.orange,
                                        }}
                                    />
                                    FAQ
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to="/contact"
                                    onClick={handleMenuClose}
                                >
                                    <MailOutlineOutlined
                                        sx={{
                                            marginRight: "16px",
                                            color: COLORS.orange,
                                        }}
                                    />
                                    Contact
                                </MenuItem>
                            </Menu>
                            {/* Navigation Links */}
                            <Box sx={{ display: "flex", gap: 2, flexGrow: 1 }}>
                                {showTechStack && (
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            ...getNavItemStyle("/tech"), // Existing styles
                                            display: "flex", // Use flexbox for alignment
                                            alignItems: "center", // Center items vertically
                                        }}
                                        component={Link}
                                        to="/tech"
                                    >
                                        <MemoryOutlined
                                            sx={{
                                                marginRight: "4px",
                                                color: "black",
                                            }}
                                        />
                                        PUBG Meta Tech Stack
                                    </Typography>
                                )}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/"
                                >
                                    <HomeOutlined
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Home
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/player"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/player-search"
                                >
                                    <SportsEsportsOutlined
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Player Stats
                                </Typography>
                                {/* Maps Dropdown */}
                                <Box
                                    sx={{
                                        position: "relative",
                                        display: "inline-block",
                                        "&:hover .dropdown-content": {
                                            display: "block",
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            ...getNavItemStyle("/maps"), // Existing styles
                                            display: "flex", // Use flexbox for alignment
                                            alignItems: "center", // Center items vertically
                                        }}
                                        component={Link}
                                        to="/maps"
                                    >
                                        <MapOutlined
                                            sx={{
                                                marginRight: "4px",
                                                color: "black",
                                            }}
                                        />
                                        Maps
                                    </Typography>

                                    <Box
                                        className="dropdown-content"
                                        sx={{
                                            display: "none",
                                            position: "absolute",
                                            top: "99%",
                                            left: 0,
                                            zIndex: 10,
                                            minWidth: "200px",
                                            backgroundColor: "#2A2A2A",
                                            borderRadius: "8px",
                                            boxShadow:
                                                "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            textAlign: "left",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            component={Link}
                                            to="/maps/4k-maps"
                                            sx={{
                                                display: "block",
                                                textDecoration: "none",
                                                color: COLORS.white,
                                                px: 2,
                                                py: 1,
                                                "&:hover": {
                                                    backgroundColor:
                                                        COLORS.blue,
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            4K Maps
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            component={Link}
                                            to="/maps/erangel-secret-rooms"
                                            sx={{
                                                display: "block",
                                                textDecoration: "none",
                                                color: COLORS.white,
                                                px: 2,
                                                py: 1,
                                                "&:hover": {
                                                    backgroundColor:
                                                        COLORS.blue,
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            Erangel Secret Rooms
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            component={Link}
                                            to="/maps/taego-secret-rooms"
                                            sx={{
                                                display: "block",
                                                textDecoration: "none",
                                                color: COLORS.white,
                                                px: 2,
                                                py: 1,
                                                "&:hover": {
                                                    backgroundColor:
                                                        COLORS.blue,
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            Taego Secret Rooms
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            component={Link}
                                            to="/maps/deston-secret-rooms"
                                            sx={{
                                                display: "block",
                                                textDecoration: "none",
                                                color: COLORS.white,
                                                px: 2,
                                                py: 1,
                                                "&:hover": {
                                                    backgroundColor:
                                                        COLORS.blue,
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            Deston Secret Rooms
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            component={Link}
                                            to="/maps/ibr-crates"
                                            sx={{
                                                display: "block",
                                                textDecoration: "none",
                                                color: COLORS.white,
                                                px: 2,
                                                py: 1,
                                                "&:hover": {
                                                    backgroundColor:
                                                        COLORS.blue,
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            IBR Crates
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            component={Link}
                                            to="/maps/tdm"
                                            sx={{
                                                display: "block",
                                                textDecoration: "none",
                                                color: COLORS.white,
                                                px: 2,
                                                py: 1,
                                                "&:hover": {
                                                    backgroundColor:
                                                        COLORS.blue,
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            TDM Maps
                                        </Typography>
                                    </Box>
                                </Box>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/rotations"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/rotations"
                                >
                                    <TravelExploreIcon
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Map Rotations
                                </Typography>

                                {/* Tier Lists Dropdown */}
                                <Box
                                    sx={{
                                        position: "relative",
                                        display: "inline-block",
                                        "&:hover .dropdown-content": {
                                            display: "block",
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            ...getNavItemStyle("/tier-lists"), // Existing styles
                                            display: "flex", // Use flexbox for alignment
                                            alignItems: "center", // Center items vertically
                                        }}
                                        component={Link}
                                        to="/tier-lists"
                                    >
                                        <BarChartOutlined
                                            sx={{
                                                marginRight: "4px",
                                                color: "black",
                                            }}
                                        />
                                        Tier Lists
                                    </Typography>

                                    <Box
                                        className="dropdown-content"
                                        sx={{
                                            display: "none",
                                            position: "absolute",
                                            top: "99%",
                                            left: 0,
                                            zIndex: 10,
                                            minWidth: "200px",
                                            backgroundColor: "#2A2A2A",
                                            borderRadius: "8px",
                                            boxShadow:
                                                "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            textAlign: "left",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            component={Link}
                                            to="/tier-lists/maker"
                                            sx={{
                                                display: "block",
                                                textDecoration: "none",
                                                color: COLORS.white,
                                                px: 2,
                                                py: 1,
                                                "&:hover": {
                                                    backgroundColor:
                                                        COLORS.blue,
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            Tier List Maker
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            component={Link}
                                            to="/tier-lists/public"
                                            sx={{
                                                display: "block",
                                                textDecoration: "none",
                                                color: COLORS.white,
                                                px: 2,
                                                py: 1,
                                                "&:hover": {
                                                    backgroundColor:
                                                        COLORS.blue,
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            Browse Tier Lists
                                        </Typography>
                                    </Box>
                                </Box>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/c4-timer"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/c4-timer"
                                >
                                    <HourglassTop
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    C4 Timer
                                </Typography>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/bot-list"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/bot-list"
                                >
                                    <SmartToy
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Bots
                                </Typography>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/weapons-wiki"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/weapons-wiki"
                                >
                                    <ListIcon
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Weapons
                                </Typography>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/tips-strategies"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/tips-strategies"
                                >
                                    <LightbulbOutlined
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Tips
                                </Typography>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/patches"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/patches"
                                >
                                    <HistoryOutlined
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Patches
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        ...getNavItemStyle("/resources"), // Existing styles
                                        display: "flex", // Use flexbox for alignment
                                        alignItems: "center", // Center items vertically
                                    }}
                                    component={Link}
                                    to="/resources"
                                >
                                    <LibraryBooksOutlined
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Resources
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "auto",
                                    gap: 2,
                                }}
                            >
                                <Button
                                    component={Link}
                                    to="/support-us"
                                    sx={{
                                        color: "black",
                                        backgroundColor: COLORS.green,
                                        textTransform: "none",
                                        border: "1px solid black",
                                        padding: "5px 10px",
                                        borderRadius: "4px",
                                        "&:hover": {
                                            backgroundColor: "#45A049", // Slightly darker green for hover
                                        },
                                    }}
                                >
                                    <FavoriteBorderOutlined
                                        sx={{
                                            marginRight: "4px",
                                            color: "black",
                                        }}
                                    />
                                    Support
                                </Button>
                                <QuickActionButton
                                    isAuthenticated={isAuthenticated}
                                    playerName={
                                        quickActionPlayer?.playerName || ""
                                    }
                                    platform={quickActionPlayer?.platform || ""}
                                />
                                {isAuthenticated ? (
                                    <>
                                        <AvatarButton
                                            onClick={handleProfileMenuClick}
                                            avatarSrc={`${process.env.PUBLIC_URL}/favicon.ico`}
                                            altText="Website Favicon"
                                        />

                                        <Menu
                                            anchorEl={profileMenuAnchorEl}
                                            open={Boolean(profileMenuAnchorEl)}
                                            onClose={handleProfileMenuClose}
                                        >
                                            <MenuItem
                                                component={Link}
                                                to="/account-settings"
                                                onClick={handleProfileMenuClose}
                                            >
                                                <SettingsIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Account Settings
                                            </MenuItem>
                                            <MenuItem
                                                component={Link}
                                                to="/preferences"
                                                onClick={handleProfileMenuClose}
                                            >
                                                <AccountCircleIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Preferences
                                            </MenuItem>
                                            <MenuItem
                                                component={Link}
                                                to="/saved-players"
                                                onClick={handleProfileMenuClose}
                                            >
                                                <FavoriteIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Saved Players
                                            </MenuItem>
                                            <MenuItem
                                                component={Link}
                                                to="/saved-matches"
                                                onClick={handleProfileMenuClose}
                                            >
                                                <VideogameAssetIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Saved Matches
                                            </MenuItem>
                                            <MenuItem onClick={handleLogout}>
                                                <LogoutIcon
                                                    sx={{
                                                        marginRight: 1,
                                                        color: COLORS.orange,
                                                    }}
                                                />
                                                Logout
                                            </MenuItem>
                                        </Menu>
                                    </>
                                ) : (
                                    process.env.REACT_APP_SHOW_SIGN_IN ===
                                        "true" && (
                                        <Button
                                            sx={{ color: "black" }}
                                            component={Link}
                                            to="/login"
                                        >
                                            <LoginIcon
                                                sx={{
                                                    marginRight: "4px",
                                                    color: "black",
                                                }}
                                            />
                                            Sign In
                                        </Button>
                                    )
                                )}
                            </Box>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
};
