export const crateWeaponsMap: { [key: string]: string } = {
    Item_Weapon_L6_C: "Lynx AMR",
    Item_Weapon_AWM_C: "AWM",
    Item_Weapon_P90_C: "P90",
    Item_Weapon_FAMASG2_C: "FAMAS",
    Item_Weapon_Groza_C: "Groza",
    Item_Weapon_Mk14_C: "Mk14",
    Item_Weapon_DP12_C: "DBS",
    Item_Weapon_MG3_C: "MG3",
};
