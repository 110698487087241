import { Match } from "types/match";
import { computeRosterStats, Participant } from "./rosterStats";
import { fixParticipantWinPlace } from "./fixParticipantWinPlace";

export const enrichMatchData = (
    matchData: Match,
    accountId: string | null,
    playerName: string
): Match => {
    const rostersWithStats = computeRosterStats(
        matchData.rosters,
        matchData.participants,
        matchData.gameMode === "tdm"
    );

    // ✅ Fix participant winPlace
    const updatedParticipants = fixParticipantWinPlace(
        rostersWithStats,
        matchData.participants
    );

    const playerParticipant = matchData.participants.find(
        (p: Participant) => p.name === playerName || p.playerId === accountId
    );

    if (!playerParticipant) {
        throw new Error(
            `Player ${playerName} (or Account ID: ${accountId}) not found in match ${matchData.matchId}`
        );
    }

    const playerRoster = rostersWithStats.find((roster) =>
        roster.participants.includes(playerParticipant.participantId)
    );

    if (!playerRoster) {
        throw new Error(
            `Roster not found for player ${playerName} in match ${matchData.matchId}`
        );
    }

    return {
        ...matchData,
        participants: updatedParticipants,
        rosters: rostersWithStats,
        playerRoster: playerRoster.playerStats || [],
        playerWinPlace: playerRoster.rank,
        playerRosterKills: playerRoster.totalKills || 0,
        playerRosterDamage: playerRoster.totalDamage || 0,
        playerRosterDBNOs: playerRoster.totalDBNOs || 0,
        playerKills: playerParticipant.kills || 0,
        playerDamage: playerParticipant.damageDealt || 0,
        playerDBNOs: playerParticipant.DBNOs || 0,
        playerSurvivalTime: playerParticipant.timeSurvived || 0,
    };
};
