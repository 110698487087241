import React, { useState } from "react";
import {
    Box,
    Typography,
    Card,
    CardMedia,
    Dialog,
    DialogContent,
    IconButton,
    Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { weapons } from "constants/tierListWeapons";
import { ammoIcons } from "constants/ammoIcons";

const groupedWeapons = weapons.reduce((acc, weapon) => {
    if (!acc[weapon.weaponType]) {
        acc[weapon.weaponType] = [];
    }
    acc[weapon.weaponType].push(weapon);
    return acc;
}, {} as Record<string, typeof weapons>);

const WeaponsWiki: React.FC = () => {
    const [selectedWeapon, setSelectedWeapon] = useState<
        (typeof weapons)[0] | null
    >(null);

    return (
        <Box sx={{ padding: 2, pb: 4, maxWidth: 1200, margin: "auto" }}>
            <Typography variant="h5" sx={{ textAlign: "center", mb: 3 }}>
                Weapons Wiki
            </Typography>

            {Object.entries(groupedWeapons).map(([category, weapons]) => (
                <Box key={category} sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {category}
                    </Typography>

                    <Grid container spacing={2}>
                        {weapons.map((weapon) => (
                            <Grid
                                item
                                xs={6}
                                sm={4}
                                md={3}
                                lg={2}
                                key={weapon.title}
                            >
                                <Card
                                    sx={{
                                        cursor: "pointer",
                                        textAlign: "center",
                                        padding: 1,
                                        transition: "0.3s",
                                        "&:hover": { boxShadow: 4 },
                                        position: "relative", // Needed for positioning icons
                                    }}
                                    onClick={() => setSelectedWeapon(weapon)}
                                >
                                    {/* Weapon Image */}
                                    <CardMedia
                                        component="img"
                                        image={weapon.cardImage}
                                        alt={weapon.title}
                                        sx={{
                                            height: 150,
                                            objectFit: "cover",
                                        }}
                                    />

                                    {/* Crate Weapon Icon (Bottom Left) */}
                                    {weapon.crateWeapon && (
                                        <Box
                                            component="img"
                                            src="https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Icons/CarePackage/CarePackage_Normal.png"
                                            alt="Crate Weapon"
                                            sx={{
                                                position: "absolute",
                                                bottom: 5,
                                                left: 5,
                                                width: 34,
                                                height: 34,
                                            }}
                                        />
                                    )}

                                    {/* Ammo Type Icon (Top Right) */}
                                    {weapon.ammoType &&
                                        ammoIcons[weapon.ammoType] && (
                                            <Box
                                                component="img"
                                                src={ammoIcons[weapon.ammoType]}
                                                alt={`${weapon.ammoType} Ammo`}
                                                sx={{
                                                    position: "absolute",
                                                    top: 5,
                                                    right: 5,
                                                    width: 34,
                                                    height: 34,
                                                }}
                                            />
                                        )}

                                    <Typography
                                        variant="body2"
                                        sx={{ mt: 1, fontWeight: "bold" }}
                                    >
                                        {weapon.title}
                                    </Typography>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}

            {/* Weapon Details Modal */}
            <Dialog
                open={!!selectedWeapon}
                onClose={() => setSelectedWeapon(null)}
                maxWidth="sm"
                fullWidth
            >
                {selectedWeapon && (
                    <DialogContent>
                        <Box sx={{ position: "relative", textAlign: "center" }}>
                            <IconButton
                                onClick={() => setSelectedWeapon(null)}
                                sx={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold", mb: 2 }}
                            >
                                {selectedWeapon.title}
                            </Typography>

                            <CardMedia
                                component="img"
                                image={selectedWeapon.cardImage}
                                alt={selectedWeapon.title}
                                sx={{
                                    height: 200,
                                    objectFit: "contain",
                                    mb: 2,
                                }}
                            />

                            <Typography>
                                <strong>Type:</strong>{" "}
                                {selectedWeapon.weaponType}
                            </Typography>
                            <Typography>
                                <strong>Ammo Type:</strong>{" "}
                                {selectedWeapon.ammoType || "N/A"}
                            </Typography>
                            <Typography>
                                <strong>Crate Weapon:</strong>{" "}
                                {selectedWeapon.crateWeapon ? "Yes" : "No"}
                            </Typography>

                            {selectedWeapon.basedOn && (
                                <Typography sx={{ mt: 1 }}>
                                    <strong>Based on:</strong>{" "}
                                    <a
                                        href={selectedWeapon.basedOn}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "blue" }}
                                    >
                                        Wiki
                                    </a>
                                </Typography>
                            )}
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </Box>
    );
};

export default WeaponsWiki;
