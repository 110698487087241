export const calculateCumulativeStats = (
    stats: any[],
    perspectiveFilter: string,
    teamFilter: string
): { [key: string]: number } => {
    return stats
        .filter((modeStats: any) => {
            const isFpp =
                perspectiveFilter === "FPP" && modeStats?.mode?.includes("fpp");
            const isTpp =
                perspectiveFilter === "TPP" && modeStats?.mode?.includes("tpp");
            const isSolo =
                teamFilter === "Solo" && modeStats?.mode?.includes("solo");
            const isDuo =
                teamFilter === "Duo" && modeStats?.mode?.includes("duo");
            const isSquad =
                teamFilter === "Squad" && modeStats?.mode?.includes("squad");

            if (perspectiveFilter !== "All" && !isFpp && !isTpp) return false;
            if (teamFilter !== "All" && !isSolo && !isDuo && !isSquad)
                return false;

            return true;
        })
        .reduce((acc: any, modeStats: any) => {
            for (const statKey in modeStats) {
                if (typeof modeStats[statKey] === "number") {
                    if (
                        [
                            "roundMostKills",
                            "maxKillStreaks",
                            "longestKill",
                            "longestTimeSurvived",
                        ].includes(statKey)
                    ) {
                        acc[statKey] = Math.max(
                            acc[statKey] || 0,
                            modeStats[statKey]
                        );
                    } else {
                        acc[statKey] = (acc[statKey] || 0) + modeStats[statKey];
                    }
                }
            }
            return acc;
        }, {});
};
