import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip, Typography } from "@mui/material";
import InsightsIcon from "@mui/icons-material/Insights";
import { useTheme } from "@mui/material/styles";

interface QuickActionButtonProps {
    isAuthenticated: boolean;
    playerName: string | null;
    platform: string | null;
}

const QuickActionButton: React.FC<QuickActionButtonProps> = ({
    isAuthenticated,
    playerName,
    platform,
}) => {
    const theme = useTheme();

    const isDisabled = !playerName || !platform;

    // Dynamic tooltip message
    const tooltipMessage = !isAuthenticated
        ? "Log in to set a quick action player."
        : isDisabled
        ? "Set a quick action player in account preferences to use this."
        : null;

    return (
        <Tooltip
            title={
                <Typography sx={{ color: "white", fontSize: "0.85rem" }}>
                    {tooltipMessage}
                </Typography>
            }
            arrow
        >
            <span>
                <IconButton
                    component={isDisabled ? "button" : Link}
                    to={
                        !isDisabled
                            ? `/player-stats/${platform}/${encodeURIComponent(
                                  playerName || ""
                              )}`
                            : undefined
                    }
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: theme.palette.custom.accentColor2, // Always use blue
                        color: "white",
                        "&:hover": {
                            backgroundColor: theme.palette.custom.accentColor2,
                            color: "white",
                        },
                        opacity: isDisabled ? 0.5 : 1, // Reduce opacity for disabled
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        pointerEvents: isDisabled ? "none" : "auto", // Prevent interaction
                    }}
                >
                    <InsightsIcon fontSize="medium" />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default QuickActionButton;
