// Define weapon name mappings for weapons whose name differs from the name used by the PUBG Frontend
export const weaponNameMap: { [key: string]: string } = {
    AK47: "AKM",
    Berreta686: "S686",
    BerylM762: "Beryl M762",
    BizonPP19: "PP-19 Bizon",
    BluezoneGrenade: "BZ Grenade",
    DP12: "DBS",
    DP28: "DP-28",
    DesertEagle: "Deagle",
    FamasG2: "FAMAS",
    FNFal: "SLR",
    G18: "P18C",
    Grenade: "Frag Grenade",
    HK416: "M416",
    L6: "Lynx AMR",
    M1911: "P1911",
    M9: "P92",
    Mk47Mutant: "Mk47 Mutant",
    Molotov: "Molotov Cocktail",
    Mosin: "Mosin Nagant",
    NagantM1895: "R1895",
    OriginS12: "O12",
    PanzerFaust100M: "Panzerfaust",
    QBU88: "QBU",
    QBZ95: "QBZ",
    Rhino: "R45",
    Saiga12: "S12K",
    Sawnoff: "Sawed-Off",
    StickyGrenade: "Sticky Bomb",
    Thompson: "Tommy Gun",
    UZI: "Micro UZI",
    Win1894: "Win94",
    Winchester: "S1897",
    vz61Skorpion: "Skorpion",
};
